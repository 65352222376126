const objectChipsStyles = theme => ({
  cardLink: {
    '&:focus-visible': {
      border: `2px solid ${theme.colors.visibleborderColor}`,
      borderRadius: 6,
    },
  },
  positionAIIcon: {
    '& svg': {
    position: 'relative',
    top: 3,
    left: 4,
    },
  }
})

export default objectChipsStyles
