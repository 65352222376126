import dashboardStyle from './dashboardStyle.jsx'
import cardTitleStyle from '../components/cardTitleStyle.jsx'
import tableStyle from '../components/tableStyle.jsx'
import { grayColor } from 'assets/jss/material-dashboard-react.jsx'

const testprojectsStyle = theme => {
  return {
    ...dashboardStyle(theme),
    ...cardTitleStyle(theme),
    ...tableStyle(theme),
    leftDatacards: {
      paddingTop: 25,
    },
    dashboardbutton: {
      color: theme.colors.primary,
      margin: '-15px'
    },
    dashboardbuttongrid: {
      textAlign: 'right'
    },
    historyTable: {
      width: 'auto'
    },
    historyTableHead: {
      height: 'auto'
    },
    historyTableHeadRow: {
      height: 'auto'
    },
    historyTableHeadTestSessionCell: {
      border: `1px solid ${theme.colors.tableRow}`,
      padding: 5,
      paddingRight: '0px !important',
      minHeight: '1000px',
      width: '1.2em',
      height:'auto'
    },
    historyTableHeadTestSessionLink: {
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      writingMode: 'vertical-lr',
      width:'100%'
    },
    historyTableCell: {
      border: `1px solid ${theme.colors.tableRow}`, 
      padding: 2
    },
    historyTableCellTestcaseName: {
      minWidth: '200px'
    },    
    historyTableTestCaseRow: {
      height: '1.2em'
    },
    historyTableTestCaseCell: {
      border: `1px solid ${theme.colors.tableRow}`, 
      padding: 0,
      width: '1.2em',
      height:'1.2em',
    },
    historyTableTestCaseCellEmpty: {
      backgroundColor: grayColor,
      cursor: 'none'
    },    
    historyTableTestCaseCellDanger: {
      backgroundColor: theme.colors.common.danger,
      height: 28,
      width: 28,
      paddingRight: '0px !important',
      cursor: 'pointer'
    },
    historyTableTestCaseCellSuccess: {
      backgroundColor: theme.colors.common.success,
      height: 28,
      width: 28,
      cursor: 'pointer'
    },
    integrationObject: {
      maxWidth: '100%', 
      maxHeight: '3vh',
      color: theme.colors.primary
    },
    tablePosition: {
      position: 'relative',
      top: '-80px',
      '& a':{
        '& div':{
          top: '0!Important'
        }
      }
    },
    testSessionsEmbeddedTable: {
      '& table': {
        '& tr': {
          height: 80
        },
        '& tr:last-child td': {
          borderBottom: `0px solid ${theme.colors.tableRowBorder}`
        },
      }
    },
    projectsListEmbeddedTable: {
      '& table': {
        borderCollapse: 'separate!Important',
      }
    },
    settingsIcon: {
      backgroundColor: theme.colors.common.primarydark,
      display: 'inline-block',
      borderRadius: 7,
      padding: 8,
    },
    resultname: {
      position: 'relative',
      top: '-8px'
    },
    performanceTestType: {
      display: 'inline-block',
      color: `${theme.colors.common.primary}`,
      backgroundColor: theme.colors.performancetext,
      padding: '2px 10px',
      borderRadius: 5,
      fontSize: 12,
      fontWeight: 'bold',
      '& span': {
        color: `${theme.colors.common.primary}`,
      }
    },
    explanationBox: {
      lineHeight: 1.5,
      minHeight: 80,
      marginTop: 20
    },
    tooltipStyle: {
      position: 'relative',
      backgroundColor: `${theme.colors.common.primary}`,
      borderRadius: 6,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
      padding: 5,
      pointerEvents: 'none',
      color: 'white',
    },
    arrowStyle: {
      position: 'absolute',
      top: '50%',
      left: '-20px',
      transform: 'translateY(-50%)',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: `10px solid ${theme.colors.common.primary}`,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
    },
    contentStyle: {
      padding: '0 5px',
    },
    labelStyle: {
      fontWeight: 'bold',
      marginBottom: 3,
    },
    entryStyle: {
      display: 'block', 
      marginBottom: 3,
    },
    refreshButtonSpace: {
      marginLeft: 10,
    },
    refreshButtonSpaceRight: {
      marginRight: 10,
    }
  }
}

export default testprojectsStyle
