import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import config from 'config'
import _ from 'lodash'
import copyToClipboard from 'copy-to-clipboard'
import moment from 'moment'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import TablePagination from 'components/Table/CustomTablePagination'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemText from 'components/List/ListItem/ListItemText'
import RefreshIcon from '@material-ui/icons/Refresh'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ImageTiles from 'components/Convo/ImageTiles'
import MessageBox from 'components/Info/MessageBox'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
// apollo
import { Query, Mutation, compose, graphql, withApollo } from 'react-apollo'
// core components
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Chip from 'components/Chip/Chip'
import Button from 'components/Button/Button'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import ErrorFormat from 'components/Info/ErrorFormat'
import CapabilitiesEdit from 'components/Capability/CapabilitiesEdit.jsx'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import UnsavedFormSpy from 'components/Form/UnsavedFormSpy'
import Transcript from 'components/Convo/Transcript.jsx'
import ShowIcon from 'components/Icon/ShowIcon'
import FeatureUpgradeNavLink from 'components/FeatureUpgrade/FeatureUpgradeNavLink'
import Field from 'components/Form/OptionalField'
import CustomTabsSecondary from 'components/Tabs/CustomTabsSecondary.jsx'
import QueryStatus from 'components/Info/QueryStatus'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ObjectChips from 'components/Chip/ObjectChips'
import { safeGetNamespaceFilteredList} from '../helper'
import {
  renderTextField,
  renderTextArea,
  renderTagField,
  renderCheckbox,
  renderSelect,
  renderIntField,
  renderAutoSuggest,
  renderNamespaceField,
  required,
  minValue,
  maxValue,
  cron,
  email,
  composeValidators,
  parseInteger,
  parseDecimal,
  CustomTextField,
  CustomTagsField,
  CustomCheckbox,
  CustomSelect,
  Condition,
  FormActionsToolbar,
  TableActionsToolbar
} from 'components/Form/Form'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import { getConnector } from 'actions/settings'

import { CapabilityObjectClean, CapabilitiesToGql, EnvironmentVariableObjectClean, EnvironmentVariablesToGql, EnvsSwitch } from 'components/Capability/Helper'

import testprojectsStyle from 'assets/jss/material-dashboard-react/views/testprojectsStyle.jsx'

import {
  TESTPROJECT_QUERY,
  TESTPROJECT_HISTORY_QUERY,
  UPDATE_TESTPROJECT,
  DELETE_TESTPROJECT,
  START_TESTPROJECT,
  CREATE_TESTPROJECT,
  CREATE_TESTPROJECT_AGGREGATOR,
  UPDATE_TESTPROJECT_AGGREGATOR,
  DELETE_TESTPROJECT_AGGREGATOR,
  RefetchTestProjectQueries,
  RefetchTestProjectQueriesOnNewTestSession
} from './gql'
import {
  TESTCASERESULT_DETAILS_QUERY,
  DeleteTestSessionListsFromCache,
  RefetchTestSessionQueries
} from '../TestSessions/gql'
import { AGENTS_DROPDOWN_QUERY } from '../Settings/gql'
import { TAGS_QUERY, APIKEYS_QUERY, REGISTEREDCOMPONENTS_QUERY, REGISTEREDAGGREGATORS_QUERY } from '../Settings/gql'
import { TESTSETS_DROPDOWN_QUERY } from '../TestSets/gql'
import { CHATBOTS_DROPDOWN_QUERY } from '../Chatbots/gql'
import { DEVICESETS_DROPDOWN_QUERY } from '../Settings/gql'

import TestSessionsEmbeddedTable from '../TestSessions/TestSessionsEmbeddedTable.jsx'
import { isTestTypeSupported } from '../QuickStart/Helper'
import PerformanceTestSessionRegisterForm, {
  buildPerformanceTestStartOptions, calculateRepeatPerTick
} from '../PerformanceTestSessions/PerformanceTestSessionRegisterForm'

import { hasPermission, hasAnyPermission, canReadNamespace, canWriteNamespace } from 'botium-box-shared/security/permissions'
import { isLicenseDeviceSetsSupported } from 'botium-box-shared/security/licenseSupport'
import { formatCreatedByAndLastChange } from 'helper/authHelper.js'
import Text from 'components/Typography/Text.jsx'
import DropdownButton from 'components/Button/DropdownButton'
import { extractErrorMessage } from '../../helper/graphHelper'
import Divider from 'components/Divider/Divider'
//import Tooltip from 'components/Tooltip/Tooltip'
import {Bar, BarChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList, Area, ComposedChart} from 'recharts'
import { isDarkmode } from 'components/Darkmode/helper'
import DateFormat from 'components/Info/DateFormat'
import {TESTSESSIONS_QUERY} from '../TestSessions/gql'
import SelectableCard from 'components/Card/SelectableCard'
import {LanguageDisplayName} from 'components/Icon/FlagIcon'

import { projectTypeFlagsToConnectorFeature, projectTypeFlagsToUrl, performanceTestTypes, projectToProjectTypeFlags } from './helper'
import { getAggregatedData } from '../PerformanceTestSessions/helper'
import { validateTestProjectCodeUnique } from './validators'
import { RenderSkeletonChartBar, RenderSkeletonProjectMenu } from 'components/Skeleton/skeletonHelper'


const { PERFORMANCE_TEST_MODE_LOAD_TEST, PERFORMANCE_TEST_MODE_STRESS_TEST, PERFORMANCE_TEST_MODE_ADVANCED_TEST } = performanceTestTypes

const httpVerbs = ['GET', 'POST', 'PUT']
const reporters = [
  { key: 'json', label: 'JSON (*.json)' },
  { key: 'junit', label: 'JUnit XML (*.xml)' },
  { key: 'csv', label: 'CSV/Excel (*.csv)' }
]

class TestProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openHistoryDialog: false,
      historyCurrentPositionX: null,
      historyCurrentPositionY: null,
      historyTestCaseFilter: '',
      historyStatus: null,
      historyStatusSelection: 'all',
      historyPage: 0,
      historyRowsPerPage: 25,
      openSummaryChart: true,
      trigger: {
        tags: [],
        buildId: '',
        apikey: '',
        reporter: 'json',
        callbackUrl: '',
        callbackMethod: 'POST',
        wait: false
      },
      badges: {
        text: '',
        date: false
      },
      showCloneDialog: false,
      newTestSessionCount: 0,
      showErrorTestProjectClone: null
    }
    this.setParentState = this.setParentState.bind(this)
  }

  setParentState(state) {
    this.setState(state)
  }

  hasReadPermission() {
    return this.props.hasReadPermissions
  }
  hasWritePermission() {
    return this.props.hasWritePermissions
  }

  getRootPath = () => {
    return '/' + this.props.location.pathname.split('/').filter(p => p)[0]
  }

  renderChatbots() {
    const { chatbotsData } = this.props

    return <Field
      name="chatbot.id"
      component={renderSelect}
      label="Chatbot"
      data-unique="selTestProjectChatbotId"
      validate={required}
      disabled={!this.hasWritePermission()}
      loading={chatbotsData && chatbotsData.loading}
      error={chatbotsData && chatbotsData.error}
      items={chatbotsData && this.props.testproject.chatbot && chatbotsData.chatbots && safeGetNamespaceFilteredList(chatbotsData.chatbots, this.props.namespace, this.props.testproject.chatbot.id).map(c => {
        return {
          key: c.id,
          chatbot: c
        }
      })}
    />
  }

  renderTestSets() {
    const { testSetsData } = this.props

    return <Field
      name="testSets"
      component={renderSelect}
      label="Select Test Set(s)"
      data-unique="selTestProjectTestSetIds"
      validate={required}
      disabled={!this.hasWritePermission()}
      loading={testSetsData && testSetsData.loading}
      error={testSetsData && testSetsData.error}
      multiple
      valueKeyMap={t => t.id}
      items={testSetsData && testSetsData.testsets && safeGetNamespaceFilteredList(testSetsData.testsets, this.props.namespace, this.props.testproject.testSets.map(t => t.id)).map(t => {
        return {
          key: t.id,
          label: t.name,
          value: t
        }
      })}
    />
  }

  renderDeviceSets() {
    const { deviceSetsData } = this.props

    return <Field
      name="deviceSets"
      component={renderSelect}
      label="Select Device Set(s)"
      data-unique="selTestProjectDeviceSetIds"
      disabled={!this.hasWritePermission()}
      loading={deviceSetsData && deviceSetsData.loading}
      error={deviceSetsData && deviceSetsData.error}
      multiple
      valueKeyMap={d => d.id}
      items={deviceSetsData && deviceSetsData.devicesets && deviceSetsData.devicesets.map(d => {
        return {
          key: d.id,
          label: d.name,
          value: d
        }
      })}
    />
  }

  renderCloneDialog(testproject) {
    const { setAlertSuccessMessage, history, deviceSetsData } = this.props
    const { showErrorTestProjectClone } = this.state

    return (
      <Mutation
        mutation={CREATE_TESTPROJECT}
        refetchQueries={() => {
          return [
            ...RefetchTestProjectQueries()
          ]
        }}
      >
        {(createTestProject, { loading, error }) => (
          <Form
            onSubmit={async (values, form) => {
              const testProject = {
                name: values.name,
                namespace: values.namespace,
                code: '',
                securityCheck: values.securityCheck,
                nlpAnalytics: values.nlpAnalytics,
                performanceTesting: values.performanceTesting,
                gdprTesting: values.gdprTesting,
                e2eTesting: values.e2eTesting,
                regressionTesting: values.regressionTesting,
                monitoring: values.monitoring,
                description: values.description,
                batchCount: values.batchCount || null,
                bail: values.bail,
                tags: {
                  set: values.tags,
                },
                chatbot: {
                  connect: {
                    id: values.chatbot.id,
                  },
                },
                testSets: {
                  connect:
                    values.testSets &&
                    values.testSets.map(t => {
                      return { id: t.id }
                    }),
                },
                deviceSets: {
                  connect:
                    values.deviceSets &&
                    values.deviceSets.map(d => {
                      return { id: d.id }
                    }),
                },
                registeredComponents: {
                  connect:
                    values.registeredComponents &&
                    values.registeredComponents.map(d => {
                      return { id: d.id }
                    }),
                },
                capabilities: {
                  create: values.capabilities && values.capabilities.map(c => CapabilityObjectClean(c))
                },
                envs: {
                  create: values.envs && values.envs.map(e => EnvironmentVariableObjectClean(e))
                }
              }
              if (values.agent && values.agent.id) {
                testProject.agent = {
                  connect: {
                    id: values.agent.id,
                  }
                }
              }

              try {
                const res = await createTestProject({
                  variables: {
                    testProject
                  },
                })
                form.initialize(res.data.createTestProject)
                this.setState({ showCloneDialog: false })
                setAlertSuccessMessage('Test Project cloned')
                history.push(`/regression/projects/view/${res.data.createTestProject.id}`)
              } catch (error) {
                this.setState({ showErrorTestProjectClone: error})
              }
            }}
            initialValues={
              Object.assign({}, testproject, { name: `Copy of ${testproject.name}` })
            }
            render={({ handleSubmit, reset, submitting, values }) => (
              <ConfirmationDialog
                open={!!this.state.showCloneDialog}
                onCancel={() => this.setState({ showCloneDialog: false })}
                onOk={() => handleSubmit()}
                isWorking={loading}
                title="Clone Test Project"
                showError={showErrorTestProjectClone && `Test Project cloning failed: ${extractErrorMessage(showErrorTestProjectClone, true)}`}
                clearError={() => this.setState({ showErrorTestProjectClone: null })}
              >
                <form onSubmit={handleSubmit}>
                  <UnsavedFormSpy />
                  <GridContainer>
                    <GridItem xs={12} sm={12}>

                      <Field
                        name="name"
                        component={renderTextField}
                        label="Test Project Name"
                        validate={composeValidators(required, async (value) => {
                          const { client } = this.props
                          if (value) {
                            return validateTestProjectCodeUnique(client, value, undefined, projectToProjectTypeFlags(values))
                          }
                        })}
                        disabled={!this.hasWritePermission()}
                        data-unique="txtTestProjectCloneTestProjectName"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <Field
                        name="namespace"
                        component={renderNamespaceField}
                        label="Namespace"
                        forWrite
                        disabled={!this.hasWritePermission()}
                        data-unique="txtTestProjectCloneTestProjectNamespace"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      {this.renderChatbots()}
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      {this.renderTestSets()}
                    </GridItem>
                    {deviceSetsData &&
                      <GridItem xs={12} sm={12}>
                        {this.renderDeviceSets()}
                      </GridItem>
                    }
                  </GridContainer>
                </form>
              </ConfirmationDialog>
            )}
          />
        )}
      </Mutation>
    )
  }

  renderTrigger(testproject) {
    const { trigger } = this.state

    if (!this.isBuildIntegrationEdition()) {
      return <Text warning><FeatureUpgradeNavLink>Build Server Integration is not supported in this Edition</FeatureUpgradeNavLink></Text>
    }
    return (
      <Query query={APIKEYS_QUERY}>
        {({ loading, error, data }) => {
          const apikeys = (data && data.apikeys) || []

          const selectedApiKey = trigger.apikey || (apikeys.length > 0 && apikeys[0].key) || 'SELECT_API_KEY'

          const baseApiLink = config.api.ext

          let buildTriggerLink = `${baseApiLink}/triggerbuild/${testproject.code}?APIKEY=${selectedApiKey}`
          let buildStatusLink = ''
          let cliLink = buildTriggerLink
          let cliArgs = []

          if (trigger.buildId) {
            buildTriggerLink += `&BUILDID=${trigger.buildId}`
            cliArgs.push(`--buildid ${trigger.buildId}`)
            buildStatusLink = `${baseApiLink}/build/${trigger.buildId}?APIKEY=${selectedApiKey}`
          }
          if (trigger.wait) {
            buildTriggerLink += `&WAIT=1`
            if (buildStatusLink) buildStatusLink += `&WAIT=1`
          }
          if (trigger.retry) {
            buildTriggerLink += `&RETRY=1`
          }
          if (trigger.tags && trigger.tags.length > 0) {
            buildTriggerLink += `&${trigger.tags.map(t => `TAG=${t}`).join('&')}`
            cliArgs.push(` --tags ${trigger.tags.join(' ')}  --`)
          }
          if (trigger.reporter) {
            buildTriggerLink += `&REPORTER=${trigger.reporter}`
            if (buildStatusLink) buildStatusLink += `&REPORTER=${trigger.reporter}`
          }
          if (trigger.callbackUrl) {
            buildTriggerLink += `&CALLBACKURL=${encodeURIComponent(trigger.callbackUrl)}&CALLBACKMETHOD=${trigger.callbackMethod}`
            if (buildStatusLink) buildStatusLink += `&CALLBACKURL=${encodeURIComponent(trigger.callbackUrl)}&CALLBACKMETHOD=${trigger.callbackMethod}`
            cliLink += `&CALLBACKURL=${encodeURIComponent(trigger.callbackUrl)}&CALLBACKMETHOD=${trigger.callbackMethod}`
          }

          let cliCmdLine = `botium-cli box xunit --reporter-options output=junit.xml --webhook ${cliLink} ${cliArgs.join(' ')}`

          return (<GridContainer>
            <GridItem xs={12}>
              <Text header>Step 1/3: Enter Build Server Integration Scripts Parameters</Text>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomSelect
                data-unique="selTestProjectApiKey"
                input={{
                  value: selectedApiKey,
                  onChange: e =>
                    this.setState({
                      trigger: { ...trigger, apikey: e.target.value },
                    }),
                }}
                items={apikeys.map(a => ({ key: a.key, label: a.name }))}
                label="API Key"
                helperText="API Key to use in your requests"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomTextField
                input={{
                  onChange: e =>
                    this.setState({
                      trigger: { ...trigger, buildId: e.target.value },
                    }),
                  value: trigger.buildId
                }}
                label="Build Id"
                helperText="Build Identifier to name your Test Results"
                data-unique="txtTestProjectBuildId"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomTagsField
                input={{
                  name: 'BuildTags',
                  onChange: e =>
                    this.setState({ trigger: { ...trigger, tags: e } }),
                  value: trigger.tags,
                }}
                label="Build Tags"
                helperText="Build Tags to attach to the Test Results"
                data-unique="tagTestProjectBuildTags"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomSelect
                data-unique="selTestProjectOutputFormat"
                input={{
                  value: trigger.reporter,
                  onChange: e =>
                    this.setState({
                      trigger: { ...trigger, reporter: e.target.value },
                    }),
                }}
                items={reporters}
                label="Output Format"
                helperText="File format to use for the test results"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomTextField
                input={{
                  onChange: e =>
                    this.setState({
                      trigger: { ...trigger, callbackUrl: e.target.value },
                    }),
                  value: trigger.callbackUrl,
                }}
                label="Callback URL"
                helperText="HTTP(S) endpoint to use for the callback"
                data-unique="txtTestProjectCallbackUrl"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomSelect
                data-unique="selTestProjectCallbackMethod"
                input={{
                  value: trigger.callbackMethod,
                  onChange: e =>
                    this.setState({
                      trigger: { ...trigger, callbackMethod: e.target.value },
                    }),
                }}
                items={httpVerbs.map(v => ({ key: v, label: v }))}
                label="Callback Method"
                helperText="HTTP(S) Method to use for the callback"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomCheckbox
                input={{
                  onChange: e =>
                    this.setState({
                      trigger: { ...trigger, wait: e.target.checked },
                    }),
                  checked: trigger.wait,
                }}
                label="Wait with HTTP(S) response until test session completed"
                data-unique="chkTestProjectWaitWithResponse"
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomCheckbox
                input={{
                  onChange: e =>
                    this.setState({
                      trigger: { ...trigger, retry: e.target.checked },
                    }),
                  checked: trigger.retry,
                }}
                label="Retry only the failed Test Cases in the latest Test Session"
                data-unique="chkTestProjectRetryLatestTestSession"
              />
            </GridItem>
            <GridItem xs={12}>
              <Text header>
                Step 2/3: Generated Build Server Integration Scripts (Use Copy &
                Paste)
              </Text>
            </GridItem>
            <GridItem xs={12}>
              <CustomTabsSecondary
                name={`TestProjectTriggerTabs_${testproject.id}`}
                headerColor="info"
                tabs={[
                  {
                    tabName: 'HTTP(S) Webhook Link',
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12}>
                          <CustomTextField
                            input={{
                              value: buildTriggerLink,
                              readOnly: true,
                            }}
                            label="Start Test Project Link"
                            helperText="Use this link to trigger the Botium Test Project from any CI pipeline"
                            rows={2}
                            data-unique="txtTestProjectStartProjectLink"
                            endAdornment={
                              <Tooltip title="Copy To Link">
                                <Button justIcon data-unique={`btnTestProjectStartProjectLinkCopy`} onClick={() => copyToClipboard(buildTriggerLink)}>
                                  <FileCopyIcon />
                                </Button>
                              </Tooltip>
                            }
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <CustomTextField
                            input={{
                              value: buildStatusLink,
                              readOnly: true,
                            }}
                            label="Status Link"
                            helperText="Use this link to get the status and result for the Botium Test Project (Build Id required)"
                            rows={2}
                            data-unique="txtTestProjectStatusLink"
                            endAdornment={
                              buildStatusLink && <Button justIcon round data-unique={`btnTestProjectStatusLinkCopy`} onClick={() => copyToClipboard(buildStatusLink)}>
                                <FileCopyIcon />
                              </Button>
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabName: 'Botium CLI',
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12}>
                          <CustomTextField
                            input={{
                              value: cliCmdLine,
                              readOnly: true,
                            }}
                            label="Botium CLI command line"
                            helperText="Command line to run when using the Botium CLI (standalone or integrated into a CI pipeline)"
                            rows={2}
                            data-unique="txtTestProjectCLI"
                            endAdornment={
                              <Tooltip title="Copy to Link">
                                <Button justIcon data-unique={`btnTestProjectCLICopy`} onClick={() => copyToClipboard(cliCmdLine)}>
                                  <FileCopyIcon />
                                </Button>
                              </Tooltip>
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabName: 'cURL command line',
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12}>
                          <CustomTextField
                            input={{
                              value: 'curl ' + buildTriggerLink,
                              readOnly: true,
                            }}
                            label="Start Test Project with cURL"
                            helperText=
                            "Command line to run when using cURL (standalone or integrated into a CI pipeline) to trigger the Botium Test Project"
                            rows={2}
                            data-unique="txtTestProjectStartProjectWithCurl"
                            endAdornment={
                              <Tooltip title="Copy to Link" >
                                <Button justIcon round data-unique={`btnTestProjectStartProjectWithCurlCopy`} onClick={() => copyToClipboard('curl ' + buildTriggerLink)}>
                                  <FileCopyIcon />
                                </Button>
                              </Tooltip>
                            }
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <CustomTextField
                            input={{
                              value: buildStatusLink ? 'curl ' + buildStatusLink : '',
                              readOnly: true,
                            }}
                            label="Get Status with cURL"
                            helperText="Command line to get the status and result for the Botium Test Project (Build Id required)"
                            rows={2}
                            data-unique="txtTestProjectGetStatusWithCurl"
                            endAdornment={
                              buildStatusLink && <Button justIcon round data-unique={`btnTestProjectGetStatusWithCurlCopy`} onClick={() => copyToClipboard('curl ' + buildStatusLink)}>
                                <FileCopyIcon />
                              </Button>
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabName: 'Build your own',
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12}>
                          <Text bold inline>HTTP(S) Endpoint - Start Test
                            Project:</Text> <Text inline>{`${baseApiLink}/triggerbuild/${testproject.code}`}</Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold inline>HTTP(S) Endpoint - Status Check:</Text> <Text inline>{`${baseApiLink}/build/_BUILDID_`}</Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold>All parameters can be handed over to the webhook either:</Text>
                          <Text>
                          <ul>
                            <li>As HTTP query parameter ("...&amp;PARAMNAME=VALUE...")</li>
                            <li>As JSON body part attribute (Request Body: {'{'} ..."PARAMNAME": "VALUE"...{'}'})</li>
                            <li>As HTTP header with prefix "X-BOTIUM-" ("X-BOTIUM-PARAMNAME": "VALUE")</li>
                          </ul>
                          </Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold inline>APIKEY:</Text> <Text inline>mandatory - <NavLink to={`/settings/security/apikeys`} data-unique="btnTestProjectSettingsApiKeys">An active ApiKey</NavLink></Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold inline>BUILDID:</Text> <Text inline>optional at start, mandatory at status check - Build Identifier to name your Test Results, you can choose your own</Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold inline>TAG:</Text> <Text inline>optional, multiple - Build Tags to attach to the Test Results</Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold inline>REPORTER:</Text> <Text inline>optional - File format to use for the test results (json, junit, csv)</Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold inline>CALLBACKURL:</Text> <Text inline>optional - HTTP(S) endpoint to use for the callback</Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold inline>CALLBACKMETHOD:</Text> <Text inline>optional - HTTP(S) Method to use for the callback</Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold inline>WAIT:</Text> <Text inline>optional - 1 (wait until complete) or 0 (run in background)</Text>
                        </GridItem>
                        <GridItem xs={12}>
                          <Text bold inline>RETRY:</Text> <Text inline>optional - 1 (retry the failed test cases in the latest test session) or 0 (start a new test session)</Text>
                        </GridItem>
                      </GridContainer>
                    )
                  }
                ]}
              />
            </GridItem>
            <GridItem xs={12}>
              <Text header>Step 3/3: Configure your Build Server</Text>
            </GridItem>
            <GridItem xs={12}>
              <Text>Please consult your Build Server documentation on how to integrate
                either HTTP(S)-Calls or command line scripts into the build pipeline.</Text>
            </GridItem>
          </GridContainer>)
        }}
      </Query>
    )
  }

  renderBadges(testproject) {
    const { badges } = this.state
    const { classes } = this.props

    const baseApiLink = config.api.ext

    let statusBadgeLink = `${baseApiLink}/dashboard/${testproject.code}/status`
    let countsBadgeLink = `${baseApiLink}/dashboard/${testproject.code}/counts`
    let countTotalBadgeLink = `${baseApiLink}/dashboard/${testproject.code}/count/total`
    let countSuccessBadgeLink = `${baseApiLink}/dashboard/${testproject.code}/count/success`
    let countFailedBadgeLink = `${baseApiLink}/dashboard/${testproject.code}/count/failed`
    let progressPieLink = `${baseApiLink}/dashboard/${testproject.code}/pie`
    let progressBarLink = `${baseApiLink}/dashboard/${testproject.code}/progress`
    if (badges.text) {
      statusBadgeLink = `${statusBadgeLink}/${encodeURIComponent(badges.text)}`
      countsBadgeLink = `${countsBadgeLink}/${encodeURIComponent(badges.text)}`
      countTotalBadgeLink = `${countTotalBadgeLink}/${encodeURIComponent(badges.text)}`
      countSuccessBadgeLink = `${countSuccessBadgeLink}/${encodeURIComponent(badges.text)}`
      countFailedBadgeLink = `${countFailedBadgeLink}/${encodeURIComponent(badges.text)}`
      progressPieLink = `${progressPieLink}/${encodeURIComponent(badges.text)}`
      progressBarLink = `${progressBarLink}/${encodeURIComponent(badges.text)}`
    }
    if (badges.date) {
      statusBadgeLink = `${statusBadgeLink}?date`
      countsBadgeLink = `${countsBadgeLink}?date`
      countTotalBadgeLink = `${countTotalBadgeLink}?date`
      countSuccessBadgeLink = `${countSuccessBadgeLink}?date`
      countFailedBadgeLink = `${countFailedBadgeLink}?date`
      progressPieLink = `${progressPieLink}?date`
      progressBarLink = `${progressBarLink}?date`
    }

    const renderBadgeRow = (badgeLink, name, ref) => <React.Fragment key={ref}>
      <GridItem xs={12} sm={2} middle center>
        <object className={classes.integrationObject} data={badgeLink} type="image/svg+xml">{name}</object>
      </GridItem>
      <GridItem xs={12} sm={5}>
        <CustomTextField
          input={{
            value: badgeLink,
            readOnly: true
          }}
          label={`Copy Current ${name} Badge Link`}
          data-unique={`txtTestProjectBadge${ref}`}
          endAdornment={
            <Tooltip title="Copy to Link">
              <Button justIcon data-unique={`btnTestProjectBadge${ref}Copy`} onClick={() => copyToClipboard(badgeLink)}>
                <FileCopyIcon />
              </Button>
            </Tooltip>
          }
        />
      </GridItem>
      <GridItem xs={12} sm={5}>
        <CustomTextField
          input={{
            value: `<object data="${badgeLink}" style="max-width: 300px" type="image/svg+xml">${name}</object>`,
            readOnly: true
          }}
          label={`Copy Current ${name} HTML Markup`}
          data-unique={`txtTestProjectMarkup${ref}`}
          endAdornment={
            <Tooltip title="Copy to Link">
              <Button justIcon data-unique={`btnTestProjectMarkup${ref}Copy`} onClick={() => copyToClipboard(`<object data="${badgeLink}" style="max-width: 300px" type="image/svg+xml">${name}</object>`)}>
                <FileCopyIcon />
              </Button>
            </Tooltip>
          }
        />
      </GridItem>
    </React.Fragment>

    const renderChartRow = (chartLink, name, ref) => <React.Fragment key={ref}>
      <GridItem xs={12} sm={2} middle center>
        <object className={classes.integrationObject} data={chartLink} type="image/svg+xml">{name}</object>
      </GridItem>
      <GridItem xs={12} sm={5}>
        <CustomTextField
          input={{
            value: chartLink,
            readOnly: true
          }}
          label={`Copy Current ${name} Chart Link`}
          data-unique={`txtTestProjectChart${ref}`}
          endAdornment={
            <Tooltip title="Copy to Link">
              <Button justIcon data-unique={`btnTestProjectChart${ref}Copy`} onClick={() => copyToClipboard(chartLink)}>
                <FileCopyIcon />
              </Button>
            </Tooltip>
          }
        />
      </GridItem>
      <GridItem xs={12} sm={5}>
        <CustomTextField
          input={{
            value: `<object data="${chartLink}" style="max-width: 300px" type="image/svg+xml">${name}</object>`,
            readOnly: true
          }}
          label={`Copy Current ${name} HTML Markup`}
          data-unique={`txtTestProjectMarkup${ref}`}
          endAdornment={
            <Tooltip title="Copy to Link">
              <Button justIcon round data-unique={`btnTestProjectMarkup${ref}Copy`} onClick={() => copyToClipboard(`<object data="${chartLink}" style="max-width: 300px" type="image/svg+xml">${name}</object>`)}>
                <FileCopyIcon />
              </Button>
            </Tooltip>
          }
        />
      </GridItem>
    </React.Fragment>

    return (<GridContainer>
      <GridItem xs={12}>
        <Text header>Test Status Badges</Text>
      </GridItem>
      <GridItem xs={12}>
        <Text>Copy the hyperlinks and show the current test status within your own dashboard.</Text>
      </GridItem>

      {renderBadgeRow(statusBadgeLink, 'Test Status', 'Status')}
      {renderBadgeRow(countsBadgeLink, 'Test Case Count', 'Counts')}
      {renderBadgeRow(countTotalBadgeLink, 'Test Case Total Count', 'TotalCount')}
      {renderBadgeRow(countSuccessBadgeLink, 'Test Case Success Count', 'SuccessCount')}
      {renderBadgeRow(countFailedBadgeLink, 'Test Case Failed Count', 'FailedCount')}

      <GridItem xs={12} sm={6} />
      <GridItem xs={12}>
        <Text header>Test Progress Charts</Text>
      </GridItem>
      <GridItem xs={12}>
        <Text>Copy the hyperlinks and show the current test session progress within your own dashboard.</Text>
      </GridItem>

      {renderChartRow(progressPieLink, 'Test Progress Pie', 'TestProgressPie')}
      {renderChartRow(progressBarLink, 'Test Progress Bar', 'TestProgressBar')}

      <GridItem xs={12}>
        <Text header>Options</Text>
      </GridItem>
      <GridItem xs={12} sm={6}>
        <CustomTextField
          input={{
            onChange: e => this.setState({ badges: { ...badges, text: e.target.value } }),
            value: badges.text
          }}
          label="Show Text"
          helperText="Text on the badge or chart (if empty, test project name is used)"
          data-unique="txtTestProjectBadgeText"
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <CustomCheckbox
          input={{
            onChange: e => this.setState({ badges: { ...badges, date: e.target.checked } }),
            checked: badges.date
          }}
          label="Show Date of Test Status or Progress"
          data-unique="chkTestProjectBadgeDate"
        />
      </GridItem>
    </GridContainer>)
  }

  toggleChartVisibility = () => {
    this.setState((prevState) => ({
      openSummaryChart: !prevState.openSummaryChart,
    }))
  }


  renderDashboard(testproject) {
    const { classes, user, license, setAlertSuccessMessage, setAlertErrorMessage } = this.props
    const { openSummaryChart } = this.state

    return (<>
      <GridContainer>
        <GridItem xs={12}>
          <Card borderbottom noMargin noPadding>
            <CardBody>
              <GridContainer>
                <GridItem md={12} className={classes.dashboardbuttongrid}>
                  <Button mini
                    data-unique="btnSummaryChartHideOpen"
                    onClick={this.toggleChartVisibility}>
                    <ShowIcon icon="chart-simple" />
                    {openSummaryChart ? 'Hide Summary Chart' : 'Show Summary Chart'}
                  </Button>
                  {this.hasReadPermission() &&
                    <NavLink to={`${this.getRootPath()}/projects/view/${testproject.id}/livechat`}>
                      <Button mini data-unique="btnTestProjectLiveChat">
                        <ShowIcon icon="play-circle" />
                        Record Live Chat ...
                      </Button>
                    </NavLink>
                  }
                  {(!this.hasWritePermission() || !hasPermission(user, 'TESTSESSIONS_CREATE')) &&
                    <GridItem />
                  }
                  {this.hasWritePermission() && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                    <Mutation
                      mutation={START_TESTPROJECT}
                      onCompleted={data => {
                        setAlertSuccessMessage('Test session started ...')
                        this.setState({ newTestSessionCount: this.state.newTestSessionCount + 1 })
                      }}
                      onError={error => {
                        setAlertErrorMessage('Test session failed', error)
                      }}
                      refetchQueries={[
                        ...RefetchTestProjectQueriesOnNewTestSession(testproject.id),
                        ...RefetchTestSessionQueries()
                      ]}
                      update={DeleteTestSessionListsFromCache}
                    >
                      {(startTestProject, { loading, error }) => {
                        if (testproject.performanceTesting){
                          return (<React.Fragment>
                            <Button
                              primaryMini
                              id="start_test_session"
                              data-unique="btnTestProjectStartTestSession"
                              onClick={() => {
                                startTestProject({
                                  variables: {id: testproject.id, debug: false},
                                })
                              }}
                            >
                              <ShowIcon icon={testproject.lastTestSession ? 'redo' : 'play'}/> {testproject.lastTestSession ? 'Repeat Performance Test Session' : 'Start Performance Test Session'}
                            </Button>
                          </React.Fragment>)
                        } else {
                          return (<React.Fragment>
                            <Button leftRound
                                    id="start_test_session"
                                    data-unique="btnTestProjectStartTestSession"
                                    onClick={() => {
                                      startTestProject({
                                        variables: {id: testproject.id, debug: false},
                                      })
                                    }}
                            >
                              <ShowIcon icon={testproject.lastTestSession ? 'redo' : 'play'}/> {testproject.lastTestSession ? 'Repeat Test Session' : 'Start Test Session'}
                            </Button>
                            <DropdownButton
                              rightRound
                              items={[{
                                id: 'start_test_session_logging',
                                name: testproject.lastTestSession ? 'Repeat Test Session (Extended Logging) now' :'Start Test Session (Extended Logging) now',
                                icon: 'bug',
                                disabled: !license.detailedReporting,
                                dataUnique: 'btnTestProjectStartTestSessionDebug',
                                onClick: () => {
                                  startTestProject({
                                    variables: {id: testproject.id, debug: true},
                                  })
                                }
                              }]}
                              data-unique="ddbtnTestProjectStartTestSession"
                            />
                          </React.Fragment>)
                        }
                      }}
                    </Mutation>
                    }
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={12}>
          <ObjectChips chatbot={testproject.chatbot} testSets={testproject.testSets} deviceSets={testproject.deviceSets} />
        </GridItem>
        <GridItem md={12}>
          <Card>
            <CardHeader color="info" >
              <Text header>Recent Test Results</Text>
              <Text subheader>Recent Test Results of this Test Project</Text>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} noPadding>
                  {openSummaryChart && this.renderTestResultsDashboardChart(testproject)}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} noPadding>
                  {this.renderTestResultsDashboard(testproject)}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer></>
    )
  }

  renderTestResultsDashboardChart(testproject) {
    const { history, classes } = this.props
    const where = {
      testProject: { id: testproject.id }
    }
    const orderBy = 'createdAt_DESC'

    const getChartData = (testSessions) => {
      if (testproject.e2eTesting || testproject.gdprTesting || testproject.regressionTesting) {
        return testSessions.map(s => {
          const regressionChartData = s.chartData ? JSON.parse(s.chartData) : {}
          const succeeded = regressionChartData.successCount ? regressionChartData.successCount : 0
          const failed = regressionChartData.failedCount ? regressionChartData.failedCount : 0

          return {
            name: s.updatedAt,
            succeeded: succeeded,
            failed: failed * -1,
          }
        })
      }
      if (testproject.performanceTesting) {
        return testSessions.map(s => {
          const { execDurationAvg, dataStartAtMs, dataEndAtMs } = getAggregatedData(s?.performanceTestSession || [])
          const parallelusers = s.performanceTestSession.results[s.performanceTestSession.results.length-1].execCount
          const averageduration = Math.ceil(execDurationAvg)
          const testduration = ((_.isNil(dataStartAtMs) || _.isNil(dataEndAtMs)) ? '?' : dataEndAtMs - dataStartAtMs) / 1000 / 60
          return {
            name: s.updatedAt,
            parallelusers: parallelusers,
            averageduration: averageduration,
            testduration: testduration
          }
        })
      }
      if (testproject.securityCheck) {
        return testSessions.map(s => {
          const highPrioCount = (s.securityAlerts && s.securityAlerts.filter(a => a.risk === 'High').length) || 0
          const mediumPrioCount = (s.securityAlerts && s.securityAlerts.filter(a => a.risk === 'Medium').length) || 0
          const lowPrioCount = (s.securityAlerts && (s.securityAlerts.length - highPrioCount - mediumPrioCount)) || 0

          return {
            name: s.updatedAt,
            highPrioCount: highPrioCount * -1,
            mediumPrioCount:  mediumPrioCount * -1,
            lowPrioCount: lowPrioCount
          }
        }, { highPrioCount: 0, mediumPrioCount: 0, lowPrioCount: 0 })
      }
      if (testproject.nlpAnalytics) {
        return testSessions.map(s => {
          const f1score = s.trainerSession?.overallStat?.F1 ? s.trainerSession.overallStat.F1 : 0

          return {
            name: s.updatedAt,
            f1score: Math.ceil(f1score * 100)
          }
        })
      }
      return []
    }

    const getAllZero = (dataChart) => {
      if (testproject.e2eTesting || testproject.gdprTesting || testproject.regressionTesting) {
        return dataChart.every(
          (entry) => entry.succeeded === 0 && entry.failed === 0
        )
      }
      if (testproject.performanceTesting) {
        return dataChart.every(
          (entry) => entry.parallelusers === 0 && entry.averageduration === 0
        )
      }
      if (testproject.securityCheck) {
        return dataChart.every(
          (entry) => entry.highPrioCount === 0 && entry.mediumPrioCount === 0 && entry.lowPrioCount === 0
        )
      }
      if (testproject.nlpAnalytics) {
        return dataChart.every(
          (entry) => entry.f1score === 0
        )
      }
    }

    const renderChart = (testSessions) => {
      const onClick = (data) => {
        if (data && data.activePayload && data.activePayload[0] && data.activePayload[0].payload) {
          const session = testSessions.find(s => s.updatedAt === data.activePayload[0].payload.name)
          if (session) {
            if (testproject.e2eTesting) {
              history.push(`/e2e/projects/view/${testproject.id}/results/${session.id}`)
            } else if (testproject.gdprTesting) {
              history.push(`/gdpr/projects/view/${testproject.id}/results/${session.id}`)
            } else if (testproject.regressionTesting) {
              history.push(`/regression/projects/view/${testproject.id}/results/${session.id}`)
            } else if (testproject.performanceTesting) {
              history.push(`/performance/projects/view/${testproject.id}/results/${session.id}`)
            } else if (testproject.securityCheck) {
              history.push(`/security/projects/view/${testproject.id}/results/${session.id}`)
            } else if (testproject.nlpAnalytics) {
              history.push(`/nlp/projects/view/${testproject.id}/results/${session.id}`)
            }
          }
        }
      }

      const dataChart = getChartData(testSessions)
      const allZero = getAllZero(dataChart)
      const CustomLabel = ({ x, y, value, dataChart }) => {
        if (allZero) {
          return (
            <svg
              x={x}
              y={y - 24}
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill={isDarkmode() ? '#D5D9DD' : '#2B3E53'}
            >
              <circle cx="10" cy="10" r="10" stroke="none" fill="currentColor" />
              <text x="10" y="14" fontSize="12" fill="white" textAnchor="middle">0</text>
            </svg>
          )
        }
        return null
      }

      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className={classes.tooltipStyle}>
              <div className={classes.contentStyle}>
                <p className={classes.labelStyle}><DateFormat>{label}</DateFormat></p>
                {payload.map((entry, index) => (
                  <span key={index} className={classes.entryStyle}>
                  <span>{entry.name}:</span> <span style={{ color: entry.fill }}><strong>{Math.abs(entry.value)}</strong></span>
                </span>
                ))}
              </div>
            </div>
          )
        }
        return null
      }

      if(testproject.e2eTesting || testproject.gdprTesting || testproject.regressionTesting) {
        const axisRange = Math.max(
          Math.abs(Math.max(...dataChart.map(d => d.succeeded), 0)),
          Math.abs(Math.min(...dataChart.map(d => d.failed), 0))
        )
        return <ResponsiveContainer width="100%" height={200}>
          <BarChart
            data={dataChart}
            stackOffset="sign"
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
            onClick={onClick}
          >
            <XAxis dataKey="name" hide={true}/>
            <YAxis tick={{fontSize: 12}} stroke={isDarkmode() ? '#D5D9DD' : '#2B3E53'} domain={[-axisRange, axisRange]}
                   hide={true}/>
            <Tooltip content={<CustomTooltip/>} cursor={{fill: 'transparent'}}/>
            <ReferenceLine y={0} stroke={isDarkmode() ? '#D5D9DD' : '#2B3E53'}/>
            <Bar dataKey="succeeded" fill="#008A00" stackId="stack" barSize={40} cursor="pointer">
              <LabelList
                content={(props) => <CustomLabel {...props} data={dataChart}/>}
                position="top"
              />
            </Bar>
            <Bar dataKey="failed" fill="#E70B04" stackId="stack" barSize={40} cursor="pointer"/>
          </BarChart>
        </ResponsiveContainer>
      }
      if(testproject.performanceTesting) {
        return <ResponsiveContainer width="100%" height={200}>
          <ComposedChart
            data={dataChart}
            stackOffset="sign"
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
            onClick={onClick}
          >
            <XAxis dataKey="name" hide={false} tick={false}/>
            <YAxis tick={{ fontSize: 12}} stroke={isDarkmode() ? '#D5D9DD' : '#2B3E53'} hide={true}/>
            <Tooltip content={<CustomTooltip />} cursor={{fill: 'transparent'}} />
            <Area type="monotone" dataKey="averageduration" stroke="#FFB413" fill="#FFB413" fillOpacity={0.1} strokeWidth={2} />
            <Bar dataKey="parallelusers" fill="#8BABF1" barSize={40} cursor="pointer" >
              <LabelList
                content={(props) => <CustomLabel {...props} data={dataChart} />}
                position="top"
              />
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      }
      if(testproject.securityCheck) {
        return <ResponsiveContainer width="100%" height={200}>
          <BarChart
            data={dataChart}
            stackOffset="sign"
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
            onClick={onClick}
          >
            <XAxis dataKey="name" hide={true} />
            <YAxis tick={{ fontSize: 12}} stroke={isDarkmode() ? '#D5D9DD' : '#2B3E53'} hide={true}/>
            <Tooltip content={<CustomTooltip />} cursor={{fill: 'transparent'}} />
            <ReferenceLine y={0} stroke={isDarkmode() ? '#D5D9DD' : '#2B3E53'} />
            <Bar dataKey="lowPrioCount" fill="#9FD400" stackId="stack" barSize={40} cursor="pointer" />
            <Bar dataKey="mediumPrioCount" fill="#FF9800" stackId="stack" barSize={40} cursor="pointer" />
            <Bar dataKey="highPrioCount" fill="#E70B04" stackId="stack" barSize={40} cursor="pointer">
              <LabelList
                content={(props) => <CustomLabel {...props} data={dataChart} />}
                position="top"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      }
      if(testproject.nlpAnalytics) {
        return <ResponsiveContainer width="100%" height={200}>
          <BarChart
            data={dataChart}
            stackOffset="sign"
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
            onClick={onClick}
          >
            <XAxis dataKey="name" hide={true} />
            <YAxis tick={{ fontSize: 12}} stroke={isDarkmode() ? '#D5D9DD' : '#2B3E53'} hide={true}/>
            <Tooltip content={<CustomTooltip />} cursor={{fill: 'transparent'}} />
            <ReferenceLine y={0} stroke={isDarkmode() ? '#D5D9DD' : '#2B3E53'} />
            <Bar dataKey="f1score" fill="#008A00" stackId="stack" barSize={40} cursor="pointer">
              <LabelList
                content={(props) => <CustomLabel {...props} data={dataChart} />}
                position="top"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      }
    }

    return <>
      <GridItem md={12}>
        <Query query={TESTSESSIONS_QUERY}
          variables={{ where, orderBy }}
          fetchPolicy={'no-cache'}
          notifyOnNetworkStatusChange={true}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <RenderSkeletonChartBar />
            if (error) return <ErrorFormat err={error} />
            const sessions = data?.testsessions || []

            const thirtyDaysAgo = moment().subtract(30, 'days')
            const recentSessions = sessions.filter(session =>
              moment(session.updatedAt).isAfter(thirtyDaysAgo)
            )

            return (<>
              <GridContainer paddingTop>
                <GridItem md={12}>
                  { renderChart(recentSessions) }
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem md={12} center largePaddingBottom>
                <Text>Last 30 days <span className={classes.refreshButtonSpace}>|</span> <Button link secondary small noMargin noPadding noCapitalize title="Refresh Chart" aria-label="Refresh Chart" onClick={() => refetch()} data-unique={'btnRefreshChart'}>
                <ShowIcon icon="refresh" /> <Text>Refresh Chart</Text></Button> {recentSessions.length === 0 ? <><span className={classes.refreshButtonSpaceRight}>|</span> <Text displayInline>No tests were run</Text></> : ''}</Text> 
                </GridItem>
              </GridContainer>
            </>)
          }}
        </Query>
      </GridItem>
    </>
  }

  render() {
    const { testproject, user } = this.props

    return (
      <CustomTabs
        name={`TestProjectTabs_${testproject.id}`}
        headerColor="info"
        tabs={[
          {
            tabName: 'Overview',
            tabIcon: <ShowIcon icon="infinity" />,
            disabled: !hasPermission(user, 'TESTSESSIONS_SELECT'),
            tabContent: hasPermission(user, 'TESTSESSIONS_SELECT') && this.renderDashboard(testproject),
            locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/dashboard`,
            dataUnique: 'tabTestProjectDashboard'
          },
          this.hasReadPermission() && {
            tabName: 'Test Case History',
            tabIcon: <ShowIcon icon="history" />,
            tabContent: this.renderTestResultsHistory(testproject),
            locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/history`,
            dataUnique: 'tabTestProjectHistory'
          },
          this.hasReadPermission() && hasAnyPermission(user, ['TESTPROJECTS_CREATE', 'TESTPROJECTS_UPDATE', 'TESTPROJECTS_DELETE', 'TESTSESSIONS_CREATE']) && {
            tabName: 'Configuration',
            tabRight: true,
            tabIcon: <ShowIcon icon="wrench" />,
            tabContent: (
              <React.Fragment>
                {this.renderSettingsForm(testproject)}
              </React.Fragment>
            ),
            locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings`,
            dataUnique: 'tabTestProjectSettings'
          }
        ].filter(t => t)}
      />
    )
  }

  renderTestResults(testproject) {
    const { setAlertSuccessMessage, setAlertErrorMessage, user, license, classes } = this.props
    const tableName = `TestProject_TestSessions_${testproject.id}`

    return (
      <GridContainer>
        {this.hasWritePermission() && hasPermission(user, 'TESTSESSIONS_CREATE') &&
          <GridItem xs={12} right largeMarginTop>
            <Mutation
              mutation={START_TESTPROJECT}
              onCompleted={data => {
                setAlertSuccessMessage('Test session started ...')
                this.setState({ newTestSessionCount: this.state.newTestSessionCount + 1 })
              }}
              onError={error => {
                setAlertErrorMessage('Test session failed', error)
              }}
              refetchQueries={[
                ...RefetchTestProjectQueriesOnNewTestSession(testproject.id),
                ...RefetchTestSessionQueries()
              ]}
              update={DeleteTestSessionListsFromCache}
            >
              {(startTestProject, {loading, error}) =>
                (<React.Fragment>
                    <Button leftRound
                      id="start_test_session"
                      data-unique="btnTestProjectStartTestSession"
                      onClick={() => {
                        startTestProject({
                          variables: { id: testproject.id, debug: false },
                        })
                      }}
                    >
                      <ShowIcon icon="redo" /> Repeat Test Session
                    </Button>
                    <DropdownButton
                      rightRound
                      items={[
                        {
                          id: 'start_test_session_logging',
                          name: 'Start Test Session (Extended Logging) now',
                          icon: 'bug',
                          disabled: !license.detailedReporting,
                          dataUnique: 'btnTestProjectStartTestSessionDebug',
                          onClick: () => {
                            startTestProject({
                              variables: {id: testproject.id, debug: true},
                            })
                          }
                        }]}
                      data-unique="ddbtnTestProjectStartTestSession"
                    />
                </React.Fragment>
              )}
            </Mutation>
          </GridItem >
        }
        <GridItem xs={12}>
          <Card>
            <CardBody noPadding>
              <GridContainer>
                <GridItem xs={12} className={classes.embeddedTable}>
                  <TestSessionsEmbeddedTable key={`TestSessions_${this.state.newTestSessionCount}`} setParentState={this.setParentState} variables={{ testProject: { id: testproject.id } }} name={tableName} hideProjectLink={true} />
                </GridItem>
              </GridContainer>
            </CardBody>
        </Card>
        </GridItem>
    </GridContainer>
    )
  }

  renderTestResultsDashboard(testproject) {
    const { classes } = this.props
    const tableName = `TestProject_Dashboard_TestSessions_${testproject.id}`

    return <GridContainer>
      <GridItem xs={12} className={classes.testSessionsEmbeddedTable}>
        <TestSessionsEmbeddedTable  key={`TestSessions_${this.state.newTestSessionCount}`} setParentState={this.setParentState} variables={{ testProject: { id: testproject.id } }} disableOrderBy disableFilter skip={0} name={tableName} hideProjectLink={true} disableFooter={false}/>
      </GridItem>
    </GridContainer>
  }

  renderTestResultsHistory(testproject) {
    const { classes } = this.props
    const { historyPage, historyRowsPerPage, historyStatus, historyStatusSelection, historyTestCaseFilter } = this.state

    return (<Card><CardBody>
      <Query
        query={TESTPROJECT_HISTORY_QUERY}
        variables={{ testProjectId: testproject.id, status: historyStatus, filter: historyTestCaseFilter, skip: historyPage * historyRowsPerPage, first: historyRowsPerPage }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (error) {
            return <ErrorFormat err={error} />
          }
          const historyTable = data.testprojectHistory || { testsessions: [], count: 0, rows: [] }

          if (!loading && historyTable.testsessions.length === 0) {
            return <GridContainer><GridItem xs={12}>
              <MessageBox
                variant="warning"
                title="No Test Results"
                text="Test Project History is still empty or currently under processing - if you already have recent test results, please check again in a few minutes."
              />
            </GridItem></GridContainer>
          }

          if (historyTable.testsessions.length === 0) {
            historyTable.testsessions = Array.from(Array(25)).map((v, i) => ({ id: i }))
          }

          const lastPage = historyTable && Math.max(0, Math.ceil(historyTable.count / historyRowsPerPage) - 1)
          const hasMore = historyTable && historyTable.count > (historyPage + 1) * historyRowsPerPage

          const hasDevices = loading ? false : !!(historyTable && historyTable.rows.find(h => h.deviceName))

          return (<GridContainer>
            <GridItem xs={12}>
              <TableActionsToolbar>
                <CustomSelect
                  input={{
                    name: 'filterHistory',
                    value: historyStatusSelection,
                    onChange: (e, child) => {
                      if (e.target.value === 'failed') {
                        this.setState({ historyPage: 0, historyStatusSelection: e.target.value, historyStatus: ['FAILED', 'INJECTED'] })
                      } else if (e.target.value === 'injected') {
                        this.setState({ historyPage: 0, historyStatusSelection: e.target.value, historyStatus: ['INJECTED'] })
                      } else if (e.target.value === 'ok') {
                        this.setState({ historyPage: 0, historyStatusSelection: e.target.value, historyStatus: ['SUCCESS', 'RECOVERED'] })
                      } else if (e.target.value === 'recovered') {
                        this.setState({ historyPage: 0, historyStatusSelection: e.target.value, historyStatus: ['RECOVERED'] })
                      } else if (e.target.value === 'changed') {
                        this.setState({ historyPage: 0, historyStatusSelection: e.target.value, historyStatus: ['RECOVERED', 'INJECTED'] })
                      } else {
                        this.setState({ historyPage: 0, historyStatusSelection: e.target.value, historyStatus: null })
                      }
                      return e.target.value
                    },
                  }}
                  multiple={false}
                  items={[
                    { key: 'all', label: 'Show All' },
                    { key: 'failed', label: 'Show Defects' },
                    { key: 'injected', label: 'Show Injected Defects' },
                    { key: 'ok', label: 'Show Passed' },
                    { key: 'recovered', label: 'Show Recovered' },
                    { key: 'changed', label: 'Show Changes' },
                  ]}
                />
                <CustomTextField
                  placeholder={hasDevices ? 'Enter Test Case / Device Filter' : 'Enter Test Case Filter'}
                  input={{
                    name: 'historyTestCaseFilter',
                    value: historyTestCaseFilter,
                    onChange: (e) => {
                      this.setState({
                        historyPage: 0,
                        historyTestCaseFilter: e.target.value
                      })
                    },
                  }}
                />
                <Button smallTop disabled={loading} justIcon round title="Refresh" aria-label="Refresh" onClick={() => refetch()}>
                  <RefreshIcon />
                </Button>
              </TableActionsToolbar>
            </GridItem>
            <GridItem xs={12}>
              <Table className={classes.historyTable}>
                <TableHead className={classNames({ [classes.primaryTableHeader]: true, [classes.historyTableHead]: true })}>
                  <TableRow className={classNames({ [classes.tableRow]: true, [classes.historyTableHeadRow]: true })}>
                    <TableCell colSpan={hasDevices ? 2 : 1} className={classNames({ [classes.tableCell]: true, [classes.historyTableCell]: true })} >
                    <Text icon="square" success inline>&nbsp;</Text> <Text bold inline>Succeeded</Text> <Text icon="square" danger inline>&nbsp;</Text> <Text bold inline>Failed</Text>
                    </TableCell>
                    {historyTable.testsessions.map((ts, index) => (
                      <TableCell rowSpan={2} key={'testsession_' + ts.id + '_' + index} className={classNames({ [classes.tableCell1]: true, [classes.historyTableHeadTestSessionCell]: true })}>
                        <NavLink to={`${this.getRootPath()}/projects/view/${testproject.id}/results/${ts.id}`} className={classes.historyTableHeadTestSessionLink}><Text bold>{ts.createdAt ? moment(ts.createdAt).format('YYYY-MM-DD HH:mm:ss') : '????-??-?? ??:??:??'}</Text></NavLink>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className={classNames({ [classes.tableCell]: true, [classes.historyTableCell]: true })}><Text>Test Case</Text></TableCell>
                    {hasDevices && <TableCell className={classNames({ [classes.tableCell]: true, [classes.historyTableCell]: true })}><Text>Device</Text></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading &&
                    <TableRow key="tr_loading" className={classes.historyTableTestCaseRow}>
                      <TableCell className={classNames({ [classes.tableCell]: true, [classes.historyTableCell]: true, [classes.historyTableCellTestcaseName]: true })}>
                        <Text bold>&nbsp;</Text>
                      </TableCell>
                      {hasDevices &&
                        <TableCell className={classNames({ [classes.tableCell]: true, [classes.historyTableCell]: true })}>
                          <Text bold>&nbsp;</Text>
                        </TableCell>
                      }
                      {historyTable.testsessions.map((ts, index) => <TableCell key={`td_loading_${index}`} className={classNames({ [classes.tableCell]: true, [classes.historyTableTestCaseCell]: true })}>
                        <LoadingIndicator />
                      </TableCell>)}
                    </TableRow>
                  }
                  {!loading && historyTable.rows.map((row, rowIndex) => (
                    <TableRow key={'tr_' + row.testcaseName + rowIndex} className={classes.historyTableTestCaseRow}>
                      <TableCell className={classNames({ [classes.tableCell]: true, [classes.historyTableCell]: true, [classes.historyTableCellTestcaseName]: true })}>
                        <Text bold>{row.testcaseName}</Text>
                      </TableCell>
                      {hasDevices &&
                        <TableCell className={classNames({ [classes.tableCell]: true, [classes.historyTableCell]: true })}>
                          <Text bold>{row.deviceName || ''}</Text>
                        </TableCell>
                      }
                      {
                        row.testcaseResults.map((r, colIndex) => {
                          if (r.success != null) {
                            return <TableCell
                              key={'tcr_' + r.testsession.id + r.id}
                              className={classNames({ [classes.tableCell1]: true, [classes.historyTableTestCaseCell]: true, [classes.historyTableTestCaseCellDanger]: !r.success, [classes.historyTableTestCaseCellSuccess]: r.success })}
                              onClick={() => this.setState({ openHistoryDialog: true, historyCurrentPositionX: colIndex, historyCurrentPositionY: rowIndex })}
                            />
                          } else {
                            return <TableCell key={r.testsession ? r.testsession.id + colIndex : colIndex} className={classNames({ [classes.tableCell]: true, [classes.historyTableTestCaseCell]: true, [classes.historyTableTestCaseCellEmpty]: true })} />
                          }
                        })
                      }
                    </TableRow>
                  ))}
                  {historyTable.count > historyRowsPerPage &&
                    <TableRow key="tr_footer">
                      <TableCell className={classNames({ [classes.tableCell]: true, [classes.historyTableCell]: true })} colSpan={historyTable.testsessions.length + (hasDevices ? 2 : 1)}>
                        <TablePagination
                          component="div"
                          count={historyTable.count}
                          labelDisplayedRows={
                            ({ from, to, count }) => `${from}-${to} of ${count}`
                          }
                          rowsPerPage={historyRowsPerPage}
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          page={historyPage}
                          onChangePage={() => ({})}
                          onChangeRowsPerPage={(event) => this.setState({ historyPage: 0, historyRowsPerPage: event.target.value })}
                          ActionsComponent={() => (
                            <React.Fragment>
                              <Button
                                onClick={() => this.setState({ historyPage: 0 })}
                                disabled={historyPage === 0}
                                aria-label="First Page"
                                justIcon round
                              >
                                <FirstPageIcon />
                              </Button>
                              <Button
                                onClick={() => this.setState({ historyPage: historyPage - 1 })}
                                disabled={historyPage === 0}
                                aria-label="Previous Page"
                                justIcon round
                              >
                                <KeyboardArrowLeft />
                              </Button>
                              <Button
                                onClick={() => this.setState({ historyPage: historyPage + 1 })}
                                disabled={!hasMore}
                                aria-label="Next Page"
                                justIcon round
                              >
                                <KeyboardArrowRight />
                              </Button>
                              <Button
                                onClick={() => this.setState({ historyPage: lastPage })}
                                disabled={!hasMore}
                                aria-label="Last Page"
                                justIcon round
                              >
                                <LastPageIcon />
                              </Button>
                            </React.Fragment>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </GridItem>
            {this.state.openHistoryDialog &&
              <ConfirmationDialog
                okText="Close"
                open={this.state.openHistoryDialog}
                onOk={() => this.setState({ openHistoryDialog: false })}
                title={`${historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[this.state.historyCurrentPositionX].testsession.name} / ${historyTable.rows[this.state.historyCurrentPositionY].testcaseName}`}>
                <Query
                  query={TESTCASERESULT_DETAILS_QUERY}
                  variables={{ id: historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[this.state.historyCurrentPositionX].id }}
                >
                  {(queryResult) => <QueryStatus {...queryResult} query="testsessiontestcaseresult" card>{({ testsessiontestcaseresult }) => {
                    const rd = testsessiontestcaseresult || {}

                    const testsession = historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[this.state.historyCurrentPositionX].testsession
                    const getCurrentTestsessionNum = () => {
                      let count = 0
                      for (let i = 0; i < historyTable.rows[this.state.historyCurrentPositionY].testcaseResults.length; i++) {
                        if (historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[i].id !== null) {
                          count++
                        }
                        if (historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[i].testsession && historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[i].testsession.id === testsession.id) {
                          return count
                        }
                      }
                    }
                    const testCasesLength = historyTable.rows[this.state.historyCurrentPositionY].testcaseResults.filter(r => r.success != null).length
                    const getNextTestSessionId = () => {
                      for (let i = this.state.historyCurrentPositionX - 1; i >= 0; i--) {
                        if (historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[i].id) {
                          return i
                        }
                      }
                    }
                    const nextTestsession = historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[getNextTestSessionId()]
                    const getPreviousTestSessionId = () => {
                      for (let i = this.state.historyCurrentPositionX + 1; i < historyTable.rows[this.state.historyCurrentPositionY].testcaseResults.length; i++) {
                        if (historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[i].id) {
                          return i
                        }
                      }
                    }
                    const previousTestsession = historyTable.rows[this.state.historyCurrentPositionY].testcaseResults[getPreviousTestSessionId()]

                    return (
                      <React.Fragment>
                        <GridContainer nounset alignItems="center">
                          <GridItem xs={12}>
                            {rd.testSet && (
                              <Text bold>
                                Test Set{' '}
                                <NavLink to={`/testsets/view/${rd.testSet.id}`} data-unique={`btnTestProjectTestResultsHistoryTestSet_${rd.testSet.id}`}>
                                  "{rd.testSet.name}"
                                </NavLink>
                                &nbsp;
                              </Text>
                            )}
                            {rd.testSetScript && ['SCRIPTING_TYPE_CONVO', 'SCRIPTING_TYPE_PCONVO'].indexOf(rd.testSetScript.scriptType) >= 0 &&
                              rd.testSet && (
                                <Text bold>
                                  Test Case{' '}
                                  <NavLink
                                    to={`/testsets/view/${rd.testSet.id}/testcases/viewconvo/${rd.testSetScript.id}/${encodeURIComponent(rd.testSetScript.name)}`}
                                    data-unique={`btnTestProjectTestResultsHistoryConvo_${rd.testSetScript.id}_${rd.testSetScript.name}`}>
                                    "{rd.testSetScript.name}"
                                  </NavLink>
                                  &nbsp;
                                </Text>
                              )}
                            {rd.testSetScript && rd.testSetScript.scriptType === 'SCRIPTING_TYPE_UTTERANCES' &&
                              rd.testSet && (
                                <Text bold>
                                  Utterances List{' '}
                                  <NavLink
                                    to={`/testsets/view/${rd.testSet.id}/testcases/viewutterance/${rd.testSetScript.id}/${encodeURIComponent(rd.testSetScript.name)}`}
                                    data-unique={`btnTestProjectTestResultsHistoryUtterance_${rd.testSetScript.id}_${rd.testSetScript.name}`}>
                                    "{rd.testSetScript.name}"
                                  </NavLink>
                                  &nbsp;
                                </Text>
                              )}
                            {rd.testSetScript && ['SCRIPTING_TYPE_CONVO', 'SCRIPTING_TYPE_PCONVO', 'SCRIPTING_TYPE_UTTERANCES'].indexOf(rd.testSetScript.scriptType) < 0 &&
                              rd.testSet && (
                                <Text bold>
                                  Script{' '}
                                  <NavLink
                                    to={`/testsets/view/${rd.testSet.id}/testcases/viewscript/${rd.testSetScript.id}`}
                                    data-unique={`btnTestProjectTestResultsHistoryScript_${rd.testSetScript.id}`}>
                                    "{rd.testSetScript.name}"
                                  </NavLink>
                                  &nbsp;
                                </Text>
                              )}
                            {rd.testSetRepository &&
                              rd.testSet && (
                                <Text bold>
                                  Repository{' '}
                                  <NavLink
                                    to={`/testsets/view/${rd.testSet.id}/settings/remote/viewrepository/${rd.testSetRepository.id}`}
                                    data-unique={`btnTestProjectTestResultsHistoryRepository_${rd.testSetRepository.id}`}>
                                    "{rd.testSetRepository.name}"
                                  </NavLink>
                                  &nbsp;
                                </Text>
                              )}
                            {rd.testSetFolder &&
                              rd.testSet && (
                                <Text bold>
                                  Folder{' '}
                                  <NavLink
                                    to={`/testsets/view/${rd.testSet.id}/settings/remote/viewfolder/${rd.testSetFolder.id}`}
                                    data-unique={`btnTestProjectTestResultsHistoryFolder_${rd.testSetFolder.id}`}>
                                    "{rd.testSetFolder.name}"
                                  </NavLink>
                                  &nbsp;
                                </Text>
                              )}
                            {rd.testSetDownloadLink &&
                              rd.testSet && (
                                <Text bold>
                                  Download Link{' '}
                                  <NavLink
                                    to={`/testsets/view/${rd.testSet.id}/settings/remote/viewdownloadlink/${rd.testSetDownloadLink.id}`}
                                    data-unique={`btnTestProjectTestResultsHistoryDownloadLink_${rd.testSetDownloadLink.id}`}>
                                    "{rd.testSetDownloadLink.name}"
                                  </NavLink>
                                  &nbsp;
                                </Text>
                              )}
                            {rd.testSetExcel &&
                              rd.testSet && (
                                <Text bold>
                                  Excel{' '}
                                  <NavLink
                                    to={`/testsets/view/${rd.testSet.id}/testcases/viewexcel/${rd.testSetExcel.id}`}
                                    data-unique={`btnTestProjectTestResultsHistoryExcel_${rd.testSetExcel.id}`}
                                  >
                                    "{rd.testSetExcel.name}"
                                  </NavLink>
                                  &nbsp;
                                </Text>
                              )}
                            {rd.testSetFilename && (
                              <Text bold>
                                File {rd.testSetFilename}
                                &nbsp;
                              </Text>
                            )}
                            {rd.deviceSet && (
                              <Text bold>
                                Device Set{' '}
                                <NavLink
                                  to={`/settings/devicecloud/devicesets/${rd.deviceSet.id}`}
                                  data-unique={`btnTestProjectTestResultsHistoryDeviceSet_${rd.deviceSet.id}`}
                                >
                                  "{rd.deviceSet.name}"
                                </NavLink>
                                &nbsp;
                              </Text>
                            )}
                            {rd.deviceName && (
                              <Text bold>
                                Device "{rd.deviceName}
                                "&nbsp;
                              </Text>
                            )}
                          </GridItem>
                        </GridContainer>
                        {rd.steps && (
                          <GridContainer>
                            <GridItem xs={12} sm={12}>
                              <Transcript
                                steps={rd.steps && _.orderBy(rd.steps, 'step')}
                                key={rd.id + '_steps'}
                                allowHtmlDisplay={(testsession && testsession.chatbot && testsession.chatbot.allowHtmlDisplay) || false}
                                chatbotId={(testsession && testsession.chatbot && testsession.chatbot.id) || null}
                                avatar={(testsession.chatbot && testsession.chatbot.avatar) || null}
                                containermode={(testsession.chatbot && testsession.chatbot.containermode) || null}
                                attachments={rd.attachments}
                                err={rd.err}
                              />
                            </GridItem>
                          </GridContainer>
                        )}
                        {!rd.steps &&
                          rd.testcaseSource && (
                            <ListItem
                              className={classes.testcaselistnested}
                              key={rd.id + '_src'}
                            >
                              <ListItemText inset>
                                <Text info>{rd.testcaseSource}</Text>
                              </ListItemText>
                            </ListItem>
                          )}
                        {rd.attachments && (
                          <ListItem
                            className={classes.testcaselistnested}
                            key={rd.id + '_att'}
                          >
                            <ListItemText inset>
                              <ImageTiles
                                images={rd.attachments.map(a => ({
                                  downloadSrc: `${config.api.base}/attachment/screenshot/${a.id}`,
                                  id: a.id,
                                  title: a.name,
                                  mimeType: a.mimeType
                                }))}
                                columns={{ xs: 12, sm: 4 }}
                              />
                            </ListItemText>
                          </ListItem>
                        )}
                        <GridContainer>
                          <GridItem xs={4}>
                            {nextTestsession && nextTestsession.id &&
                              <Button data-unique="btnTestProjectHistoryNext" secondary onClick={() => {
                                this.setState({ historyCurrentPositionX: getNextTestSessionId() })
                              }
                              }>
                                <ArrowBackIcon /> Next
                              </Button>}

                          </GridItem>
                          <GridItem xs={4} style={{ textAlign: 'center' }}>
                            <Text bold>Test Session {getCurrentTestsessionNum()} of {testCasesLength}</Text>
                          </GridItem>
                          <GridItem xs={4}>
                            {previousTestsession && previousTestsession.id &&
                              <Button data-unique="btnTestProjectHistoryPrevious" secondary style={{ float: 'right' }} onClick={() => {
                                this.setState({ historyCurrentPositionX: getPreviousTestSessionId() })
                              }
                              }>
                                Previous &nbsp;
                                <ArrowForwardIcon />
                              </Button>
                            }
                          </GridItem>
                        </GridContainer>

                      </React.Fragment>
                    )
                  }}
                  </QueryStatus>}
                </Query>
              </ConfirmationDialog>
            }
          </GridContainer>)
        }}
      </Query>
    </CardBody></Card>)
  }

  isTestTypeEnabled(testType) {
    const { testproject, getConnector } = this.props
    if (testproject && testproject.chatbot) return isTestTypeSupported(testType, getConnector(testproject.chatbot.containermode))
    else return false
  }

  isPerformanceEdition() {
    const { license } = this.props
    return license && (license.performanceTesting)
  }
  isBuildIntegrationEdition() {
    const { license } = this.props
    return license && (license.buildIntegration)
  }
  isCoachEdition() {
    const { license } = this.props
    return license && (license.coach)
  }
  isSecurityEdition() {
    const { license } = this.props
    return license && (license.securityTesting)
  }
  isMonitoringEdition() {
    const { license } = this.props
    return license && (license.monitoring)
  }
  isNotificationsEdition() {
    const { license } = this.props
    return license && (license.notifications)
  }

  _formSettingsApplyUiChanges(values) {
    values.minimumAverageConfidence = !_.isNull(values.minimumAverageConfidence) ? values.minimumAverageConfidence * 100 : null
    values.maximumConfidenceDeviation = !_.isNull(values.maximumConfidenceDeviation) ? values.maximumConfidenceDeviation * 100 : null
    values.minimumConfidenceDiff = !_.isNull(values.minimumConfidenceDiff) ? values.minimumConfidenceDiff * 100 : null
    return values
  }

  calculatePerformanceTestSettingsValues(testproject) {
    if(!testproject.performanceTesting) {
      return {}
    }
    const initialValuesPerformanceTest = Object.assign({},{
      performanceTest_Type: testproject.performanceTestType ? testproject.performanceTestType.toLowerCase() : 'load',
      performanceTest_parallelJobCount: (testproject && testproject.parallelJobCount) || 1,
      performanceTest_tickRepeatInitial: (testproject && testproject.tickRepeatInitial) || 5,
      performanceTest_tickRepeatMax: (testproject && Math.ceil((testproject.tickRepeatInitial + ((testproject.tickMaxTime / 60000 * 60 / (testproject.tickTime / 1000) - 1) * testproject.tickRepeatPerTick)))) || 50,
      performanceTest_tickMaxTimeMinutes: (testproject && testproject.tickMaxTime / 60000) || 1,
      performanceTest_tickTimeSeconds: (testproject && testproject.tickTime / 1000) || 10,
      performanceTest_shareContainer: testproject ? !!testproject.shareContainer : true,
      performanceTest_simpleConvo: testproject ? !!testproject.simpleConvo : true,
      performanceTest_waitForBotTimeoutSeconds: (testproject && testproject.waitForBotTimeout / 1000) || 2,
      performanceTest_requiredPercentOfSuccesfulUsers: (testproject && testproject.requiredPercentOfSuccesfulUsers) || 80,
      performanceTest_notification: (testproject && testproject.notification) || 'PROJECT_DEFAULT',
      performanceTest_detailedReporting: testproject ? !!testproject.detailedReporting : false
    })
    initialValuesPerformanceTest.performanceTest_tickRepeatPerTick = calculateRepeatPerTick(
      initialValuesPerformanceTest.performanceTest_tickRepeatInitial,
      initialValuesPerformanceTest.performanceTest_tickRepeatMax,
      initialValuesPerformanceTest.performanceTest_tickMaxTimeMinutes,
      initialValuesPerformanceTest.performanceTest_tickTimeSeconds
    )

    return initialValuesPerformanceTest
  }

  renderSettingsFormTab(testproject, sectionExpanded, fnForm, fnLeftButtons, fnRightButtons, wide= false) {
    const { license, user, client, setAlertSuccessMessage, setAlertErrorMessage } = this.props

    const initialValues = this._formSettingsApplyUiChanges(Object.assign(
      // default values for old data
      {},
      testproject,
      this.calculatePerformanceTestSettingsValues(testproject)
    ))

    return (<Mutation
      mutation={UPDATE_TESTPROJECT}
      refetchQueries={[
        ...RefetchTestProjectQueries(),
        {
          query: TAGS_QUERY
        }
      ]}
    >
      {(updateTestProject, { loading, error }) => (
        <Form
          mutators={{ ...arrayMutators }}
          onSubmit={async (values, form) => {
            const capabilities = CapabilitiesToGql(
              values.capabilities,
              testproject.capabilities,
            )
            const envs = license.shared ? {} : EnvironmentVariablesToGql(
              values.envs,
              testproject.envs,
            )

            let testSets = values.testSets ?
              values.testSets.map(t => {
                return { id: t.id }
              }) : []

            let deviceSets = values.deviceSets ?
              values.deviceSets.map(d => {
                return { id: d.id }
              }) : []

            const performanceTest = values.performanceTesting ? buildPerformanceTestStartOptions(values) : undefined
            const testProjectPerformanceTest = {}
            if(performanceTest) {
              testProjectPerformanceTest.performanceTestType = values.performanceTest_Type ? values.performanceTest_Type.toUpperCase() : 'LOAD'
              testProjectPerformanceTest.parallelJobCount = performanceTest.parallelJobCount
              testProjectPerformanceTest.tickRepeatInitial = performanceTest.tickRepeatInitial
              testProjectPerformanceTest.requiredPercentOfSuccesfulUsers = performanceTest.requiredPercentOfSuccesfulUsers
              testProjectPerformanceTest.tickMaxTime = performanceTest.tickMaxTime
              testProjectPerformanceTest.tickRepeatPerTick = performanceTest.tickRepeatPerTick
              testProjectPerformanceTest.tickTime = performanceTest.tickTime
              testProjectPerformanceTest.shareContainer = performanceTest.shareContainer
              testProjectPerformanceTest.simpleConvo = performanceTest.simpleConvo
              testProjectPerformanceTest.waitForBotTimeout = performanceTest.waitForBotTimeout
              testProjectPerformanceTest.detailedReporting = performanceTest.detailedReporting
            }

            const request = {
              variables: {
                id: values.id,
                testProject: {
                  name: values.name,
                  description: values.description || null,
                  namespace: values.namespace || null,
                  chatbot: {
                    connect: {
                      id: values.chatbot.id,
                    },
                  },
                  testSets: {
                    set: testSets
                  },
                  deviceSets: {
                    set: deviceSets
                  },
                  tags: {
                    set: values.tags,
                  },
                  securityCheck: this.isSecurityEdition() && this.isTestTypeEnabled('SECURITY') ? !!values.securityCheck : false,
                  nlpAnalytics: this.isCoachEdition() && this.isTestTypeEnabled('NLP') ? !!values.nlpAnalytics : false,
                  batchCount: values.batchCount || null,
                  bail: !!values.bail,
                  cronExpression: values.cronExpression || null,
                  cronTimeZone: values.cronTimeZone || null,
                  notificationReceiversEmail: {
                    set: values.notificationReceiversEmail
                  },
                  notificationExcludeErrors: {
                    set: values.notificationExcludeErrors
                  },
                  notificationOnSuccess: !!values.notificationOnSuccess,
                  notificationForPerformanceTest: values.notificationForPerformanceTest || null,
                  skipTestCasesPatterns: values.skipTestCasesPatterns || null,
                  minimumAverageConfidence: values.minimumAverageConfidence ? values.minimumAverageConfidence / 100 : null,
                  maximumConfidenceDeviation: values.maximumConfidenceDeviation ? values.maximumConfidenceDeviation / 100 : null,
                  minimumConfidenceDiff: values.minimumConfidenceDiff ? values.minimumConfidenceDiff / 100 : null,
                  minimumUtterancesPerIntent: values.minimumUtterancesPerIntent || null,
                  minimumUtterancesPerEntity: values.minimumUtterancesPerEntity || null,
                  ...testProjectPerformanceTest,
                  gdprLanguage: values.gdprLanguage || null,
                  gdprFallbackIntents: {
                    set: values.gdprFallbackIntents || []
                  },
                  gdprFallbackResponses: {
                    set: values.gdprFallbackResponses || []
                  },
                  capabilities,
                  envs
                },
              },
            }
            if (values.agent && values.agent.id) {
              request.variables.testProject.agent = {
                connect: { id: values.agent.id },
              }
            } else if (testproject.agent) {
              request.variables.testProject.agent = {
                disconnect: true,
              }
            }
            request.variables.testProject.registeredComponents = {
              set: (values.registeredComponents || []).map(r => ({ id: r.id })),
            }
            try {
              for (const aggregator of values.aggregators) {
                if (aggregator.id) {
                  await client.mutate({
                    mutation: UPDATE_TESTPROJECT_AGGREGATOR,
                    variables: {
                      id: aggregator.id,
                      aggregator: {
                        asserter: aggregator.asserter,
                        exactValue: aggregator.exactValue,
                        minValue: aggregator.minValue,
                        maxValue: aggregator.maxValue,
                        registeredAggregatorId: aggregator.registeredAggregator.id
                      }
                    },
                    fetchPolicy: 'no-cache'
                  })
                } else {
                  await client.mutate({
                    mutation: CREATE_TESTPROJECT_AGGREGATOR,
                    variables: {
                      testProjectId: values.id,
                      aggregator: {
                        asserter: aggregator.asserter,
                        exactValue: aggregator.exactValue,
                        minValue: aggregator.minValue,
                        maxValue: aggregator.maxValue,
                        registeredAggregatorId: aggregator.registeredAggregator.id
                      }
                    },
                    fetchPolicy: 'no-cache'
                  })
                }
              }
              const toDelete = testproject.aggregators.filter(ta => values.aggregators.findIndex(a => ta.id === a.id) < 0)
              for (const ta of toDelete) {
                await client.mutate({
                  mutation: DELETE_TESTPROJECT_AGGREGATOR,
                  variables: {
                    id: ta.id
                  },
                  fetchPolicy: 'no-cache'
                })
              }

              const res = await updateTestProject(request)
              form.initialize(this._formSettingsApplyUiChanges(Object.assign(
                {},
                res.data.updateTestProject,
                this.calculatePerformanceTestSettingsValues(res.data.updateTestProject))
              ))
              setAlertSuccessMessage('Test Project updated')
            } catch (error) {
              setAlertErrorMessage('Test Project update failed', error)
            }
          }}
          initialValues={initialValues}
          render={({
            handleSubmit,
            form,
            submitting,
            invalid,
            values
          }) => {
            return (<form onSubmit={handleSubmit}>
              <UnsavedFormSpy />
              <GridContainer>
                <GridItem md={12} lg={wide ? 12 : 8}>
                  {fnForm({ form, values, submitting })}
                </GridItem>
                <GridItem md={12} lg={wide ? 12 : 8} largePadding>
                  {hasPermission(user, 'TESTPROJECTS_CREATE') && this.renderCloneDialog(testproject)}
                  <FormActionsToolbar
                    leftButtons={<>
                      {fnLeftButtons && fnLeftButtons({ form, values, submitting })}
                      {hasPermission(user, 'TESTPROJECTS_CREATE') &&
                        <Button
                          onClick={() => {
                            this.setState({ showCloneDialog: true })
                          }}
                          data-unique="btnTestProjectClone"
                          secondary
                        >
                          <ShowIcon icon="clone" />
                          Clone Test Project
                        </Button>
                      }
                    </>}
                    rightButtons={<>
                      {fnRightButtons && fnRightButtons({ form, values, submitting })}
                      {this.hasWritePermission() &&
                        <Button
                          type="submit"
                          disabled={submitting || invalid}
                          data-unique="btnTestProjectSave"
                        >
                          {submitting && <LoadingIndicator alt />}
                          {!submitting && <ShowIcon icon="save" />}
                          Save
                        </Button>
                      }
                    </>}
                  />
                </GridItem>
              </GridContainer>
            </form>
            )
          }
          }
        />
      )}
    </Mutation>)
  }

  renderSettingsForm(testproject) {
    const {
      setAlertSuccessMessage,
      setAlertErrorMessage,
      removeRecentListEntry,
      license,
      history,
      user,
      agentsData,
      deviceSetsData,
      registeredComponentsData,
      registeredAggregatorsData,
      chatbotsData,
      getConnector
    } = this.props

    const getConnectorFeatures = (chatbotId) => {
      if (!chatbotsData || !chatbotsData.chatbots) return false
      const chatbot = chatbotsData.chatbots.find(c => c.id === chatbotId)
      if (!chatbot || !chatbot.containermode) return false

      const connector = getConnector(chatbot.containermode)
      return connector?.features
    }
    const _showFlagRequiredForMetric = (registeredAggregatorId, metricPrefix, flag) => {
      if (flag) return false
      if (!registeredAggregatorId) return false
      if (!registeredAggregatorsData || !registeredAggregatorsData.registeredaggregators) return false

      const r = registeredAggregatorsData.registeredaggregators.find(r => r.id === registeredAggregatorId)
      if (!r || !r.name || !r.name.startsWith(metricPrefix)) return false
      return true
    }

    return (<CustomTabsSecondary
      name={`tabTestProjectSettings_${testproject.id}`}
      headerColor="info"
      tabs={[
        {
          tabName: 'Test Project',
          tabContent: this.renderSettingsFormTab(testproject, 'basic', () => <GridContainer>
            <GridItem md={6}>
              <Field
                name="name"
                component={renderTextField}
                label="Test Project Name"
                validate={required}
                disabled={!this.hasWritePermission()}
                data-unique="txtTestProjectName"
              />
            </GridItem>
            <GridItem md={6}>
              <Field
                name="namespace"
                component={renderNamespaceField}
                forWrite
                label="Namespace"
                disabled={!this.hasWritePermission()}
                data-unique="txtTestProjectNamespace"
              />
            </GridItem>
            <GridItem md={12}>
              <Field
                name="tags"
                component={renderTagField}
                label="Tags"
                disabled={!this.hasWritePermission()}
                data-unique="tagTestProjectTags"
              />
            </GridItem>

            <GridItem md={12}>
              <Field
                name="description"
                component={renderTextArea}
                label="Test Project Description"
                rows={3}
                disabled={!this.hasWritePermission()}
                data-unique="txtTestProjectTestProjectDescription"
              />
            </GridItem>
            <GridItem md={12}>
              <Text muted>{formatCreatedByAndLastChange(testproject)}</Text>
            </GridItem>
          </GridContainer>),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/basic`,
          dataUnique: 'tabTestProjectSettingsBasic'
        },
        {
          tabName: 'Essential',
          tabContent: this.renderSettingsFormTab(testproject, 'essential', () => <GridContainer>
            <GridItem md={12}>
            <MessageBox
              variant="warning"
              title="Be careful! If you change these data, it might make existing test results not relevant anymore."
              text=""
            />
            </GridItem>
            <GridItem md={12}>
              {this.renderChatbots()}
            </GridItem>
            {!testproject.securityCheck && <GridItem md={12}>
              {this.renderTestSets()}
            </GridItem>}
            {deviceSetsData &&
              <GridItem md={12}>
                {this.renderDeviceSets()}
              </GridItem>
            }
          </GridContainer>),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/essential`,
          dataUnique: 'tabTestProjectSettingsEssential'
        },
        (testproject.nlpAnalytics) ? {
          tabName: `NLP Settings`,
          tabContent: this.renderSettingsFormTab(testproject, 'features', ({ values, form }) => {
            return <GridContainer>
              {values.nlpAnalytics && <>
                  <GridItem md={12}>
                    <Divider orientation="horizontal"   />
                  </GridItem>
                  <GridItem md={4}>
                    <Field
                      name="minimumAverageConfidence"
                      component={renderIntField}
                      label="Minimum Average Confidence (%)"
                      validate={composeValidators(minValue(1), maxValue(100))}
                      parse={parseInteger}
                      format={undefined}
                      disabled={!this.hasWritePermission()}
                    />
                  </GridItem>
                  <GridItem md={4}>
                    <Field
                      name="maximumConfidenceDeviation"
                      component={renderIntField}
                      label="Maximum Confidence Deviation (%)"
                      validate={composeValidators(minValue(1), maxValue(100))}
                      parse={parseInteger}
                      format={undefined}
                      disabled={!this.hasWritePermission()}
                    />
                  </GridItem>
                  <GridItem md={4}>
                    <Field
                      name="minimumConfidenceDiff"
                      component={renderIntField}
                      label="Minimum Confidence Difference (%)"
                      validate={composeValidators(minValue(1), maxValue(100))}
                      parse={parseInteger}
                      format={undefined}
                      disabled={!this.hasWritePermission()}
                    />
                  </GridItem>
                  <GridItem md={4}>
                    <Field
                      name="minimumUtterancesPerIntent"
                      component={renderIntField}
                      label="Minimum Utterances per Intent"
                      validate={composeValidators(minValue(1))}
                      parse={parseInteger}
                      format={undefined}
                      disabled={!this.hasWritePermission()}
                    />
                  </GridItem>
                  <GridItem md={4}>
                    <Field
                      name="minimumUtterancesPerEntity"
                      component={renderIntField}
                      label="Minimum Utterances per Entity"
                      validate={composeValidators(minValue(1))}
                      parse={parseInteger}
                      format={undefined}
                      disabled={!this.hasWritePermission()}
                    />
                  </GridItem>
                </>}
            </GridContainer>
          }),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/features`,
          dataUnique: 'tabTestProjectSettingsFeatures'
        } : null,
        (testproject.gdprTesting) ? {
          tabName: `GDPR Settings`,
          tabContent: this.renderSettingsFormTab(testproject, 'gdpr', ({ values, form }) => {
            return <GridContainer>
              <GridItem md={12} lg={6}>
                <Field
                  name="gdprLanguage"
                  component={renderSelect}
                  label="GDPR Language"
                  validate={required}
                  data-unique="selTestProjectRegisterGdprLanguage"
                  items={['en', 'de'].map(l => ({
                    key: l,
                    label: LanguageDisplayName(l),
                    flagIcon: l
                  }))} />
              </GridItem>
              {getConnectorFeatures(testproject.chatbot.id)?.intentResolution &&
                <GridItem md={12} lg={6}>
                  <Field
                    name="gdprFallbackIntents"
                    component={renderAutoSuggest}
                    label="Fallback Intent Names"
                    helperText="Enter a list of NLU Fallback intents which are triggered in case the Chatbot does not recognize the user intent"
                    data-unique="asTestProjectRegisterGdprFallbackIntents"
                  />
                </GridItem>
              }
              <GridItem md={12} lg={6}>
                <Field
                  name="gdprFallbackResponses"
                  component={renderAutoSuggest}
                  label="Fallback Responses"
                  helperText="Enter a list of text responses which are triggered in case the Chatbot does not recognize the user intent"
                  data-unique="asTestProjectRegisterGdprFallbackIntents"
                />
              </GridItem>
            </GridContainer>
          }),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/gdpr`,
          dataUnique: 'tabTestProjectSettingsGdpr'
        } : null,
        (testproject.performanceTesting) ? {
          tabName: 'Performance',
          tabContent: this.renderSettingsFormTab(testproject, 'performance', ({ form, values }) => {
            const { classes } = this.props

            const tiles = [
              {
                name: PERFORMANCE_TEST_MODE_LOAD_TEST,
                header: <div className={classes.performanceTestType}>P. Load Test</div>,
                subheader: <><div className={classes.explanationBox}>
                  Botium <b>generates</b> Constant Load by <b>simulating a constant number of parallel Users.</b>
                </div>
                  <Button mini noMargin fullWidth >
                    Load Test
                  </Button></>
              },
              {
                name: PERFORMANCE_TEST_MODE_STRESS_TEST,
                header: <div className={classes.performanceTestType}>P. Stress Test</div>,
                subheader: <><div className={classes.explanationBox}>
                  Botium generates an Increasing Load by <b>adding new Users every 10 seconds.</b>
                </div>
                  <Button mini noMargin fullWidth >
                    Stress Test
                  </Button></>
              },
              {
                name: PERFORMANCE_TEST_MODE_ADVANCED_TEST,
                header: <div className={classes.performanceTestType}>P. Advanced Test</div>,
                subheader: <><div className={classes.explanationBox}>
                  In Advanced mode <b>you can control every parameter.</b> It gives more freedom, but requires more knowledge about Botium Performance Test.
                </div>
                  <Button mini noMargin fullWidth >
                    Advanced Test
                  </Button></>
              }
            ]

            const advanced = values.performanceTest_Type === PERFORMANCE_TEST_MODE_ADVANCED_TEST
            return (<GridContainer>
              {tiles.map((t, key) => {
                  const selected = values.performanceTest_Type === t.name
                  return <GridItem key={key} md={4} lg={3} style={{ display: 'flex', marginBottom: '30px' }}>
                    <div tabIndex={0} className={classes.testTypeCardWarapper} data-unique={`cardPerformanceTestType-${t.name}`}>
                      <SelectableCard
                        noPadding
                        selected={selected}
                        data-unique={`cardTestSettings-${t.name}-${selected ? 'enabled' : 'disabled'}`}
                        onClick={() => {
                          if (!advanced) form.change('performanceTest_simpleConvo', true)
                          form.change('performanceTest_Type', t.name)
                        }}
                      >
                        <CardBody>
                          <GridContainer>
                            <GridItem lg={12}>
                              {t.header}
                            </GridItem>
                            <GridItem lg={12}>{t.subheader}</GridItem>
                          </GridContainer>
                        </CardBody>
                      </SelectableCard>
                    </div>
                  </GridItem>
                })}
            <GridItem lg={12}>
              <Divider />
              <GridItem largeMarginBottom largeMarginTop lg={12}>
                <Text header>Setup Test Parameters</Text>
              </GridItem>
              <PerformanceTestSessionRegisterForm showUseHello/>
            </GridItem>
          </GridContainer>)
          }, null, null, true),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/performance`,
          dataUnique: 'tabTestProjectSettingsPerformance'
        } : null,
        (!testproject.performanceTesting) ? {
          tabName: 'Execution',
          tabContent: this.renderSettingsFormTab(testproject, 'execution', ({ form }) => <GridContainer>
            <GridItem xs={12} sm={4}>
              <Field
                name="batchCount"
                component={renderIntField}
                label="Parallel Jobs Count"
                validate={license.maxagents ? composeValidators(minValue(1), maxValue(license.maxagents)) : minValue(1)}
                parse={parseInteger}
                format={undefined}
                disabled={!license.parallelexecution || !this.hasWritePermission()}
              />
            </GridItem>
            <GridItem xs={12} sm={8}>
              <Field
                name="agent.id"
                component={renderSelect}
                label="Botium Agent"
                disabled={license.shared || !this.hasWritePermission()}
                data-unique="selTestProjectAgentId"
                loading={agentsData && agentsData.loading}
                error={agentsData && agentsData.error}
                items={
                  (agentsData && agentsData.agents && agentsData.agents.map(a => {
                    return { key: a.id, agent: a }
                  })) || []
                }
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="registeredComponents"
                component={renderSelect}
                label="Involved Registered Component(s)"
                disabled={!this.hasWritePermission()}
                data-unique="selTestProjectRegisteredComponents"
                loading={registeredComponentsData && registeredComponentsData.loading}
                error={registeredComponentsData && registeredComponentsData.error}
                multiple
                valueKeyMap={r => r.id}
                items={[
                  ...((registeredComponentsData && registeredComponentsData.registeredcomponents && registeredComponentsData.registeredcomponents.map(r => {
                    return { key: r.id, label: `${r.name} - ${r.ref}`, value: r }
                  })) || [])]}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="bail"
                component={renderCheckbox}
                label="Stop on First Failure"
                disabled={!this.hasWritePermission()}
                type="checkbox"
                data-unique="chkTestProjectBail"
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="skipTestCasesPatterns"
                component={renderTextField}
                label="Skip Test Cases"
                disabled={!this.hasWritePermission()}
                data-unique="txtTestProjectSettingsSkipPattern"
                helperText={'When running tests, skip any test case matching any of those patterns - multiple patterns separated by comma "," and wildcards "*" are allowed'}
              />
            </GridItem>
            <Divider orientation="horizontal" dividerlgnone />
            <GridItem xs={6}>
              <Field
                name="cronExpression"
                component={renderTextField}
                label="Cron Expression"
                helperText="Enter a Unix-style cron expression to schedule automated test runs"
                validate={cron}
                disabled={!this.hasWritePermission() || !this.isMonitoringEdition()}
                data-unique="txtTestProjectTestCronExpression"
              />
              <Chip
                label="Daily at midnight"
                onClick={(e) => this.isMonitoringEdition() && form.change('cronExpression', '0 0 * * *')}
              />
              <Chip
                label="Daily at 9 am"
                onClick={(e) => this.isMonitoringEdition() && form.change('cronExpression', '0 9 * * *')}
              />
              <Chip
                label="Weekly on Monday at 9 am"
                onClick={(e) => this.isMonitoringEdition() && form.change('cronExpression', '0 9 * * 1')}
              />
              <Chip
                label="Monthly on first day at 9 am"
                onClick={(e) => this.isMonitoringEdition() && form.change('cronExpression', '0 9 1 * *')}
              />
            </GridItem>
            <GridItem xs={6}>
              <Field
                name="cronTimeZone"
                component={renderSelect}
                label="Cron Timezone"
                helperText="Select a Timezone for the cron expression"
                data-unique="selTestProjectTestCronTimeZone"
                items={Intl.supportedValuesOf('timeZone').map(tz => {
                  return {
                    key: tz,
                    label: tz
                  }})}
              />
            </GridItem>
          </GridContainer>),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/execution`,
          dataUnique: 'tabTestProjectSettingsExecution'
        } : null,
        {
          tabName: 'Metrics',
          tabContent: this.renderSettingsFormTab(testproject, 'aggregators', ({ form: { mutators: { push } }, values }) => <GridContainer>
            <GridItem xs={12}>
              <FieldArray name="aggregators">
                {({ fields }) =>
                  fields.map((name, index) => (
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem sm={3}>
                          <Field
                            name={`${name}.registeredAggregator.id`}
                            component={renderSelect}
                            label={`Test Metric`}
                            data-unique={`selTestProjectSettingsAggregatorsSelect_${index}`}
                            disabled={!this.hasWritePermission()}
                            validate={required}
                            items={((registeredAggregatorsData && registeredAggregatorsData.registeredaggregators) || []).map(c => {
                              return {
                                key: c.id,
                                label: c.label
                              }
                            })}
                          />
                        </GridItem>
                        <GridItem sm={3}>
                          <Field
                            name={`${name}.asserter`}
                            component={renderSelect}
                            label="Assertion"
                            disabled={!this.hasWritePermission()}
                            validate={required}
                            data-unique={`selTestProjectSettingsAggregatorsAsserter_${index}`}
                            items={[
                              { key: 'NONE', label: 'Can by anything' },
                              { key: 'EQUALS', label: 'Has to equal' },
                              { key: 'NOT_EQUALS', label: 'Has to be different from' },
                              { key: 'RANGE_INSIDE', label: 'Has to be within range' },
                              { key: 'RANGE_OUTSIDE', label: 'Has to be outside of range' },
                              { key: 'MIN', label: 'Has to be at least' },
                              { key: 'MAX', label: 'Has to be at most' }
                            ]}
                          />
                        </GridItem>
                        <Condition when={`${name}.asserter`} isin={['EQUALS', 'NOT_EQUALS']}>
                          <GridItem sm={2}>
                            <Field
                              name={`${name}.exactValue`}
                              component={renderIntField}
                              label="Value"
                              disabled={!this.hasWritePermission()}
                              validate={required}
                              parse={parseDecimal}
                              format={undefined}
                              data-unique={`intTestProjectSettingsAggregatorsExactValue_${index}`}
                            />
                          </GridItem>
                        </Condition>
                        <Condition when={`${name}.asserter`} isin={['RANGE_INSIDE', 'RANGE_OUTSIDE', 'MIN']}>
                          <GridItem sm={2}>
                            <Field
                              name={`${name}.minValue`}
                              component={renderIntField}
                              label="Min Value"
                              disabled={!this.hasWritePermission()}
                              validate={required}
                              parse={parseDecimal}
                              format={undefined}
                              data-unique={`intTestProjectSettingsAggregatorsMinValue_${index}`}
                            />
                          </GridItem>
                        </Condition>
                        <Condition when={`${name}.asserter`} isin={['RANGE_INSIDE', 'RANGE_OUTSIDE', 'MAX']}>
                          <GridItem sm={2}>
                            <Field
                              name={`${name}.maxValue`}
                              component={renderIntField}
                              label="Max Value"
                              disabled={!this.hasWritePermission()}
                              validate={required}
                              parse={parseDecimal}
                              format={undefined}
                              data-unique={`intTestProjectSettingsAggregatorsMaxValue_${index}`}
                            />
                          </GridItem>
                        </Condition>
                        {this.hasWritePermission() &&
                          <GridItem sm={1} bottom largePadding>
                            <Tooltip title="Delete">
                              <Button
                                onClick={() => fields.remove(index)}
                                justIcon
                                data-unique="btnTestProjectSettingsAggregatorsRemove"
                              >
                                <ShowIcon icon="trash" />
                              </Button>
                            </Tooltip>
                          </GridItem>
                        }
                        {_showFlagRequiredForMetric(_.get(values, `${name}.registeredAggregator.id`), 'botium:nlp', values.nlpAnalytics) &&
                          <GridItem xs={12}><Text warning>NLP Analytics required for this Test Metric</Text></GridItem>
                        }
                        {_showFlagRequiredForMetric(_.get(values, `${name}.registeredAggregator.id`), 'botium:security', values.securityCheck) &&
                          <GridItem xs={12}><Text warning>Security Testing required for this Test Metric</Text></GridItem>
                        }
                      </GridContainer>
                    </GridItem>
                  ))
                }
              </FieldArray>
            </GridItem>
            {this.hasWritePermission() &&
              <GridItem xs={12}>
                <Button
                  onClick={() => push('aggregators', { asserter: 'NONE', action: 'create' })}
                  data-unique="btnTestProjectSettingsAggregatorsAdd"
                  dashed
                  fullWidth
                  secondary
                >
                  <ShowIcon icon="plus" />
                  Add Test Metric to Test Execution
                </Button>
              </GridItem>
            }
          </GridContainer>),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/aggregators`,
          dataUnique: 'tabTestProjectSettingsAggregators'
        },
        {
          tabName: 'Notification',
          tabContent: this.renderSettingsFormTab(testproject, 'notification', () => <GridContainer>
            {!this.isNotificationsEdition() &&
              <GridItem xs={12}>
                <Text warning><FeatureUpgradeNavLink>Email Notifications are not available in this Edition.</FeatureUpgradeNavLink></Text>
              </GridItem>
            }
            <GridItem xs={12}>
              <Field
                name="notificationReceiversEmail"
                component={renderAutoSuggest}
                label="Email notification receivers"
                helperText="Enter a list of email addresses to be notified on test session failures and recovery"
                validate={email}
                disabled={!this.hasWritePermission() || !this.isNotificationsEdition()}
                data-unique="asTestProjectNotificationReceiverEmailSelection"
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="notificationExcludeErrors"
                component={renderAutoSuggest}
                label="Excluded error messages"
                helperText="Enter a list of Test Session Result errors that should be ignored when sending a notification"
                disabled={!this.hasWritePermission() || !this.isNotificationsEdition()}
                data-unique="asTestProjectNotificationExcludeErrorsSelection"
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="notificationOnSuccess"
                component={renderCheckbox}
                label="Send notification email for successful test sessions"
                disabled={!this.hasWritePermission() || !this.isNotificationsEdition()}
                type="checkbox"
                data-unique="chkTestProjectNotificationOnSuccess"
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="notificationForPerformanceTest"
                disabled={!this.hasWritePermission() || !this.isNotificationsEdition()}
                component={renderSelect}
                label="Send notification email for performance test"
                data-unique="selTestProjectNotificationForPerformanceTest"
                items={[
                  { key: 'NEVER', label: 'Never' },
                  { key: 'FAILED', label: 'Failed' },
                  { key: 'ALWAYS', label: 'Always' },
                ]}
              />
            </GridItem>
            <GridItem xs={12}>
              <Text>For advanced notification options, please check out our <a data-unique="btnTestProjectAdvancedNotificationOptions" href="https://support.botium.ai/hc/en-us/articles/6619482260111-Integrating-Botium-with-Zapier" target="_blank" rel="noopener noreferrer">Zapier integration</a>!</Text>
            </GridItem>
          </GridContainer>),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/notification`,
          dataUnique: 'tabTestProjectSettingsNotification'
        },
        {
          tabName: 'Advanced',
          tabContent: this.renderSettingsFormTab(testproject, 'advanced', ({ form: { mutators: { push, pop } } }) => <GridContainer>
            <GridItem xs={12}>
              <Text header>Additional Botium Capabilities</Text>
            </GridItem>
            <GridItem xs={12} largeMarginBottom>
              <Text muted>When running a Test Session, the chatbot settings ("capabilities") are overwritten with these values.</Text>
            </GridItem>
            <GridItem xs={12}>
              <CapabilitiesEdit push={push} pop={pop} field="capabilities" disabled={!this.hasWritePermission()} />
            </GridItem>
            <Divider orientation="horizontal"  dividerlgnone />
            {!license.shared && <>
              <GridItem xs={12}>
                <Text header>System Environment Variables</Text>
              </GridItem>
              <GridItem xs={12} largeMarginBottom>
                <Text muted>When running a Test Session, these System Environment Variables are visible for the Chatbot connector as any other system environment variable.</Text>
              </GridItem>
              <GridItem xs={12} noMargin>
                <EnvsSwitch push={push} pop={pop} field="envs" disabled={!this.hasWritePermission()} />
              </GridItem>
            </>}
          </GridContainer>),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/advanced`,
          dataUnique: 'tabTestProjectSettingsAdvanced'
        },
        {
          tabName: 'Build Server Integration',
          disabled: !hasPermission(user, 'TESTSESSIONS_CREATE'),
          tabContent: hasPermission(user, 'TESTSESSIONS_CREATE') && this.renderTrigger(testproject),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/integration`,
          dataUnique: 'tabTestProjectSettingsIntegration'
        },
        {
          tabName: 'Dashboard Integration',
          tabContent: this.renderBadges(testproject),
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/badges`,
          dataUnique: 'tabTestProjectSettingsBadges'
        },
        {
          tabName: 'Danger Zone',
          tabContent: <GridContainer>
            <GridItem md={8} lg={4}>
              <ListItem>
                <Text danger lg padding><ShowIcon icon="trash" /></Text>
                <GridContainer>
                  <GridItem xs={12}>
                    <Text bold>Delete Test Project</Text>
                  </GridItem>
                  <GridItem xs={12}>
                    <Text>Remove this Test Project and its configuration settings</Text>
                  </GridItem>
                </GridContainer>
                <Mutation
                  mutation={DELETE_TESTPROJECT}
                  onCompleted={data => {
                    removeRecentListEntry({
                      url: `${this.getRootPath()}/projects/view/${testproject.id}`
                    })
                    setAlertSuccessMessage('Test Project deleted')

                    history.push(projectTypeFlagsToUrl(testproject) || '/regression')
                  }}
                  onError={error => {
                    setAlertErrorMessage(
                      'Test Project deletion failed',
                      error,
                    )
                  }}
                  refetchQueries={[
                    ...RefetchTestProjectQueries()
                  ]}
                >
                  {(deleteTestProject, { loading, error }) => (
                    <ConfirmationButton
                      confirmationText={`When deleting the Test Project "${testproject.name}", all configuration settings are removed, as well as the Build Server Integration webhooks - all external DevOps pipelines pointing to this Test Project will fail. Test Results won't be deleted. Are you sure you want to delete it ?`}
                      requireCheck={true}
                      danger
                      small
                      minWidth
                      disabled={!hasPermission(user, 'TESTPROJECTS_DELETE')}
                      onClick={() => {
                        deleteTestProject({
                          variables: { id: testproject.id },
                        })
                      }}
                      data-unique="btnTestProjectDelete"
                    >
                      Delete
                    </ConfirmationButton>
                  )}
                </Mutation>
              </ListItem>
            </GridItem>
          </GridContainer>,
          locationPrefix: `${this.getRootPath()}/projects/view/${testproject.id}/settings/danger`,
          dataUnique: 'tabTestProjectSettingsDanger'
        }
      ].filter(t => t)}
    />)
  }
}

const TestProjectComponent = compose(
  withApollo,
  withStyles(
    (theme) => ({
      ...testprojectsStyle(theme),
    }),
    { withTheme: true },
  ),
  connect(
    state => ({ user: state.token.user, license: state.settings.license, namespace: state.namespace }),
    { getConnector, setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
  ),
  graphql(AGENTS_DROPDOWN_QUERY, {
    skip: (props) => props.license.shared,
    options: {
      fetchPolicy: 'network-only'
    },
    props: ({ data }) => ({
      agentsData: data,
    }),
  }),
  graphql(REGISTEREDCOMPONENTS_QUERY, {
    props: ({ data }) => ({
      registeredComponentsData: data,
    }),
  }),
  graphql(CHATBOTS_DROPDOWN_QUERY, {
    skip: (props) => !props.testproject,
    options: (props) => {
      return {
        variables: projectTypeFlagsToConnectorFeature(props.testproject)
      }
    },
    props: ({ data }) => ({
      chatbotsData: data,
    }),
  }),
  graphql(TESTSETS_DROPDOWN_QUERY, {
    props: ({ data }) => ({
      testSetsData: data,
    }),
  }),
  graphql(DEVICESETS_DROPDOWN_QUERY, {
    skip: (props) => !isLicenseDeviceSetsSupported(props.license),
    props: ({ data }) => ({
      deviceSetsData: data,
    }),
  }),
  graphql(REGISTEREDAGGREGATORS_QUERY, {
    props: ({ data }) => ({
      registeredAggregatorsData: data,
    }),
  }),
)(TestProject)

export default connect(state => ({ user: state.token.user }))(function ({ match, user, ...rest }) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        {match.params && match.params.projectId && (
          <Query query={TESTPROJECT_QUERY} variables={{ id: match.params.projectId }}>
            {(queryResult) => <QueryStatus {...queryResult} query="testproject" renderLoading={() => <RenderSkeletonProjectMenu />}>{(data) => {
                if (typeof data.testproject.securityCheck === 'undefined') {
                  data.testproject.securityCheck = false
                }
                return <TestProjectComponent
                  match={match}
                  testproject={data.testproject}
                  hasReadPermissions={hasPermission(user, 'TESTPROJECTS_SELECT') && canReadNamespace(user, user.namespacePermissions, data.testproject.namespace)}
                  hasWritePermissions={hasAnyPermission(user, ['TESTPROJECTS_CREATE', 'TESTPROJECTS_UPDATE']) && canWriteNamespace(user, user.namespacePermissions, data.testproject.namespace)}
                  {...rest} />
            }}</QueryStatus>}
          </Query>
        )}
      </GridItem>
    </GridContainer>
  )
})
