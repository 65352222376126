import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/Button/Button'
import config from '../../config'

import { setAlertErrorMessage } from 'actions/alert'
import { clearFeed, setFeed } from 'actions/feed'
import Text from 'components/Typography/Text'
import { RenderSkeletonNews } from 'components/Skeleton/skeletonHelper'


class Feed extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      fetching: false
    }
  }

  componentDidMount() {
    const { cacheDate } = this.props

    if (cacheDate) {
      if (moment().diff(cacheDate, 'minutes') <= 15) {
        return
      }
    }
    this.reloadFeed()
  }

  async reloadFeed() {
    const { name, url, setFeed, top } = this.props
    try {
      this.setState({ fetching: true })
      const rssFeedResponse = await fetch(`${config.api.ext}/rss?` + new URLSearchParams({ url }), {
        method: 'GET',
        credentials: 'include'
      })
      const rssFeedContent = await rssFeedResponse.json()
      this.setState({ fetching: false })
      setFeed(name, rssFeedContent.link, rssFeedContent.items.slice(0, top || 4), moment().toDate())
    } catch (err) {
      this.setState({ fetching: false })
    }
  }

  render() {
    const { fetching } = this.state
    const { moreHref, cachedEntries, name, clearFeed } = this.props

    

    if (fetching) {
      return <RenderSkeletonNews/>
    }

    return (<><GridContainer>
      {cachedEntries && cachedEntries.map((e, index) => <GridItem md={6} lg={3} key={index}>
        <GridContainer>
        {e['media:content'] && e['media:content']['$'] && e['media:content']['$'].url && <GridItem xs={12}>
            <a href={e.link}><img alt={e.title} style={{ borderRadius: 5 }} src={e['media:content']['$'].url} /></a>
          </GridItem>}  
          <GridItem xs={12}>
            <Text bold lg header><a href={e.link} target="_blank" rel="noopener noreferrer">{e.title}</a></Text>
          </GridItem>
          <GridItem xs={12}>
            <Text subheader>{e.contentSnippet}</Text>
          </GridItem>
          <GridItem xs={12} smallMarginTop>
           <Text bold md><a href={e.link} target="_blank" rel="noopener noreferrer">Read on!</a></Text>
          </GridItem>
        </GridContainer>
      </GridItem>)}
      <GridItem xs={12} key="reload" right marginTop>

        <Button data-unique="btnRSSRefresh" secondary onClick={() => {
          clearFeed(name)
          this.reloadFeed()
        }}>
          Refresh
        </Button>
        {moreHref &&
            
              <Button data-unique="btnRSSMore" onClick={() => {
                window.open(moreHref)
              }}>
                More
              </Button>
          
        }
      </GridItem>
    </GridContainer></>)
  }
}

Feed.defaultProps = {}

Feed.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  top: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  divider: PropTypes.bool
}

export default withRouter(
  connect(
    (state, ownProps) => ({
      cachedEntries: state.feed[ownProps.name] && state.feed[ownProps.name].entries,
      cacheDate: state.feed[ownProps.name] && state.feed[ownProps.name].date
    }),
    { setFeed, clearFeed, setAlertErrorMessage }
  )(Feed)
)
