import React from 'react'
import StatsText from 'components/Stats/StatsText'
import Text from 'components/Typography/Text'
import { getNLPScoreForTestSession } from 'botium-box-shared/trainer/score'
import _ from 'lodash'
import { withStyles } from '@material-ui/core'
import Tooltip from 'components/Tooltip/Tooltip'
import ShowIcon from 'components/Icon/ShowIcon'
import Chip from 'components/Chip/Chip'
import TestSessionProgress from './TestSessionProgress.jsx'

import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle'
import {
  TESTSESSION_PROGRESS_COACH_QUERY,
  TESTSESSION_PROGRESS_MISUSECHECKER_QUERY,
  TESTSESSIONPROGRESS_COACH_SUBSCRIPTION, TESTSESSIONPROGRESS_MISUSECHECKER_SUBSCRIPTION
} from './gql'
import { PERFORMANCETESTSESSION_PROGRESS_QUERY, PERFORMANCETESTSESSION_PROGRESS_SUBSCRIPTION } from '../PerformanceTestSessions/gql'
import { getAggregatedData } from '../PerformanceTestSessions/helper'
import LoadingIndicator from 'components/Icon/LoadingIndicator.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import {compose} from 'react-apollo'
import {connect} from 'react-redux'
import {formatMillisecondsDuration} from '../helper'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min.js'

class _NewResultStats extends React.Component {
  render() {
    const { ts, classes, type } = this.props
    const effective = ts.type || type

    if (effective === 'regression' || effective === 'e2e' || effective === 'gdpr' || effective === 'monitoring') {
      return <TestSessionProgress testSession={ts} key={`TestSession_RegressionResultStats_${ts.id}`}>
        {({ testSessionProgress }) => {
          if (testSessionProgress && testSessionProgress.chartData) {
            const chartData = JSON.parse(testSessionProgress.chartData)
            let expectedTotalTestCaseCount = 0
            if(chartData) {
              expectedTotalTestCaseCount = chartData.expectedTotalTestCaseCount || chartData.successCount + chartData.failedCount
            }
            if (effective === 'regression' || effective === 'e2e' || effective === 'monitoring' ) {
              return <GridContainer fullWidth data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}`}>
              <GridItem md={12} lg={4} middle flexAuto  >
                <GridContainer fullWidth>
                  <GridItem xs={12} middle noPaddingLeft><Text regular>Total</Text></GridItem>
                  <GridItem xs={12} middle className={classes.textLeftBorderDefault}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_total`} mlg bold>{expectedTotalTestCaseCount}</Text></GridItem>
                </GridContainer>
              </GridItem>
              <GridItem md={12} lg={4} middle flexAuto>
                <GridContainer fullWidth>
                  <GridItem xs={12} middle noPaddingLeft><Text regular>Succeeded</Text></GridItem>
                  <GridItem xs={12} middle className={classes.textLeftBorderGreen}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_success`} mlg bold>{chartData.successCount}</Text></GridItem>
                </GridContainer>
              </GridItem>
              <GridItem md={12} lg={4} middle flexAuto>
                <GridContainer fullWidth>
                  <GridItem xs={12} middle noPaddingLeft><Text regular>Failed</Text></GridItem>
                  <GridItem xs={12} middle className={classes.textLeftBorderRed}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_failed`} mlg bold>{chartData.failedCount}</Text></GridItem>
                </GridContainer>
              </GridItem>

            </GridContainer>
            } else if (effective === 'gdpr') {
              return <><GridContainer fullWidth >
                <GridItem md={12} lg={4} middle flexAuto >
                  <GridContainer fullWidth>
                    <GridItem xs={12} middle noPaddingLeft><Text regular>Custom Answers</Text></GridItem>
                    <GridItem xs={12} middle className={classes.textLeftBorderGreen}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_success`} mlg bold>{chartData.successCount}</Text></GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem md={12} lg={4} middle flexAuto >
                  <GridContainer fullWidth>
                    <GridItem xs={12} middle noPaddingLeft><Text regular>Fallback Answers</Text></GridItem>
                    <GridItem xs={12} middle className={classes.textLeftBorderRed}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_failed`} mlg bold>{chartData.failedCount}</Text></GridItem>
                  </GridContainer>
                </GridItem>
                <TestSessionProgress testSession={ts} key={`TestSession_GdprAuditStats_${ts.id}`}>
                  {({ testSessionProgress }) => {
                    const audit = testSessionProgress.gdprTestSessionAudit
                    if (audit && audit.status === 'FAILED') {
                      return <GridItem md={12} lg={4} middle flexAuto >
                        <GridContainer fullWidth>
                          <GridItem xs={12} middle noPaddingLeft><Text regular>GDPR Issues Identified</Text></GridItem>
                          <GridItem xs={12} middle className={classes.textLeftBorderRed}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_issue`} mlg bold>{audit.issueCount}</Text></GridItem>
                        </GridContainer>
                      </GridItem>
                    } else if (audit && audit.status === 'READY') {
                      return <GridItem md={12} lg={4} middle flexAuto >
                        <GridContainer fullWidth>
                          <GridItem xs={12} middle noPaddingLeft><Text regular>No GDPR Issues Identified</Text></GridItem>
                          <GridItem xs={12} middle className={classes.textLeftBorderGreen}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_success`} mlg bold>{audit.issueCount}</Text></GridItem>
                        </GridContainer>
                      </GridItem>
                    } else {
                      return <GridItem md={12} lg={4} middle flexAuto >
                        <GridContainer fullWidth>
                          <GridItem xs={12} middle noPaddingLeft><Text regular>GDPR Questionaire</Text></GridItem>
                          <GridItem xs={12} middle className={classes.textLeftBorderYellow}><Text lg bold>Not Completed</Text></GridItem>
                        </GridContainer>
                      </GridItem>
                    }
                  }}
                </TestSessionProgress></GridContainer></>
              }

          }
          return null
         }
        }
      </TestSessionProgress>
    }
    if (effective === 'nlp') {
      return <TestSessionProgress
        query={TESTSESSION_PROGRESS_COACH_QUERY}
        subscription={TESTSESSIONPROGRESS_COACH_SUBSCRIPTION}
        testSession={ts}
        key={`TestSession_NlpResultStats_${ts.id}`}>
        {({testSessionProgress}) => {
          if (testSessionProgress && testSessionProgress.trainerSession && testSessionProgress.trainerSession.status === 'READY') {
            const score = getNLPScoreForTestSession(testSessionProgress)
            if (!_.isNil(score)) {
              return <GridContainer fullWidth>
                <GridItem md={12} middle flexAuto>
                  <GridContainer fullWidth>
                    <GridItem xs={12} middle noPaddingLeft><Text regular>Correctness (F1 Score)</Text></GridItem>
                    <GridItem xs={12} middle className={classes.textLeftBorderDefault}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_f1`} mlg bold>{Math.ceil(score * 100)}</Text></GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            } else {
              const chartData = JSON.parse(testSessionProgress.chartData)
              let expectedTotalTestCaseCount = 0
              if(chartData) {
                expectedTotalTestCaseCount = chartData.expectedTotalTestCaseCount || chartData.successCount + chartData.failedCount
              }
              return <GridContainer fullWidth>
                <GridItem md={12} middle flexAuto >
                  <GridContainer fullWidth>
                    <GridItem xs={12} middle noPaddingLeft><Text regular>Correctness (F1 Score)</Text></GridItem>
                    <GridItem xs={12} middle className={classes.textLeftBorderDefault}><Text noWrap data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_f1`} mlg bold>N/A</Text>{expectedTotalTestCaseCount === 0 && <Text paddingLeftRight topMarginsm>/ No Test Cases</Text>}</GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            }
          }
          return null
        }}
      </TestSessionProgress>
    }
    if (effective === 'security') {
      return <TestSessionProgress testSession={ts} key={`TestSession_SecurityResultStats_${ts.id}`}>

        {({ testSessionProgress }) => {

          const totalCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.length) || 0
          const highPrioCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.filter(a => a.risk === 'High').length) || 0
          const mediumPrioCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.filter(a => a.risk === 'Medium').length) || 0
          const lowPrioCount = totalCount - highPrioCount - mediumPrioCount


          return <GridContainer fullWidth>
            <GridItem md={12} lg={4} middle flexAuto>
              <GridContainer fullWidth>
                <GridItem xs={12} middle noPaddingLeft><Text regular >High Priority</Text></GridItem>
                <GridItem xs={12} middle className={classes.textLeftBorderRed}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_high`} mlg bold>{highPrioCount}</Text></GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} lg={4} middle flexAuto >
              <GridContainer fullWidth>
                <GridItem xs={12} middle noPaddingLeft><Text regular >Medium Priority</Text></GridItem>
                <GridItem xs={12} middle className={classes.textLeftBorderOrange}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_medium`} mlg bold>{mediumPrioCount}</Text></GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} lg={4} middle flexAuto >
              <GridContainer fullWidth>
                <GridItem xs={12} middle noPaddingLeft><Text regular >Low Priority</Text></GridItem>
                <GridItem xs={12} middle className={classes.textLeftBorderLightGreen}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_low`} mlg bold>{lowPrioCount}</Text></GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        }}
        </TestSessionProgress>
    }
    if (effective === 'factchecker') {
      return <TestSessionProgress testSession={ts} key={`TestSession_FactCheckerResultStats_${ts.id}`}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
          const dangerCount = (testSessionProgress.factCheckerSession && testSessionProgress.factCheckerSession.incorrectCount) || 0
          const unknownCount = (testSessionProgress.factCheckerSession && (testSessionProgress.factCheckerSession.failedCount + testSessionProgress.factCheckerSession.unknownCount)) || 0
          const correctCount = (testSessionProgress.factCheckerSession && testSessionProgress.factCheckerSession.correctCount) || 0

          return <GridContainer fullWidth>
            <GridItem md={12} lg={4} middle flexAuto>
              <GridContainer>
                <GridItem xs={12} middle noPaddingLeft><Text regular className={classes.NewResultStatsTextLabel}>Accurate</Text></GridItem>
                <GridItem xs={12} middle className={classes.textLeftBorderGreen}>
                  {!isDone && <Text mlg bold>?</Text>}
                  {isDone && correctCount > 0 && <Text mlg bold>{correctCount}</Text>}
                  {isDone && correctCount === 0 && <Text mlg bold>{correctCount}</Text>}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} lg={4}  middle flexAuto>
              <GridContainer>
                <GridItem xs={12} middle noPaddingLeft><Text regular className={classes.NewResultStatsTextLabel}>Inaccurate</Text></GridItem>
                <GridItem xs={12} middle className={classes.textLeftBorderRed}>
                  {!isDone && <Text mlg bold>?</Text>}
                  {isDone && dangerCount > 0 && <Text mlg bold>{dangerCount}</Text>}
                  {isDone && dangerCount === 0 && <Text mlg bold>{dangerCount}</Text>}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} lg={4}  middle flexAuto>
              <GridContainer>
                <GridItem xs={12} middle noPaddingLeft><Text regular className={classes.NewResultStatsTextLabel}>Out of Scope</Text></GridItem>
                <GridItem xs={12} middle className={classes.textLeftBorderYellow}>
                  {!isDone && <Text mlg bold>?</Text>}
                  {isDone && unknownCount > 0 && <Text mlg bold>{unknownCount}</Text>}
                  {isDone && unknownCount === 0 && <Text mlg bold>{unknownCount}</Text>}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        }}
      </TestSessionProgress>
    }
    if (effective === 'misusechecker') {
      return <TestSessionProgress
        query={TESTSESSION_PROGRESS_MISUSECHECKER_QUERY}
        subscription={TESTSESSIONPROGRESS_MISUSECHECKER_SUBSCRIPTION}
        testSession={ts}
        key={`TestSession_MisuseCheckerResultStats_${ts.id}`}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
          const chartData = testSessionProgress && testSessionProgress.misuseCheckerSession && testSessionProgress.misuseCheckerSession.chartData && JSON.parse(testSessionProgress.misuseCheckerSession.chartData)

          return <GridContainer fullWidth>
            <GridItem md={12} lg={4} middle flexAuto>
              <GridContainer fullWidth>
                <GridItem xs={12} middle noPaddingLeft><Text regular >High Violations</Text></GridItem>
                <GridItem xs={12} middle className={classes.textLeftBorderRed}><Text mlg bold>{isDone && chartData ? chartData.highSeverity : '?'}</Text></GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} lg={4} middle flexAuto >
              <GridContainer fullWidth>
                <GridItem xs={12} middle noPaddingLeft><Text regular >Medium Violations</Text></GridItem>
                <GridItem xs={12} middle className={classes.textLeftBorderOrange}><Text mlg bold>{isDone && chartData ? chartData.mediumSeverity : '?'}</Text></GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} lg={4} middle flexAuto >
              <GridContainer fullWidth>
                <GridItem xs={12} middle noPaddingLeft><Text regular >Low Violations</Text></GridItem>
                <GridItem xs={12} middle className={classes.textLeftBorderLightGreen}><Text mlg bold>{isDone && chartData ? chartData.lowSeverity : '?'}</Text></GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        }}
      </TestSessionProgress>
    }
    if (effective === 'performance' && ts.performanceTestSession) {
      return <TestSessionProgress
        query={PERFORMANCETESTSESSION_PROGRESS_QUERY}
        querySelector={data => data.performancetestsession}
        subscription={PERFORMANCETESTSESSION_PROGRESS_SUBSCRIPTION}
        subscriptionSelector={data => data.performanceSessionProgress}
        testSession={{ id: ts.performanceTestSession.id }}>
        {({testSessionProgress}) => {
          if (testSessionProgress && testSessionProgress.results && testSessionProgress.results.length > 0) {

            const { execDurationAvg, dataStartAtMs, dataEndAtMs } = getAggregatedData(testSessionProgress)
            return <GridContainer fullWidth>
              <GridItem md={12} lg={4} middle flexAuto>
                <GridContainer fullWidth>
                  <GridItem xs={12} middle noPaddingLeft><Text regular smLineHeight>Parallel Users</Text></GridItem>
                  <GridItem xs={12} middle className={classes.textLeftBorderBlue}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_tickRepeatInitial`} mlg bold>{testSessionProgress.results[testSessionProgress.results.length-1].execCount}</Text></GridItem>
                </GridContainer>
              </GridItem>
              <GridItem md={12} lg={4} middle flexAuto >
                <GridContainer fullWidth>
                  <GridItem xs={12} middle noPaddingLeft><Text regular smLineHeight>Average duration</Text></GridItem>
                  <GridItem xs={12} middle className={classes.textLeftBorderOrange}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_averageDuration`} mlg bold>{formatMillisecondsDuration(execDurationAvg)}</Text></GridItem>
                </GridContainer>
              </GridItem>
              <GridItem md={12} lg={4} middle flexAuto >
                <GridContainer fullWidth>
                  <GridItem xs={12} middle noPaddingLeft><Text regular smLineHeight>Test duration</Text></GridItem>
                  <GridItem xs={12} middle className={classes.textLeftBorderOrange}><Text data-unique={`txtNewResultStats_${ts.testProject?.name}_${effective}_testDuration`}  mlg bold>{(_.isNil(dataStartAtMs) || _.isNil(dataEndAtMs)) ? '?' : formatMillisecondsDuration(dataEndAtMs - dataStartAtMs)}</Text></GridItem>
                </GridContainer>
              </GridItem>

            </GridContainer>
          }
          return null
        }}
      </TestSessionProgress>
    }
    return null
  }
}
export const NewResultStats = withStyles(testsessionsStyle, { withTheme: true })(_NewResultStats)

class _ResultIcon extends React.Component {
  render() {
    const { ts } = this.props
    if (ts.type === 'regression' || ts.type === 'e2e') {
      return <TestSessionProgress testSession={ts} key={`TestSession_ResultIcon_${ts.id}`}>
        {({ testSessionProgress }) => {
          if (testSessionProgress && testSessionProgress.status === 'READY') {
            return <Tooltip title="All tests succeeded">
              <StatsText success primaryText={<ShowIcon custom icon="success" />} data-type={ts.type} data-status={testSessionProgress.status}/>
              </Tooltip>
          } else if (testSessionProgress && testSessionProgress.status === 'CANCELLED') {
            return <Tooltip title="Cancelled, click here for more information">
              <StatsText error primaryText={<ShowIcon custom icon="stop" />} data-type={ts.type} data-status={testSessionProgress.status}/>
            </Tooltip>
          } else if (testSessionProgress && testSessionProgress.status === 'FAILED') {
            return <Tooltip title="Failed, click here for more information">
              <StatsText error primaryText={<ShowIcon custom icon="error"/>} data-type={ts.type} data-status={testSessionProgress.status} />
              </Tooltip>
          }
          return <StatsText primaryText={<LoadingIndicator />} data-type={ts.type} data-status={testSessionProgress.status}/>
        }}
      </TestSessionProgress>
    }
    if(ts.type === 'factchecker') {
      return <TestSessionProgress testSession={ts} key={`TestSession_ResultIcon_${ts.id}`}>
        {({ testSessionProgress }) => {
          const totalCount = (testSessionProgress.factCheckerSession && testSessionProgress.factCheckerSession.totalCount) || 0
          const dangerCount = (testSessionProgress.factCheckerSession && (testSessionProgress.factCheckerSession.failedCount + testSessionProgress.factCheckerSession.incorrectCount)) || 0
          if (testSessionProgress && testSessionProgress.status === 'CANCELLED') {
            return <Tooltip title="Cancelled, click here for more information">
              <StatsText error primaryText={<ShowIcon custom icon="stop" />} data-type={ts.type} data-status={testSessionProgress.status}/>
            </Tooltip>
          } else if (dangerCount > 0) {
            return <Tooltip title={`Incorrect ${dangerCount > 1 ? 'statements' : 'statement'}, click here for more information`}>
              <StatsText error primaryText={<ShowIcon custom icon="error"/>} data-type={ts.type} data-status={'FAILED'} />
            </Tooltip>
          } else if(totalCount > 0) {
            return <Tooltip title="All tests succeeded">
              <StatsText success primaryText={<ShowIcon custom icon="success" />} data-type={ts.type} data-status={'READY'}/>
            </Tooltip>
          } else if (testSessionProgress && testSessionProgress.status === 'FAILED') {
            return <Tooltip title="Fact Checker Session failed">
              <StatsText error primaryText={<ShowIcon custom icon="error"/>} data-type={ts.type} data-status={'FAILED'} />
            </Tooltip>
          }
          return <StatsText primaryText={<LoadingIndicator />} data-type={ts.type} data-status={testSessionProgress.status}/>
        }}
      </TestSessionProgress>
    }
    if(ts.type === 'misusechecker') {
      return <TestSessionProgress testSession={ts} key={`TestSession_ResultIcon_${ts.id}`}>
        {({ testSessionProgress }) => {
          const totalCount = (testSessionProgress.misuseCheckerSession && testSessionProgress.misuseCheckerSession.totalCount) || 0
          // TODO fix this
          const dangerCount = 0
            // (testSessionProgress.misuseCheckerSession && (testSessionProgress.misuseCheckerSession.failedCount + testSessionProgress.misuseCheckerSession.incorrectCount + testSessionProgress.misuseCheckerSession.unknownCount)) || 0
          if (testSessionProgress && testSessionProgress.status === 'CANCELLED') {
            return <Tooltip title="Cancelled, click here for more information">
              <StatsText error primaryText={<ShowIcon custom icon="stop" />} data-type={ts.type} data-status={testSessionProgress.status}/>
            </Tooltip>
          } else if (dangerCount > 0) {
            return <Tooltip title={`Incorrect ${dangerCount > 1 ? 'statements' : 'statement'}, click here for more information`}>
              <StatsText error primaryText={<ShowIcon custom icon="error"/>} data-type={ts.type} data-status={'FAILED'} />
            </Tooltip>
          } else if(totalCount > 0) {
            return <Tooltip title="All tests succeeded">
              <StatsText success primaryText={<ShowIcon custom icon="success" />} data-type={ts.type} data-status={'READY'}/>
            </Tooltip>
          }
          return <StatsText primaryText={<LoadingIndicator />} data-type={ts.type} data-status={testSessionProgress.status}/>
        }}
      </TestSessionProgress>
    }
    if (ts.type === 'nlp') {
      return <TestSessionProgress
        query={TESTSESSION_PROGRESS_COACH_QUERY}
        subscription={TESTSESSIONPROGRESS_COACH_SUBSCRIPTION}
        testSession={ts}
        key={`TestSession_NlpResultIcon_${ts.id}`}>
        {({testSessionProgress}) => {
          if (testSessionProgress && testSessionProgress.trainerSession && testSessionProgress.trainerSession.status === 'READY') {
            const score = getNLPScoreForTestSession(testSessionProgress)
            if (!_.isNil(score)) {
              if (score >= 0.75) return <StatsText success primaryText={<ShowIcon custom icon="success" />} data-type={ts.type} data-status="READY"/>
              if (score >= 0.5) return <StatsText success primaryText={<ShowIcon custom icon="success" />} data-type={ts.type} data-status="READY"/>
              else return <StatsText warning primaryText={<ShowIcon custom icon="error" />} data-type={ts.type} data-status="READY"/>
            } else {
              return null
            }
          } else if (testSessionProgress && testSessionProgress.trainerSession && testSessionProgress.trainerSession.status === 'FAILED') {
            const _text = () => <StatsText error primaryText={<ShowIcon custom icon="error"/>} data-type={ts.type} data-status={testSessionProgress.trainerSession.status} />
            if (testSessionProgress.trainerSession.err) {
              return <Tooltip title={testSessionProgress.trainerSession.err}>{_text()}</Tooltip>
            } else {
              return _text()
            }
          } else {
            return <StatsText primaryText={<LoadingIndicator />} data-type={ts.type} data-status="RUNNING"/>
          }
        }}
      </TestSessionProgress>
    }
    if (ts.type === 'security') {
      return <TestSessionProgress testSession={ts} key={`TestSession_SecurityResultStats_${ts.id}`}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')

          if (!isDone) {
            return <StatsText primaryText={<LoadingIndicator />} data-type={ts.type} data-status="RUNNING"/>
          } else {
            const allCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.length) || 0
            const dangerCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.filter(a => a.risk === 'High' || a.risk === 'Medium').length) || 0

            if (dangerCount > 0) {
              return <StatsText error primaryText={<ShowIcon custom icon="error" />} data-type={ts.type} data-status="READY"/>
            } else if (allCount > 0) {
              return <StatsText warning primaryText={<ShowIcon custom icon="error" />} data-type={ts.type} data-status="READY"/>
            } else {
              return <StatsText success primaryText={<ShowIcon custom icon="success" />} data-type={ts.type} data-status="READY"/>
            }
          }
        }}
        </TestSessionProgress>
    }
    if (ts.type === 'gdpr') {
      return <TestSessionProgress testSession={ts} key={`TestSession_GDPRResultStats_${ts.id}`}>
        {({ testSessionProgress }) => {
          const isSessionDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
          const isAuditDone = testSessionProgress && testSessionProgress.gdprTestSessionAudit && (testSessionProgress.gdprTestSessionAudit.status === 'READY' || testSessionProgress.gdprTestSessionAudit.status === 'FAILED')

          if (!isSessionDone || !isAuditDone) {
            return <StatsText success primaryText={<ShowIcon custom icon="success" />} data-type={ts.type} data-status="RUNNING"/>
          }
          if (testSessionProgress.status === 'CANCELLED') {
            return <StatsText warning primaryText={<ShowIcon custom icon="stop" />} data-type={ts.type} data-status="CANCELLED"/>
          }
          if (testSessionProgress.status === 'FAILED' || testSessionProgress.gdprTestSessionAudit.status === 'FAILED') {
            return <StatsText warning primaryText={<ShowIcon custom icon="error" />} data-type={ts.type} data-status="FAILED"/>
          }
          return <StatsText success primaryText={<ShowIcon custom icon="success" />} data-type={ts.type} data-status="READY"/>
        }}
        </TestSessionProgress>
    }
    if (ts.type === 'performance') {
      return <TestSessionProgress
        query={PERFORMANCETESTSESSION_PROGRESS_QUERY}
        querySelector={data => data.performancetestsession}
        subscription={PERFORMANCETESTSESSION_PROGRESS_SUBSCRIPTION}
        subscriptionSelector={data => data.performanceSessionProgress}
        testSession={{ id: ts.performanceTestSession.id }}>
        {({testSessionProgress}) => {
          if (testSessionProgress && testSessionProgress.jobs && testSessionProgress.jobs.length > 0) {
            const hasRunning = testSessionProgress.jobs.findIndex(j => j.status === 'RUNNING' || j.status === 'PENDING') >= 0
            if (hasRunning) {
              return <StatsText primaryText={<LoadingIndicator />} data-type={ts.type} data-status="RUNNING"/>
            } else {
              const hasFailed = testSessionProgress.jobs.findIndex(j => j.status === 'FAILED') >= 0
              if (ts.status === 'CANCELLED') {
                return <StatsText error primaryText={<ShowIcon custom icon="stop"/>} data-type={ts.type}
                                  data-status="CANCELLED"/>
              } else if (hasFailed || ts.status === 'FAILED') {
                return <StatsText error primaryText={<ShowIcon custom icon="error"/>} data-type={ts.type}
                                  data-status="FAILED"/>
              } else {
                const {totalFailedCount} = getAggregatedData(testSessionProgress)
                if (totalFailedCount > 0) {
                  return <StatsText error primaryText={<ShowIcon icon="error"/>} data-type={ts.type}
                                    data-status="READY"/>
                } else {
                  return <StatsText success primaryText={<ShowIcon custom icon="success"/>} data-type={ts.type}
                                    data-status="READY"/>
                }
              }
            }
          }
          return null
        }}
      </TestSessionProgress>
    }
    return null
  }
}

export const ResultIcon = withStyles(testsessionsStyle, { withTheme: true })(_ResultIcon)

class _NewResultIcon extends React.Component {
  render() {
    const { ts } = this.props
    if (ts.type === 'regression' || ts.type === 'e2e' || ts.type === 'factchecker' || ts.factCheckerTesting) {
      return <TestSessionProgress testSession={ts} key={`TestSession_ResultIcon_${ts.id}`}>
        {({ testSessionProgress }) => {
          if (testSessionProgress && testSessionProgress.status === 'READY') {
            return ''
          } else if (testSessionProgress && testSessionProgress.status === 'CANCELLED') {
            return <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status={testSessionProgress.status} icon="ban">CANCELLED</Text>
          } else if (testSessionProgress && testSessionProgress.status === 'FAILED') {
            return <>{false && <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status={testSessionProgress.status} icon="triangle-exclamation">FAILED</Text>}</>
          }
          return <Text warningBg bgradius marginRight displayInline iconRotation data-type={ts.type} data-status={testSessionProgress.status} icon="circle-notch">RUNNING</Text>
        }}
      </TestSessionProgress>
    }
    if(ts.type === 'misusechecker' || ts.misuseCheckerTesting) {
      return <TestSessionProgress testSession={ts} key={`TestSession_ResultIcon_${ts.id}`}>
        {({ testSessionProgress }) => {
          if (testSessionProgress) {
            if (testSessionProgress.status === 'CANCELLED') {
              return <Text dangerBg bgradius marginRight displayInline data-type={ts.type}
                           data-status={testSessionProgress.status} icon="ban">CANCELLED</Text>
            } else if (testSessionProgress.status === 'FAILED') {
              return <>{false &&
                <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status={'FAILED'}
                      icon="triangle-exclamation">FAILED</Text>}</>
            } else if (testSessionProgress.status === 'READY') {
              return ''
            }
          }
          return <Text warningBg bgradius marginRight displayInline iconRotation data-type={ts.type} data-status={testSessionProgress.status} icon="circle-notch">RUNNING</Text>
        }}
      </TestSessionProgress>
    }
    if (ts.type === 'nlp') {
      return <TestSessionProgress
        query={TESTSESSION_PROGRESS_COACH_QUERY}
        subscription={TESTSESSIONPROGRESS_COACH_SUBSCRIPTION}
        testSession={ts}
        key={`TestSession_NlpResultIcon_${ts.id}`}>
        {({testSessionProgress}) => {
          if (testSessionProgress && testSessionProgress.status === 'CANCELLED') {
            return <Text dangerBg bgradius marginRight displayInline data-type={ts.type}
                         data-status={testSessionProgress.status} icon="ban">CANCELLED</Text>
          } else if (testSessionProgress && testSessionProgress.trainerSession && testSessionProgress.trainerSession.status === 'READY') {
            const score = getNLPScoreForTestSession(testSessionProgress)
            if (!_.isNil(score)) {
              return ''
            } else {
              return null
            }
          } else if (testSessionProgress && testSessionProgress.trainerSession && testSessionProgress.trainerSession.status === 'FAILED') {
            const _text = () => <>{false && <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status={testSessionProgress.trainerSession.status} icon="triangle-exclamation">FAILED</Text>}</>
            if (testSessionProgress.trainerSession.err) {
              return <Tooltip title={testSessionProgress.trainerSession.err}>{_text()}</Tooltip>
            } else {
              return _text()
            }
          } else {
            return <Text warningBg bgradius iconRotation marginRight displayInline data-type={ts.type} data-status="RUNNING" icon="circle-notch">RUNNING</Text>
          }
        }}
      </TestSessionProgress>
    }
    if (ts.type === 'security') {
      return <TestSessionProgress testSession={ts} key={`TestSession_SecurityResultStats_${ts.id}`}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
          if (!isDone) {
            return <Tooltip title="Test session Running">
                <Text warningBg bgradius iconRotation marginRight displayInline data-type={ts.type} data-status="RUNNING" icon="circle-notch">RUNNING</Text>
              </Tooltip>
          } else {
            const allCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.length) || 0
            const dangerCount = (testSessionProgress.securityAlerts && testSessionProgress.securityAlerts.filter(a => a.risk === 'High' || a.risk === 'Medium').length) || 0
            if (dangerCount > 0) {
              return <>{false && <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status="READY" icon="triangle-exclamation">FAILED</Text>}</>
            } else if (allCount > 0) {
              return <>{false && <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status="READY" icon="triangle-exclamation">FAILED</Text>}</>
            } else if (testSessionProgress && testSessionProgress.status === 'CANCELLED') {
              return <Tooltip title="Cancelled, click here for more information">
                <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status={testSessionProgress.status} icon="ban">CANCELLED</Text>
              </Tooltip>
            } else {
              return ''
            }
          }
        }}
        </TestSessionProgress>
    }
    if (ts.type === 'gdpr') {
      return <TestSessionProgress testSession={ts} key={`TestSession_GDPRResultStats_${ts.id}`}>
        {({ testSessionProgress }) => {
          const isSessionDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
          const isAuditDone = testSessionProgress && testSessionProgress.gdprTestSessionAudit && (testSessionProgress.gdprTestSessionAudit.status === 'READY' || testSessionProgress.gdprTestSessionAudit.status === 'FAILED')
          if (!isSessionDone) {
            return <Tooltip title="Test session Running">
              <Text warningBg bgradius marginRight displayInline iconRotation data-type={ts.type} data-status="RUNNING" icon="circle-notch">RUNNING</Text>
            </Tooltip>
          }
          if (!isSessionDone || !isAuditDone) {
            return ''
          }
          if (testSessionProgress.status === 'CANCELLED') {
            return <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status="CANCELLED" icon="ban">CANCELLED</Text>
          }
          if (testSessionProgress.status === 'FAILED' || testSessionProgress.gdprTestSessionAudit.status === 'FAILED') {
            return <>{false && <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status="FAILED" icon="triangle-exclamation">FAILED</Text>}</>
          }
          return ''
        }}
        </TestSessionProgress>
    }
    if (ts.type === 'performance') {

      return <TestSessionProgress
        query={PERFORMANCETESTSESSION_PROGRESS_QUERY}
        querySelector={data => data.performancetestsession}
        subscription={PERFORMANCETESTSESSION_PROGRESS_SUBSCRIPTION}
        subscriptionSelector={data => data.performanceSessionProgress}
        testSession={{ id: ts.performanceTestSession.id }}>

        {({testSessionProgress}) => {
          if (testSessionProgress && testSessionProgress.jobs && testSessionProgress.jobs.length > 0) {
            const hasRunning = testSessionProgress.jobs.findIndex(j => j.status === 'RUNNING' || j.status === 'PENDING') >= 0
            if (hasRunning) {
              return <Tooltip title="Test session Running">
              <Text warningBg bgradius marginRight displayInline iconRotation data-type={ts.type} data-status="RUNNING" icon="circle-notch">RUNNING</Text>
            </Tooltip>
            } else {
              const hasFailed = testSessionProgress.jobs.findIndex(j => j.status === 'FAILED') >= 0
              if (ts.status === 'CANCELLED') {
                return <Text dangerBg bgradius marginRight displayInline data-type={ts.type} data-status="CANCELLED" icon="ban">CANCELLED</Text>
              } else if (hasFailed || ts.status === 'FAILED') {
                return <>{false && <Text dangerBg bgradius marginRight data-type={ts.type} data-status="FAILED" icon="triangle-exclamation">FAILED</Text>}</>
              } else {
                const {totalFailedCount} = getAggregatedData(testSessionProgress)
                if (totalFailedCount > 0) {
                  return <>{false && <Text dangerBg bgradius marginRight data-type={ts.type} data-status="FAILED" icon="triangle-exclamation">FAILED</Text>}</>
                } else {
                  return ''
                }
              }
            }
          }
          return null
        }}
      </TestSessionProgress>
    }

    return null
  }
}
export const NewResultIcon = withStyles(testsessionsStyle, { withTheme: true })(_NewResultIcon)

class _TestSessionType extends React.Component {
  render() {
    const { classes, ts } = this.props

    const _scheduledType = () => {
      if (ts && ts.testProject && (ts.testProject.monitoring || (ts.testProject.cronExpression && ts.testProject.cronExpression.length > 0))) {
        return <NavLink tabIndex={-1} to={`/${ts.type}/projects/view/${ts.testProject.id}/settings/execution`}>
                <div className={classes.scheduledTypeFlag}><ShowIcon icon="clock" /></div>
              </NavLink>
      }
      return null
    }

    if (ts.type === 'regression') {
      return <><div className={classes.regressiontext + ' ' + classes.scheduledType}>{ts.type.toUpperCase()}</div>{_scheduledType()}</>
    }
    if (ts.type === 'e2e') {
      return <><div className={classes.e2etext + ' ' + classes.scheduledType}>{ts.type.toUpperCase()}</div>{_scheduledType()}</>
    }
    if (ts.type === 'nlp') {
      return <><div className={classes.nlptext + ' ' + classes.scheduledType}>{ts.type.toUpperCase()}</div>{_scheduledType()}</>
    }
    if (ts.type === 'performance') {
      return <><div className={classes.performancetext}>{ts.type.toUpperCase()}</div>{_scheduledType()}</>
    }
    if (ts.type === 'security') {
      return <><div className={classes.securitytext + ' ' + classes.scheduledType}>{ts.type.toUpperCase()}</div>{_scheduledType()}</>
    }
    if (ts.type === 'gdpr') {
      return <><div className={classes.gdprtext + ' ' + classes.scheduledType}>{ts.type.toUpperCase()}</div>{_scheduledType()}</>
    }
    if (ts.type === 'factchecker' || ts.factCheckerTesting) {
      return <><div className={classes.factcheckertext}>FACTCHECK</div>{_scheduledType()}</>
    }
    if (ts.type === 'misusechecker' || ts.misuseCheckerTesting) {
      return <><div className={classes.misusecheckertext}>MISUSE</div>{_scheduledType()}</>
    }
    if (ts.type === 'monitoring') {
      return <><div className={classes.logmonitoringtext}>SCHEDULED</div>{_scheduledType()}</>
    }
    return null
  }
}

export const TestSessionType = withStyles(testsessionsStyle)(_TestSessionType)

class _TestSessionRegressionE2ETypeChip extends React.Component {
  constructor(props) {
    super(props)
    this.e2eContainerNames = props.settings.allconnectors.filter(c => c.features.e2eTesting).map(c => c.name)
  }

  _assignSessionTypes(ts) {
    if (ts.chatbot && this.e2eContainerNames.includes(ts.chatbot.containermode)) {
      ts.type = 'e2e'
    } else {
      ts.type = 'regression'
    }
    return ts
  }

  render() {
    const { ts } = this.props

    if(!ts.type) {
      this._assignSessionTypes(ts)
    }

    if (ts.type === 'regression') {
      return <Chip regression tooltip={`Test type: ${ts.type}`} variant="info" label={ts.type.toUpperCase()}/>
    }
    if (ts.type === 'e2e') {
      return <Chip e2e tooltip={`Test type: ${ts.type}`} variant="info" label={ts.type.toUpperCase()}/>
    }
    return null
  }
}

export const TestSessionRegressionE2ETypeChip = compose(
  withStyles(testsessionsStyle),
  connect(
    (state, ownProps) => ({
      settings: state.settings,
      namespace: state.namespace,
    })
  )
)(_TestSessionRegressionE2ETypeChip)

class _NlpSessionProgressIcon extends React.Component {

  render() {
    const { testSession, classes } = this.props

    return <TestSessionProgress
      query={TESTSESSION_PROGRESS_COACH_QUERY}
      subscription={TESTSESSIONPROGRESS_COACH_SUBSCRIPTION}
      testSession={testSession}
      key={`TestSession_NlpResultIcon_${testSession.id}`}>
      {({testSessionProgress}) => {
        if (testSessionProgress && testSessionProgress.trainerSession && testSessionProgress.trainerSession.status === 'READY') {
          const score = getNLPScoreForTestSession(testSessionProgress)
          if (!_.isNil(score)) {
            return ''
          } else {
            return null
          }
        } else if (testSessionProgress && testSessionProgress.trainerSession && testSessionProgress.trainerSession.status === 'FAILED') {
          const _text = () => <>{false && <div className={classes.resultIconPositionNavLink}><Text dangerBg bgradius marginRight displayInline data-type={testSession.type} data-status={testSessionProgress.trainerSession.status} icon="triangle-exclamation">FAILED</Text></div>}</>
          if (testSessionProgress.trainerSession.err) {
            return <Tooltip title={testSessionProgress.trainerSession.err}>{_text()}</Tooltip>
          } else {
            return _text()
          }
        } else {
          return <div className={classes.resultIconPositionNavLink}><Text warningBg bgradius iconRotation marginRight displayInline data-type={testSession.type} data-status="RUNNING" icon="circle-notch">RUNNING</Text></div>
        }
      }}
    </TestSessionProgress>
  }
}

export const NlpSessionProgressIcon = compose(
  withStyles(testsessionsStyle),
  connect(
    (state, ownProps) => ({
      settings: state.settings,
      namespace: state.namespace,
    })
  )
)(_NlpSessionProgressIcon)

export const getRootPath = (location, length = 1) => {
  return '/' + location.pathname.split('/').filter(p => p).slice(0, length).join('/')
}
