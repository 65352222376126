import dashboardStyle from './dashboardStyle.jsx'
import cardTitleStyle from 'assets/jss/material-dashboard-react/components/cardTitleStyle.jsx'

const chatbotsStyle = theme => ({
  ...dashboardStyle(theme),
  ...cardTitleStyle(theme),
  quickcreatechatbot: {
    paddingTop: 20,
    justifyContent: 'space-between'
  },
  leftDatacards: {
    paddingTop: 25,
  },
  dashboardbutton: {
    color: theme.colors.primary,
  },
  liveChatArea: {
    paddingTop: 10,
    paddingBottom: 10,
    overflowY: 'scroll',
    height: 'calc(100vh - 400px)',
    width: '100%',
  },
  liveChatMessage: {
    backgroundColor: theme.colors.testsetseditorbg,
    marginTop: 15,
    borderRadius: 5,
  },
  CustomTextField: {
    margin: 10,
    '& textarea': {
      minHeight: 46,
      paddingLeft: '0!important',
      resize: 'none!important',
    },
  },
  timePosition: {
    marginTop: -1,
    color: theme.colors.primary
  },
  tablePosition: {
    position: 'relative',
    top: '-80px',
  },
  namespace: {
    border: `1px solid ${theme.colors.borderColorhover} !important`,
    borderRadius: 5,
    padding: '3px 10px'
  },
  embeddedTableNamespace: {
    '& a':{
      '& div':{
        top: '0!Important'
      }
    }
  },
  embeddedTable: {
    '&.table': {
      '& td': {
        padding: '0',
      },
    },
    '& td': {
      verticalAlign: 'top!Important',
      '&:last-child': {
        paddingRight: '1px!Important',
      },
    },
  },
  mediaChipHide: {
    display: 'none'
  },
  mediaChip: {
    position: 'relative',
    left: -34,
    paddingLeft: 4,
    '& svg': {
      color: theme.colors.common.primary,
    }
  },
  chatbotServerSidePagingTable: {
    '& table': {
      //borderCollapse: 'collapse',
      '& tr': {
        height: 70
      },
      '& tr:last-child td': {
        borderBottom: `0px solid ${theme.colors.tableRowBorder}`
      },
    }
  },
  chatbotTestSessionsEmbeddedTable: {
    '& table': {
      //borderCollapse: 'collapse',
      '& tr': {
        height: 70
      },
      '& tr:last-child td': {
        borderBottom: `0px solid ${theme.colors.tableRowBorder}`
      },
    }
  },
  projectLink: {
    textDecoration: 'underline'
  },
  audioChip: {
    position: 'relative',
    paddingLeft: 3,
    marginLeft: -45,
    '& svg': {
      color: theme.colors.common.primary,
    }
  },
  audioPlayButton: {
    position: 'relative',
    left: -4,
    zIndex: 1,
    color: `${theme.colors.common.primary}!important`
  },
})

export default chatbotsStyle
