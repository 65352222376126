import React from 'react'
import { connect } from 'react-redux'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// apollo
import {Query, graphql, compose, Mutation} from 'react-apollo'
// core components
import Button from 'components/Button/Button'
import LinkButton from 'components/Button/LinkButton'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import DateFormat from 'components/Info/DateFormat'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import QueryStatus from 'components/Info/QueryStatus'
import ShowIcon from 'components/Icon/ShowIcon'
import ObjectChips from 'components/Chip/ObjectChips'
import Tooltip from 'components/Tooltip/Tooltip'

import TestSessionProgress from './TestSessionProgress.jsx'
import GDPRSessionAudit from './GDPRSessionAudit.jsx'
import TestSessionTestCases from './TestSessionTestCases.jsx'
import GDPRSessionSuggestions, { getFallbackTooltip } from './GDPRSessionSuggestions.jsx'
import GDPRSessionReport from './GDPRSessionReport.jsx'
import { getRootPath } from './helper'
import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'

import {
  TESTSESSION_QUERY,
  RETRY_TESTSESSION_GDPR,
  DeleteTestSessionListsFromCache,
  RefetchTestSessionQueries, DELETE_TESTSESSION, CANCEL_TESTSESSION
} from './gql'

import {hasPermission, canWriteNamespace, hasAnyPermission} from 'botium-box-shared/security/permissions'
import Text from 'components/Typography/Text'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import ListItem from 'components/List/ListItem/ListItem'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import Divider from 'components/Divider/Divider'
import { RenderSkeletonProjectMenu } from 'components/Skeleton/skeletonHelper'


class GDPRSession extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testcaseProps: null
    }
  }

  hasWritePermission(user, testsession) {
    return hasAnyPermission(user, ['TESTSESSIONS_CREATE', 'TESTSESSIONS_UPDATE']) && canWriteNamespace(user, user.namespacePermissions, testsession.namespace)
  }

  renderReport(testsession) {
    return (<GridContainer><GridItem xs={12}>
      <TestSessionProgress testSession={testsession}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED')
          if (isDone) {
            return <GDPRSessionReport testSession={testsession} />
          } else {
            return <LoadingIndicator />
          }
        }}
        </TestSessionProgress>
    </GridItem></GridContainer>)
  }

  renderDashboard(testsession, loading) {
    const { user, history, classes } = this.props
    const { mutateRetryTestSessionGdpr } = this.props

    const location = this.props.location

    const fallbackTooltip = getFallbackTooltip(testsession)

    return (<GridContainer>
      <TestSessionProgress testSession={testsession}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED')
          const isAuditDone = testSessionProgress && testSessionProgress.gdprTestSessionAudit && (testSessionProgress.gdprTestSessionAudit.status === 'READY' || testSessionProgress.gdprTestSessionAudit.status === 'FAILED')
          const chartData = testSessionProgress && testSessionProgress.chartData && JSON.parse(testSessionProgress.chartData)

          return (<>
            <GridItem xs={12} right middle smallPadding>
            <Text primary>Recent Test Session</Text><Text paddingLeftRight>  |  </Text><Text primary><DateFormat seconds>{testsession.createdAt}</DateFormat></Text>
              {hasPermission(user, 'TESTSESSIONS_CREATE') && canWriteNamespace(user, user.namespacePermissions, testsession.namespace) &&
                <Button
                  primaryMini
                  data-unique="btnRetryTestSessionGdpr"
                  onClick={() => {
                    mutateRetryTestSessionGdpr({
                      variables: { id: testsession.id }
                    })
                  }}
                >
                  <ShowIcon icon="redo" /> Repeat Test Session
                </Button>
              }
            </GridItem>

            <GridItem lg={12} grid>
              <GridContainer border borderRadius noMargin>
                <GridItem lg borderRight noPadding grid>
                  <Card noBorder noMarginBottom noMarginTop hoverlight borderRadiusRight>
                    <CardBody LargePadding>
                      <LinkButton gray noUnderline
                        data-unique="btnTestSessionChartDataTotalCount"
                        onClick={() => {
                          this.setState({
                            testcaseProps: {
                              filter: {
                                showSucceeded: true,
                                showFailed: true,
                                filterText: '',
                                page: 0
                              }
                            }
                          })
                          history.push(`${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testsession.id}/testcases`)
                        }}
                      >
                        <GridContainer fullWidth paddingLeft>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Total</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderDefault}>
                            <Text mlg bold>{!chartData ? (isDone ? '0' : '?') : chartData.totalCount}</Text>
                          </GridItem>
                        </GridContainer>
                      </LinkButton>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem lg borderRight noPadding grid>
                  <Card noBorder noMarginBottom noMarginTop hoverlight noBorderRadius>
                    <CardBody LargePadding>
                      <LinkButton gray noUnderline
                        data-unique="btnTestSessionChartDataTestCasesSucceeded"
                        onClick={() => {
                          this.setState({
                            testcaseProps: {
                              filter: {
                                showSucceeded: true,
                                showFailed: false,
                                filterText: '',
                                page: 0
                              }
                            }
                          })
                          history.push(`${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testsession.id}/testcases`)
                        }}
                      >
                        <GridContainer fullWidth paddingLeft>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Custom Answers</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderGreen}>
                            <Text mlg bold>{!chartData ? (isDone ? '0' : '?') : chartData.successCount}</Text>
                          </GridItem>
                        </GridContainer>
                      </LinkButton>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem lg borderRight noPadding grid>
                  <Tooltip title={fallbackTooltip}>
                    <Card noBorder noMarginBottom noMarginTop hoverlight noBorderRadius>
                      <CardBody LargePadding>
                        <LinkButton gray noUnderline
                          data-unique="btnTestSessionChartDataTestCasesFailed"
                          onClick={() => {
                            this.setState({
                              testcaseProps: {
                                filter: {
                                  showSucceeded: false,
                                  showFailed: true,
                                  filterText: '',
                                  page: 0
                                }
                              }
                            })
                            history.push(`${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testsession.id}/testcases`)
                          }}
                        >
                        <GridContainer fullWidth paddingLeft>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Fallback Answers</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderRed}>
                            <Text mlg bold>{!chartData ? (isDone ? '0' : '?') : chartData.failedCount}</Text>
                          </GridItem>
                        </GridContainer>
                        </LinkButton>
                      </CardBody>
                    </Card>
                  </Tooltip>
                </GridItem>
                <GridItem lg borderRight>
                  <Card noBorder noMarginBottom  noMarginTop>
                    <CardBody LargePadding>
                      <LinkButton gray noUnderline
                        data-unique="btnTestSessionChartDataTestCasesSucceeded"
                        onClick={() => {
                          this.setState({
                            testcaseProps: {
                              filter: {
                                showSucceeded: true,
                                showFailed: false,
                                filterText: '',
                                page: 0
                              }
                            }
                          })
                          history.push(`${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testsession.id}/audit`)
                        }}
                      >
                       <TestSessionProgress testSession={testsession} key={`TestSession_GdprAuditStats_${testsession.id}`}>
                  {({ testSessionProgress }) => {
                    const audit = testSessionProgress.gdprTestSessionAudit
                    if (audit && audit.status === 'FAILED') {
                      return <GridItem lg middle flexAuto >
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>GDPR Issues Identified</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderRed}><Text mlg bold>{audit.issueCount}</Text></GridItem>
                        </GridContainer>
                      </GridItem>
                    } else if (audit && audit.status === 'READY') {
                      return <GridItem lg middle flexAuto >
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>No GDPR Issues Identified</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderGreen}><Text mlg bold>{audit.issueCount}</Text></GridItem>
                        </GridContainer>
                      </GridItem>
                    } else {
                      return <GridItem lg middle flexAuto >
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>GDPR Questionaire</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderYellow}><Text lg bold>Not Completed</Text></GridItem>
                        </GridContainer>
                      </GridItem>
                    }
                  }}
                </TestSessionProgress>
                      </LinkButton>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <ObjectChips rootPath={getRootPath(location)} dataUniquePrefix="btnTestSession" testProject={testsession.testProject} chatbot={testsession.chatbot} testSets={testsession.testSets} deviceSets={testsession.deviceSets} />
            </GridItem>
            {isAuditDone && <>
              <GridItem xs={12}>
                <Text header>GDPR Questionaire</Text>
              </GridItem>
              <GridItem xs={12}>
                <Card>
                  <CardBody>
                    <GDPRSessionSuggestions testSession={testsession} />
                  </CardBody>
                </Card>
              </GridItem>
            </>}
          </>)
        }}
      </TestSessionProgress>
    </GridContainer>)
  }

  getRootPath = () => {
    return getRootPath(this.props.location)
  }

  render() {
    const { match, user, license, history } = this.props
    const testSessionId = match.params.id

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Query query={TESTSESSION_QUERY} variables={{ id: testSessionId }}>
            {(queryResult) => <QueryStatus {...queryResult} query="testsession" renderLoading={() => <RenderSkeletonProjectMenu />}>{({ testsession, loading }) => {
              if (!testsession) testsession = { id: testSessionId, results: [], jobs: [], testProject: { nlpAnalytics: false } }
              if (!testsession.gdprTestSessionAudit) testsession.gdprTestSessionAudit = { status: 'PENDING' }
              return (
                <CustomTabs
                  name={`tabGDPRSession_${testSessionId}`}
                  headerColor="info"
                  plainTabs
                  tabs={[
                    {
                      tabName: 'Overview',
                      tabIcon: <ShowIcon icon="tachometer-alt" />,
                      tabContent: this.renderDashboard(testsession, loading),
                      locationPrefix: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testSessionId}/dashboard`,
                      dataUnique: 'btnTestSessionNavigationDashboard'
                    },
                    {
                      tabName: 'Questionaire',
                      tabIcon: testsession.gdprTestSessionAudit.status === 'READY' ? <ShowIcon icon="check-square" /> : testsession.gdprTestSessionAudit.status === 'FAILED' ? <ShowIcon icon="exclamation-triangle" /> : <ShowIcon icon="question" />,
                      tabContent: loading ? <LoadingIndicator box/> : <GDPRSessionAudit testSession={testsession} />,
                      locationPrefix: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testSessionId}/audit`,

                      dataUnique: 'btnTestSessionNavigationAudit',
                      disabled: !!loading
                    },
                    {
                      tabName: 'Report',
                      tabIcon: <ShowIcon icon="file-alt" />,
                      tabContent: loading ? <LoadingIndicator box/> : this.renderReport(testsession),
                      locationPrefix: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testSessionId}/report`,
                      dataUnique: 'btnTestSessionNavigationReport',
                      disabled: !!loading
                    },
                    {
                      tabName: 'Test Cases',
                      tabIcon: <ShowIcon icon="list-ul" />,
                      tabContent: loading ? <LoadingIndicator box/> : <TestSessionTestCases testSession={testsession} {...this.state.testcaseProps || {}} />,
                      locationPrefix: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testSessionId}/testcases`,
                      dataUnique: 'btnTestSessionNavigationTestCases',
                      disabled: !!loading
                    },
                    this.hasWritePermission(user, testsession) && hasPermission(user, 'TESTSESSIONS_DELETE') && {
                      tabName: 'Danger Zone',
                      tabRight: true,
                      tabIcon: <ShowIcon icon="exclamation-triangle" />,
                      locationPrefix: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testSessionId}/danger`,
                      dataUnique: 'btnTestSessionNavigationDangerZone',
                      tabContent: (
                        <GridContainer key="danger">
                          {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_DELETE') &&
                            <React.Fragment>
                              <GridItem md={8} lg={4}>
                                <ListItem>
                                  <Text lg danger padding><ShowIcon icon="trash" /></Text>

                                  <GridContainer nounset>
                                    <GridItem md={12}><Text bold>Delete Test Session</Text></GridItem>
                                    <GridItem md={12}><Text>This removes the Test Session and its results</Text></GridItem>
                                  </GridContainer>
                                  <Mutation
                                    mutation={DELETE_TESTSESSION}
                                    onCompleted={data => {
                                      removeRecentListEntry({
                                        url: `${this.getRootPath()}/projects/view/${testsession.testProject.id}/results/${testsession.id}`
                                      })
                                      setAlertSuccessMessage('Test Session deleted')
                                      history.push(`${this.getRootPath()}/projects/view/${testsession.testProject.id}`)
                                    }}
                                    onError={error => {
                                      setAlertErrorMessage('Test session deletion failed', error)
                                    }}
                                    update={DeleteTestSessionListsFromCache}
                                  >
                                    {(
                                      deleteTestSession,
                                      { loading, error },
                                    ) => (
                                      <ConfirmationButton
                                        confirmationText={`When deleting this Test Session, all it's test results are lost. You have to start another Test Session to get current results. If the session is running, it will be cancelled first. Are you sure you want to delete it ?`}
                                        requireCheck={true}
                                        danger
                                        small
                                        minWidth
                                        onClick={() => {
                                          deleteTestSession({
                                            variables: { id: testsession.id },
                                          })
                                        }}
                                        data-unique="btnTestSessionDelete"
                                      >

                                        Delete
                                      </ConfirmationButton>
                                    )}
                                  </Mutation>
                                </ListItem>
                              </GridItem>
                              <GridItem md={8}></GridItem>
                            </React.Fragment>
                          }
                          {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_DELETE') && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                            <React.Fragment>
                              <GridItem md={8} lg={4}><Divider dense /></GridItem>
                              <GridItem md={12} lg={8}></GridItem>
                            </React.Fragment>
                          }
                          {hasPermission(user, 'TESTSESSIONS_CREATE') &&
                            <React.Fragment>
                              <GridItem md={8} lg={4}>
                                <ListItem>
                                  <Text lg danger padding><ShowIcon icon="power-off" /></Text>
                                  <GridContainer nounset>
                                    <GridItem md={12}><Text bold>Send Cancellation Request</Text></GridItem>
                                    <GridItem md={12}><Text>Send cancellation request to Test Session</Text></GridItem>
                                  </GridContainer>
                                  <Mutation
                                    mutation={CANCEL_TESTSESSION}
                                    onCompleted={data => {
                                      setAlertSuccessMessage('Sent cancellation request to Test Session')
                                      this.setState({ testSessionProgressKey: this.state.testSessionProgressKey + 1 })
                                    }}
                                    onError={error => {
                                      setAlertErrorMessage('Sending cancellation request to Test Session failed', error)
                                    }}
                                  >
                                    {(
                                      cancelTestSession,
                                      { loading, error },
                                    ) => (
                                      <TestSessionProgress testSession={testsession}>
                                        {({ testSessionProgress }) => (
                                          <ConfirmationButton
                                            confirmationText={`When cancelling a Test Session, all background processing will be stopped and the test results are not complete. Are you sure you want to cancel it ?`}
                                            requireCheck={true}
                                            danger
                                            small
                                            minWidth
                                            disabled={testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED'}
                                            onClick={() => {
                                              cancelTestSession({
                                                variables: { id: testsession.id },
                                              })
                                            }}
                                            data-unique="btnTestSessionCancel"
                                          >
                                            Send
                                          </ConfirmationButton>
                                        )}
                                      </TestSessionProgress>
                                    )}
                                  </Mutation>
                                </ListItem>
                              </GridItem>
                              <GridItem xs={8}></GridItem>
                            </React.Fragment>
                          }
                          {!license.maxtestsessionsperhour && hasPermission(user, 'TESTSESSIONS_CREATE') &&
                            <React.Fragment>
                              <GridItem xs={4}><Divider dense /></GridItem>
                              <GridItem xs={8}></GridItem>
                            </React.Fragment>
                          }
                        </GridContainer>
                      ),
                    }
                  ]}
                />
              )
            }}</QueryStatus>}
          </Query>
        </GridItem>
      </GridContainer>
    )
  }
}

const GDPRSessionWithGraphql = compose(
  graphql(RETRY_TESTSESSION_GDPR, {
    props: ({ mutate }) => ({
      mutateRetryTestSessionGdpr: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.retryTestSessionGdpr.id
        props.setAlertSuccessMessage('GDPR Test session started ...')
        props.history.push(`${getRootPath(props.location)}/projects/view/${props.match.params.projectId}/results/${testSessionId}`)

      },
      onError: (error) => {
        props.setAlertErrorMessage('GDPR Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestSessionQueries(data.retryTestSessionGdpr.id)
      ],
      update: DeleteTestSessionListsFromCache
    })
  })
)(GDPRSession)

export default connect(
  state => ({ token: state.token.token, user: state.token.user, license: state.settings.license, features: state.settings.features }),
  { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
)(withStyles(testsessionsStyle, { withTheme: true })(GDPRSessionWithGraphql))
