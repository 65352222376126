import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
// apollo
import {compose, graphql, Mutation, Query} from 'react-apollo'
// core components
import Table from 'components/Table/AdvancedTable.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CustomTabs from 'components/Tabs/CustomTabs.jsx'
import { setAlertSuccessMessage, setAlertErrorMessage } from 'actions/alert'
import { removeRecentListEntry } from 'actions/activity'
import QueryStatus from 'components/Info/QueryStatus'
import ShowIcon from 'components/Icon/ShowIcon'
import ObjectChips from 'components/Chip/ObjectChips'
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog.jsx'
import Text from 'components/Typography/Text.jsx'
import LinkButton from 'components/Button/LinkButton'
import Transcript from 'components/Convo/Transcript'
import ErrorFormat from 'components/Info/ErrorFormat'
import LoadingIndicator from 'components/Icon/LoadingIndicator'
import Button from 'components/Button/Button'
import DropdownButton from 'components/Button/DropdownButton'
import Tooltip from 'components/Tooltip/Tooltip'
import ExpansionPanel from 'components/Expansion/ExpansionPanel'
import ExpansionPanelSummary from 'components/Expansion/ExpansionPanelSummary'
import ListItem from 'components/List/ListItem/ListItem'
import ListItemText from 'components/List/ListItem/ListItemText'
import DateFormat from 'components/Info/DateFormat'
import ExpansionPanelDetails from 'components/Expansion/ExpansionPanelDetails'
import ListItemIcon from 'components/List/ListItem/ListItemIcon'
import Chip from 'components/Chip/Chip'
import { isDarkmode } from 'components/Darkmode/helper'
import AdvancedTable from 'components/Table/AdvancedTable'
import TablePagination from 'components/Table/CustomTablePagination'
import Divider from 'components/Divider/Divider'
import ConfirmationButton from 'components/Button/ConfirmationButton'
import {CustomCheckbox, CustomTextField} from 'components/Form/Form'
import TestSessionProgress from './TestSessionProgress.jsx'
import ConversationStartMessages from 'assets/img/crawler/conversationstart.svg'
import ConversationStartMessagesDarkmode from 'assets/img/crawler/darkmode/conversationstart.svg'
import misuseAllowesDomains from 'assets/img/crawler/misusealloweddomains.svg'
import misuseAllowesDomainsDarkmode from 'assets/img/crawler/darkmode/misusealloweddomainsdarkmode.svg'
import misuseApprovedTopics from 'assets/img/crawler/misuseapprovedtopics.svg'
import misuseApprovedTopicsDarkmode from 'assets/img/crawler/darkmode/misuseapprovedtopicsdarkmode.svg'
import misuseConfusedSentences from 'assets/img/crawler/misuseconfusedsentences.svg'
import misuseConfusedSentencesDarkmode from 'assets/img/crawler/darkmode/misuseconfusedsentencesdarkmode.svg'
import misuseDistractionTopics from 'assets/img/crawler/misusedistractiontopics.svg'
import misuseDistractionTopicsDarkmode from 'assets/img/crawler/darkmode/misusedistractiontopicsdarkmode.svg'
import misuseIgnoredSentences from 'assets/img/crawler/misuseignoredsentences.svg'
import misuseIgnoredSentencesDarkmode from 'assets/img/crawler/darkmode/misuseignoredsentencesdarkmode.svg'
import misuseNumberofCycles from 'assets/img/crawler/misusenumberofcycles.svg'
import misuseNumberofCyclesDarkmode from 'assets/img/crawler/darkmode/misusenumberofcyclesdarkmode.svg'
import testsessionsStyle from 'assets/jss/material-dashboard-react/views/testsessionsStyle.jsx'
import { PieChart, Pie, Cell, ResponsiveContainer, BarChart, XAxis, YAxis, Bar } from 'recharts'
import { RenderSkeletonProjectMenu } from 'components/Skeleton/skeletonHelper'
import {canWriteNamespace, hasAnyPermission, hasPermission} from 'botium-box-shared/security/permissions'
import {
  TESTSESSION_QUERY,
  TESTSESSION_PROGRESS_MISUSECHECKER_QUERY,
  TESTSESSIONPROGRESS_MISUSECHECKER_SUBSCRIPTION,
  MISUSECHECKER_SESSION_RESULT_QUERY,
  RefetchTestSessionQueries,
  DeleteTestSessionListsFromCache,
  MISUSECHECKER_SESSIONJOB_LOGS_QUERY,
  MISUSECHECKER_SESSION_RESULTCOUNT_QUERY,
  MISUSECHECKER_SESSION_RESULTCOUNT_SUBSCRIPTION,
  MISUSECHECKER_SESSION_RESULTS_QUERY,
  MISUSECHECKER_SESSION_RESULTS_SUBSCRIPTION, DELETE_TESTSESSION, CANCEL_TESTSESSION
} from './gql.js'
import { START_MISUSECHECKERPROJECT } from '../LLMprojects/gql'
import {RefetchTestProjectQueriesOnNewTestSession} from '../TestProjects/gql'
import config from 'config'

const MISUSE_ATTEMPS_MAPPING = {
  1: 'Short',
  2: 'Standard',
  3: 'Extended',
  4: 'Exhaustive'
}

class MisuseCheckerSession extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      testSessionProgressKey: 0,
      resultText: null,
      filter: {
        showHighViolations: false,
        showMediumViolations: false,
        showLowViolations: false,
        showSuccess: false,
        page: 0,
        rowsPerPage: 25,
      },
      filterHighViolations: false,
      filterMediumViolations: false,
      filterLowViolations: false,
      filterResultExplanation: false,
    }
  }

  hasWritePermission(testsession) {
    const { user } = this.props
    return hasAnyPermission(user, ['TESTSESSIONS_CREATE', 'TESTSESSIONS_UPDATE']) && canWriteNamespace(user, user.namespacePermissions, testsession.namespace)
  }

  renderRepeatButtons(testsession) {
    const { mutateStartMisuseCheckerProject, user, license } = this.props
    return (<GridItem lg={12} right middle smallPadding smallMarginRight>
      {hasPermission(user, 'TESTSESSIONS_CREATE') && this.hasWritePermission(testsession) &&
        <TestSessionProgress testSession={testsession} key={this.state.testSessionProgressKey}>
          {({ testSessionProgress }) => {
            const repeatItems = []
            if (testsession.misuseCheckerSession && testsession.misuseCheckerSession.project && license.detailedReporting) {
              repeatItems.push({
                id: 'repeatTestSessionDebug',
                name: 'Repeat Test Session (Extended Logging)',
                dataUnique: 'ddbtniRepeatLog',
                disabled: testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED',
                icon: 'bug',
                onClick: () => {
                  mutateStartMisuseCheckerProject({
                    variables: { id: testsession.misuseCheckerSession.project.id, debug: true }
                  })
                }
              })
            }
            return (<>{(testsession.misuseCheckerSession && testsession.misuseCheckerSession.project) && <>
                <Button leftRound
                        id="repeatTestSession"
                        data-unique="btnRepeatFullTestSession"
                        disabled={testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED'}
                        icon="play-circle"
                        onClick={() => {
                          mutateStartMisuseCheckerProject({
                            variables: { id: testsession.misuseCheckerSession.project.id, debug: false }
                          })
                        }
                        }
                ><ShowIcon icon="redo" /> Repeat Test Session</Button>
                <DropdownButton
                  rightRound
                  data-unique="ddbtnRepeatTestSession"
                  disabled={repeatItems.length === 0 || (testSessionProgress.status !== 'READY' && testSessionProgress.status !== 'FAILED' && testSessionProgress.status !== 'CANCELLED')}
                  items={repeatItems}
                ></DropdownButton></>}
              </>
            )
          }}
        </TestSessionProgress>
      }
    </GridItem>)
  }

  renderDashboard(testsession, loading) {
    const { classes, history } = this.props
    const misuseCheckerTestProjectId = this.props.match.params.projectId


    if (loading) return (<LoadingIndicator />)

    return (<GridContainer>
      <TestSessionProgress
        key={this.state.testSessionProgressKey}
        query={TESTSESSION_PROGRESS_MISUSECHECKER_QUERY}
        subscription={TESTSESSIONPROGRESS_MISUSECHECKER_SUBSCRIPTION}
        testSession={testsession}>
        {({ testSessionProgress }) => {
          const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
          const chartData = testSessionProgress && testSessionProgress.misuseCheckerSession && testSessionProgress.misuseCheckerSession.chartData && JSON.parse(testSessionProgress.misuseCheckerSession.chartData)
          return (
            <React.Fragment>
              {this.renderRepeatButtons(testsession)}
              <GridItem lg={12} grid>
                <GridContainer border borderRadius noMargin>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom  noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Total</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderDefault}><Text mlg bold>{isDone && chartData ? chartData.totalStatements : '?'}</Text></GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight noPadding grid widthPercent>
                    <Card noBorder noMarginBottom  noMarginTop hoverlight noBorderRadius>
                      <CardBody
                        LargerPadding
                        cursorPointer
                        tabIndex={0}
                        data-unique="btnMisuseCheckerSessionChartDataHigh"
                        onClick={() => {
                          this.setState({ filterHighViolations: true, filterMediumViolations: false, filterLowViolations: false })
                          history.push(`/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testsession.id}/violations`)
                        }}
                      >
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>High Violations</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderRed}>
                            <Text mlg bold>{isDone && chartData ? chartData.highSeverity : '?'}</Text>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight noPadding grid widthPercent>
                    <Card noBorder noMarginBottom  noMarginTop hoverlight noBorderRadius>
                      <CardBody
                        LargerPadding
                        cursorPointer
                        tabIndex={0}
                        data-unique="btnMisuseCheckerSessionChartDataMedium"
                        onClick={() => {
                          this.setState({ filterHighViolations: false, filterMediumViolations: true, filterLowViolations: false })
                          history.push(`/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testsession.id}/violations`)
                        }}
                      >
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Medium Violations</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderOrange}>
                            <Text mlg bold>{isDone && chartData ? chartData.mediumSeverity : '?'}</Text>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight noPadding grid widthPercent>
                    <Card noBorder noMarginBottom  noMarginTop hoverlight noBorderRadius>
                      <CardBody
                        LargerPadding
                        cursorPointer
                        tabIndex={0}
                        data-unique="btnMisuseCheckerSessionChartDataLow"
                        onClick={() => {
                          this.setState({ filterHighViolations: false, filterMediumViolations: false, filterLowViolations: true })
                          history.push(`/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testsession.id}/violations`)
                        }}
                      >
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Low Violations</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderLightGreen}>
                            <Text mlg bold>{isDone && chartData ? chartData.lowSeverity : '?'}</Text>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem lg borderRight>
                    <Card noBorder noMarginBottom  noMarginTop>
                      <CardBody LargePadding>
                        <GridContainer fullWidth>
                          <GridItem lg={12} middle noPaddingLeft><Text regular>Correct Statements</Text></GridItem>
                          <GridItem lg={12} middle className={classes.textLeftBorderGreen}>
                            <Text mlg bold>{isDone && chartData ? chartData.correctStatements : '?'}</Text>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </React.Fragment>
          )
        }}
      </TestSessionProgress>
      <GridItem lg={12}>
        <ObjectChips dataUniquePrefix="btnTestSession"
                     misuseCheckerProject={testsession.misuseCheckerSession && testsession.misuseCheckerSession.project}
                     chatbot={testsession.chatbot} testSets={testsession.testSets} deviceSets={testsession.deviceSets}
                     llmModel={testsession.misuseCheckerSession && testsession.misuseCheckerSession.llmModelId ? {llmModelId: testsession.misuseCheckerSession.llmModelId} : null} />
      </GridItem>
      <GridItem xs={12}>
        <TestSessionProgress
          key={this.state.testSessionProgressKey}
          query={TESTSESSION_PROGRESS_MISUSECHECKER_QUERY}
          subscription={TESTSESSIONPROGRESS_MISUSECHECKER_SUBSCRIPTION}
          testSession={testsession}>
          {({ testSessionProgress }) => {
            const isDone = testSessionProgress && (testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED')
            const chartData = testSessionProgress?.misuseCheckerSession?.chartData
              ? JSON.parse(testSessionProgress.misuseCheckerSession.chartData)
              : null
            const correctSeverityDistractionTopics = []
            const highSeverityDistractionTopics = []
            const mediumSeverityDistractionTopics = []
            const lowSeverityDistractionTopics = []
            const allDistractionTopics = []
            if(chartData && chartData.distractionTopicsDetails && chartData.distractionTopicsDetails) {
              for(const dt in chartData.distractionTopicsDetails) {
                const dtDetails = chartData.distractionTopicsDetails[dt]
                dtDetails.distractionTopic = dt
                allDistractionTopics.push(dtDetails)
                if(dtDetails.highSeverity > 0) {
                  highSeverityDistractionTopics.push(dtDetails)
                }
                if(dtDetails.mediumSeverity > 0) {
                  mediumSeverityDistractionTopics.push(dtDetails)
                }
                if(dtDetails.lowSeverity > 0) {
                  lowSeverityDistractionTopics.push(dtDetails)
                }
                if(dtDetails.correctStatements > 0) {
                  correctSeverityDistractionTopics.push(dtDetails)
                }
              }
            }

            const dataPieHigh = chartData
            ? [
                { name: 'High Severity', value: chartData.highSeverity || 0 },
                { name: 'Others', value: (chartData.totalStatements || 0) - (chartData.highSeverity || 0) }
              ]
            : [
                { name: 'High Severity', value: 0 },
                { name: 'Others', value: 0 }
              ]

            const COLORSHIGH = ['#E70B04', '#eee']

            const dataPieMedium = chartData
            ? [
                { name: 'Medium Severity', value: chartData.mediumSeverity || 0 },
                { name: 'Others', value: (chartData.totalStatements || 0) - (chartData.mediumSeverity || 0) }
              ]
            : [
                { name: 'Medium Severity', value: 0 },
                { name: 'Others', value: 0 }
              ]

            const COLORSMEDIUM = ['#FF9800', '#eee']

            const dataPieLow = chartData
            ? [
                { name: 'Low Severity', value: chartData.lowSeverity || 0 },
                { name: 'Others', value: (chartData.totalStatements || 0) - (chartData.lowSeverity || 0) }
              ]
            : [
                { name: 'Low Severity', value: 0 },
                { name: 'Others', value: 0 }
              ]

            const COLORSLOW = ['#9FD400', '#eee']

            const dataPieCorrect = chartData
            ? [
                { name: 'Correct Severity', value: chartData.correctStatements || 0 },
                { name: 'Others', value: (chartData.totalStatements || 0) - (chartData.correctStatements || 0) }
              ]
            : [
                { name: 'Correct Severity', value: 0 },
                { name: 'Others', value: 0 }
              ]

            const COLORSCORRECT = ['#008A00', '#eee']

            const onPieClick = (data, index) => {
              this.setState({ filterHighViolations: data.setfilterHighViolations, filterMediumViolations: data.filterMediumViolations, filterLowViolations: data.filterLowViolations })
              history.push(`/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testsession.id}/violations`)
            }

            return (
              <React.Fragment>
                {isDone && chartData && chartData.highSeverity > 0 && <Card noMarginTop>
                  <CardBody>
                    <GridContainer>
                      <GridItem lg={4}>
                        <Text bold lg>High Severity</Text>
                        <GridContainer autoHeight fullWidth >
                          <GridItem lg={12} positionRelative marginAuto>
                            <ResponsiveContainer width="100%" height={250}>
                              <PieChart onMouseEnter={this.onPieEnter}>
                                <Pie
                                  data={dataPieHigh}
                                  innerRadius={60}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  paddingAngle={2}
                                  dataKey="value"
                                  startAngle={450}
                                  endAngle={0}
                                  onClick={() => onPieClick({ setfilterHighViolations: true, filterMediumViolations: false, filterLowViolations: false })}
                                  cursor="pointer"
                                >
                                  {dataPieHigh.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORSHIGH[index % COLORSHIGH.length]} />
                                  ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                            <div className={classes.percentPosition} onClick={onPieClick}>
                              <Text bold mlg>{(((isDone && chartData ? chartData.highSeverity : '?') / (isDone && chartData ? chartData.totalStatements : '?')) * 100).toFixed(0)}%</Text>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem lg={8}>
                        <Text inline>Out of </Text>
                        <Text bold inline>{(isDone && chartData ? chartData.totalStatements : '?')}</Text>
                        <Text inline> bot statements, </Text>
                        <Text bold inline>{(isDone && chartData ? chartData.highSeverity : '?')}</Text>
                        <Text inline> have been identified as high-severity violations related to the topics of </Text>
                        <Text bold inline>
                          {highSeverityDistractionTopics.length > 1
                            ? highSeverityDistractionTopics
                              .map(dt => dt.distractionTopic)
                              .slice(0, -1)
                              .join(', ') + ' and ' + highSeverityDistractionTopics[highSeverityDistractionTopics.length - 1].distractionTopic
                            : highSeverityDistractionTopics.map(dt => dt.distractionTopic)}
                        </Text>
                        <GridContainer autoHeight fullWidth paddingBottom>
                          <GridItem lg={12} positionRelative marginAuto>
                            <List component="div" disablePadding>
                              {highSeverityDistractionTopics.slice(0, 3).map(dt => {
                                const dataBarHigh = [
                                  { name: 'High Severity', value: dt.highSeverity || 0 }
                                ]
                                return <><ListItem key={dt.distractionTopic} small onClick={onPieClick} className={classes.cursorPointer}>
                                  <ListItemText style={{ display: 'block' }} primary={<Text bold>{dt.distractionTopic}</Text>} secondary={<Text>{dt.highSeverity} violation{dt.highSeverity > 1 ? 's' : ''} detected</Text>} />
                                </ListItem>
                                  <div style={{ display: 'block', marginBottom: 15 }}>
                                    <ResponsiveContainer width="100%" height={10}>
                                      <BarChart
                                        data={dataBarHigh}
                                        layout="vertical"
                                        margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
                                        onClick={() => onPieClick({ setfilterHighViolations: true, filterMediumViolations: false, filterLowViolations: false })}
                                        cursor="pointer"
                                      >
                                        <XAxis type="number" hide domain={[0, dt.totalStatements || 1]} />
                                        <YAxis type="category" dataKey="name" hide />
                                        <Tooltip />
                                        <Bar
                                          dataKey="value"
                                          fill="#E70B04"
                                          barSize={10}
                                          background={{ fill: '#E70B041A', radius: 20 }}
                                          radius={20}
                                        />
                                      </BarChart>
                                    </ResponsiveContainer>
                                  </div>
                                </>
                              })}
                            </List>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    {highSeverityDistractionTopics.length > 3 && <GridContainer paddingTop>
                      <GridItem lg={12} center>
                        <NavLink  to={`/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testsession.id}/violations`} className={classes.link}>
                          <Text>Show more</Text>
                        </NavLink>
                      </GridItem>
                    </GridContainer>}
                  </CardBody>
                </Card>}
                {isDone && chartData && chartData.mediumSeverity > 0 && <Card noMarginTop>
                  <CardBody>
                    <GridContainer>
                      <GridItem lg={4}>
                        <Text bold lg>Medium Severity</Text>
                        <GridContainer autoHeight fullWidth >
                          <GridItem lg={12} positionRelative marginAuto>
                            <ResponsiveContainer width="100%" height={250}>
                              <PieChart onMouseEnter={this.onPieEnter}>
                                <Pie
                                  data={dataPieMedium}
                                  innerRadius={60}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  paddingAngle={2}
                                  dataKey="value"
                                  startAngle={450}
                                  endAngle={0}
                                  onClick={() => onPieClick({ setfilterHighViolations: false, filterMediumViolations: true, filterLowViolations: false })}
                                  cursor="pointer"
                                >
                                  {dataPieMedium.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORSMEDIUM[index % COLORSMEDIUM.length]} />
                                  ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                            <div className={classes.percentPosition} onClick={onPieClick}>
                              <Text bold mlg>{(((isDone && chartData ? chartData.mediumSeverity : '?') / (isDone && chartData ? chartData.totalStatements : '?')) * 100).toFixed(0)}%</Text>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem lg={8}>
                        <Text inline>Out of </Text>
                        <Text bold inline>{(isDone && chartData ? chartData.totalStatements : '?')}</Text>
                        <Text inline> bot statements, </Text>
                        <Text bold inline>{(isDone && chartData ? chartData.mediumSeverity : '?')}</Text>
                        <Text inline> have been identified as medium-severity violations related to the topics of </Text>
                        <Text bold inline>
                          {mediumSeverityDistractionTopics.length > 1
                            ? mediumSeverityDistractionTopics
                              .map(dt => dt.distractionTopic)
                              .slice(0, -1)
                              .join(', ') + ' and ' + mediumSeverityDistractionTopics[mediumSeverityDistractionTopics.length - 1].distractionTopic
                            : mediumSeverityDistractionTopics.map(dt => dt.distractionTopic)}
                        </Text>
                        <GridContainer autoHeight fullWidth paddingBottom>
                          <GridItem lg={12} positionRelative marginAuto>
                            <List component="div" disablePadding>
                              {mediumSeverityDistractionTopics.slice(0, 6).map(dt => {
                                const dataBarMedium = [
                                  { name: 'Medium Severity', value: dt.mediumSeverity || 0 }
                                ]
                                return <><ListItem key={dt.distractionTopic} small onClick={onPieClick} className={classes.cursorPointer}>
                                  <ListItemText primary={<Text bold>{dt.distractionTopic}</Text>} secondary={<Text>{dt.mediumSeverity} violation{dt.mediumSeverity > 1 ? 's' : ''} detected</Text>} />
                                </ListItem>
                                <div style={{ display: 'block', marginBottom: 15 }}>
                                <ResponsiveContainer width="100%" height={10}>
                                  <BarChart
                                    data={dataBarMedium}
                                    layout="vertical"
                                    margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
                                    onClick={() => onPieClick({ setfilterHighViolations: false, filterMediumViolations: true, filterLowViolations: false })}
                                    cursor="pointer"
                                  >
                                    <XAxis type="number" hide domain={[0, dt.totalStatements || 1]} />
                                    <YAxis type="category" dataKey="name" hide />
                                    <Tooltip />
                                    <Bar
                                      dataKey="value"
                                      fill="#FF9800"
                                      barSize={10}
                                      background={{ fill: '#FF98001A', radius: 20 }}
                                      radius={20}
                                    />
                                  </BarChart>
                                </ResponsiveContainer>
                              </div>
                            </>
                              })}
                            </List>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    {mediumSeverityDistractionTopics.length > 3 && <GridContainer paddingTop>
                      <GridItem lg={12} center>
                        <NavLink  to={`/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testsession.id}/violations`} className={classes.link}>
                          <Text>Show more</Text>
                        </NavLink>
                      </GridItem>
                    </GridContainer>}
                  </CardBody>
                </Card>}
                {isDone && chartData && chartData.lowSeverity > 0 && <Card noMarginTop>
                  <CardBody>
                    <GridContainer>
                      <GridItem lg={4}>
                        <Text bold lg>Low Severity</Text>
                        <GridContainer autoHeight fullWidth >
                          <GridItem lg={12} positionRelative marginAuto>
                            <ResponsiveContainer width="100%" height={250}>
                              <PieChart onMouseEnter={this.onPieEnter}>
                                <Pie
                                  data={dataPieLow}
                                  innerRadius={60}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  paddingAngle={2}
                                  dataKey="value"
                                  startAngle={450}
                                  endAngle={0}
                                  onClick={() => onPieClick({ setfilterHighViolations: false, filterMediumViolations: false, filterLowViolations: true })}
                                  cursor="pointer"
                                >
                                  {dataPieLow.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORSLOW[index % COLORSLOW.length]} />
                                  ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                            <div className={classes.percentPosition} onClick={onPieClick}>
                              <Text bold mlg>{(((isDone && chartData ? chartData.lowSeverity : '?') / (isDone && chartData ? chartData.totalStatements : '?')) * 100).toFixed(0)}%</Text>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem lg={8}>
                        <Text inline>Out of </Text>
                        <Text bold inline>{(isDone && chartData ? chartData.totalStatements : '?')}</Text>
                        <Text inline> bot statements, </Text>
                        <Text bold inline>{(isDone && chartData ? chartData.lowSeverity : '?')}</Text>
                        <Text inline> have been identified as low-severity violations related to the topics of </Text>
                        <Text bold inline>
                          {lowSeverityDistractionTopics.length > 1
                            ? lowSeverityDistractionTopics
                              .map(dt => dt.distractionTopic)
                              .slice(0, -1)
                              .join(', ') + ' and ' + lowSeverityDistractionTopics[lowSeverityDistractionTopics.length - 1].distractionTopic
                            : lowSeverityDistractionTopics.map(dt => dt.distractionTopic)}
                        </Text>
                        <GridContainer autoHeight fullWidth paddingBottom>
                          <GridItem lg={12} positionRelative marginAuto>
                            <List component="div" disablePadding>
                              {lowSeverityDistractionTopics.slice(0, 6).map(dt => {
                                const dataBarLow = [
                                  { name: 'Low Severity', value: dt.lowSeverity || 0 }
                                ]
                                return <><ListItem key={dt.distractionTopic} small onClick={onPieClick} className={classes.cursorPointer}>
                                  <ListItemText primary={<Text bold>{dt.distractionTopic}</Text>} secondary={<Text>{dt.lowSeverity} violation{dt.lowSeverity > 1 ? 's' : ''} detected</Text>} />
                                </ListItem>
                                <div style={{ display: 'block', marginBottom: 15 }}>
                                  <ResponsiveContainer width="100%" height={10}>
                                    <BarChart
                                      data={dataBarLow}
                                      layout="vertical"
                                      margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
                                      onClick={() => onPieClick({ setfilterHighViolations: false, filterMediumViolations: false, filterLowViolations: true })}
                                      cursor="pointer"
                                    >
                                      <XAxis type="number" hide domain={[0, dt.totalStatements || 1]} />
                                      <YAxis type="category" dataKey="name" hide />
                                      <Tooltip />
                                      <Bar
                                        dataKey="value"
                                        fill="#9FD400"
                                        barSize={10}
                                        background={{ fill: '#9FD4001A', radius: 20 }}
                                        radius={20}
                                      />
                                    </BarChart>
                                  </ResponsiveContainer>
                                </div></>
                              })}
                            </List>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    {lowSeverityDistractionTopics.length > 3 && <GridContainer paddingTop>
                      <GridItem lg={12} center>
                        <NavLink  to={`/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testsession.id}/violations`} className={classes.link}>
                          <Text>Show more</Text>
                        </NavLink>
                      </GridItem>
                    </GridContainer>}
                  </CardBody>
                </Card>}
                {isDone && chartData && chartData.lowSeverity === 0 && chartData.mediumSeverity === 0 && chartData.highSeverity === 0 && <Card noMarginTop>
                <CardBody>
                    <GridContainer>
                      <GridItem lg={4}>
                        <Text bold lg>Correct statement</Text>
                        <GridContainer autoHeight fullWidth >
                          <GridItem lg={12} positionRelative marginAuto>
                            <ResponsiveContainer width="100%" height={250}>
                              <PieChart onMouseEnter={this.onPieEnter}>
                                <Pie
                                  data={dataPieCorrect}
                                  innerRadius={60}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  paddingAngle={2}
                                  dataKey="value"
                                  startAngle={450}
                                  endAngle={0}
                                  onClick={() => onPieClick({ setfilterHighViolations: false, filterMediumViolations: false, filterLowViolations: false })}
                                  cursor="pointer"
                                >
                                  {dataPieCorrect.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORSCORRECT[index % COLORSCORRECT.length]} />
                                  ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                            <div className={classes.percentPosition} onClick={onPieClick}>
                              <Text bold mlg>{(((isDone && chartData ? chartData.correctStatements : '?') / (isDone && chartData ? chartData.totalStatements : '?')) * 100).toFixed(0)}%</Text>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem lg={8}>
                        <Text inline>Out of </Text>
                        <Text bold inline>{(isDone && chartData ? chartData.totalStatements : '?')}</Text>
                        <Text inline> bot statements, </Text>
                        <Text inline> no violations have been identified related to the topics of </Text>
                        <Text bold inline>
                          {allDistractionTopics.length > 1
                            ? allDistractionTopics
                              .map(dt => dt.distractionTopic)
                              .slice(0, -1)
                              .join(', ') + ' and ' + allDistractionTopics[allDistractionTopics.length - 1].distractionTopic
                            : allDistractionTopics.map(dt => dt.distractionTopic)}
                        </Text>
                        <GridContainer autoHeight fullWidth paddingBottom>
                          <GridItem lg={12} positionRelative marginAuto>
                            <List component="div" disablePadding>
                              {allDistractionTopics.slice(0, 6).map(dt => {
                                const dataBarCorrect = [
                                  { name: 'Correct Severity', value: dt.correctStatements || 0 }
                                ]
                                return <><ListItem key={dt.distractionTopic} small onClick={onPieClick} className={classes.cursorPointer}>
                                  <ListItemText primary={<Text bold>{dt.distractionTopic}</Text>} secondary={<Text>{dt.correctStatements} correct statement{dt.correctStatements > 1 ? 's' : ''} detected</Text>} />
                                </ListItem>
                                <div style={{ display: 'block', marginBottom: 15 }}>
                                <ResponsiveContainer width="100%" height={10}>
                                  <BarChart
                                    data={dataBarCorrect}
                                    layout="vertical"
                                    margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
                                    onClick={() => onPieClick({ setfilterHighViolations: false, filterMediumViolations: false, filterLowViolations: false })}
                                    cursor="pointer"
                                  >
                                    <XAxis type="number" hide domain={[0, dt.totalStatements || 1]} />
                                    <YAxis type="category" dataKey="name" hide />
                                    <Tooltip />
                                    <Bar
                                      dataKey="value"
                                      fill="#008A00"
                                      barSize={10}
                                      background={{ fill: '#008A001A', radius: 20 }}
                                      radius={20}
                                    />
                                  </BarChart>
                                </ResponsiveContainer>
                              </div></>
                              })}
                            </List>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                    {correctSeverityDistractionTopics.length > 3 && <GridContainer paddingTop>
                      <GridItem lg={12} center>
                        <NavLink  to={`/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testsession.id}/violations`} className={classes.link}>
                          <Text>Show more</Text>
                        </NavLink>
                      </GridItem>
                    </GridContainer>}
                  </CardBody>
                </Card>}
              </React.Fragment>
            )
          }}
        </TestSessionProgress>
      </GridItem>
    </GridContainer>)
  }

  handleFilterChange = (filterName) => (event) => {
    this.setState({ [filterName]: event.target.checked })
  }

  countCheckedCheckboxes() {
    const { filterHighViolations, filterMediumViolations, filterLowViolations} = this.state
    const checkboxes = [filterHighViolations, filterMediumViolations, filterLowViolations]
    return checkboxes.filter(checked => checked).length
  }

  renderViolationsTab(testsession, loading) {
    const { classes } = this.props
    const { filterHighViolations, filterMediumViolations, filterLowViolations } = this.state
    const checkedCount = this.countCheckedCheckboxes()

    if (loading) return (<LoadingIndicator />)
    return (
      <GridContainer>
        {this.renderRepeatButtons(testsession)}
        <GridItem xs={12}>
          <Card noMarginTop>
            <CardBody>
              <Text bold lg>Results</Text>
              <TestSessionProgress
                key={this.state.testSessionProgressKey}
                query={TESTSESSION_PROGRESS_MISUSECHECKER_QUERY}
                subscription={TESTSESSIONPROGRESS_MISUSECHECKER_SUBSCRIPTION}
                testSession={testsession}>
                {({ testSessionProgress, testSessionProgressLoading, testSessionProgressErr }) => {
                  const results = []
                  if(testSessionProgress.misuseCheckerSession && testSessionProgress.misuseCheckerSession.results) {
                    for (const result of testSessionProgress.misuseCheckerSession.results) {
                      if (result.violations) {
                        for (const violation of result.violations) {
                          results.push({
                            resultId: result.id,
                            distractionTopic: result.distractionTopic,
                            category: violation.category,
                            reason: violation.reason,
                            statement: violation.statement,
                            severity: violation.severity
                          })
                        }
                      }
                    }
                  }

                  const filteredQueries = results.filter(result => {
                    if(!filterHighViolations && !filterMediumViolations && !filterLowViolations) {
                      return true
                    }
                    return (filterHighViolations && result.severity === 'HIGH') || (filterMediumViolations && result.severity === 'MEDIUM') || (filterLowViolations && result.severity === 'LOW')
                  })

                  return <Table
                    className={classes.resultsTable}
                    tableHeaderColor="primary"
                    disableFilter
                    customActionsRight={
                      <>
                        <GridContainer >
                          <GridItem md={12} right>
                            <Button
                              className={classes.testStatusButtonPosition}
                              onClick={() => this.setState({
                                filtersTestResultsExpanded: !this.state.filtersTestResultsExpanded,
                                filtersTestTypeExpanded: false
                              })}

                              label="FactCheck Results"
                              dropdownSelectAvtive={this.state.filtersTestResultsExpanded}
                              dropdownSelect
                              data-unique="btnFilterViewFactCheckResultsExpanded"
                            >
                              Severity Filter {checkedCount > 0 ? <span className={classes.valuebuble}>{checkedCount}</span> : <span className={classes.valuebubleempty}>0</span>}
                              <ShowIcon icon={this.state.filtersTestResultsExpanded ? 'caret-up' : 'caret-down'} />
                            </Button>
                          </GridItem>
                          <GridItem md={12} right >
                            <GridContainer paddingTop>
                              {this.state.filtersTestResultsExpanded && <><GridItem middle>
                                <CustomCheckbox data-unique="chkFilterViewCorrect"
                                  formControlProps={{ className: classes.statustypefilter }}
                                  useCheckbox
                                  input={{
                                    onChange: this.handleFilterChange('filterHighViolations'),
                                    checked: filterHighViolations
                                  }}
                                  label="HIGH"
                                />
                                <CustomCheckbox data-unique="chkFilterViewIncorrect"
                                  formControlProps={{ className: classes.statustypefilter }}
                                  useCheckbox
                                  input={{
                                    onChange: this.handleFilterChange('filterMediumViolations'),
                                    checked: filterMediumViolations
                                  }}
                                  label="MEDIUM"
                                />
                                <CustomCheckbox data-unique="chkFilterViewIncorrect"
                                  formControlProps={{ className: classes.statustypefilter }}
                                  useCheckbox
                                  input={{
                                    onChange: this.handleFilterChange('filterLowViolations'),
                                    checked: filterLowViolations
                                  }}
                                  label="LOW"
                                />
                              </GridItem>
                                </>}
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </>
                    }

                    tableHead={[
                      { name: 'Violation', width: 'large'},
                      { name: ' ', width: 'smallsecondary'},
                      { name: 'Distraction Topic', width: 'smallsecondary'},
                      { name: 'Severity', width: 'smallsecondary'},
                      ''
                    ]}
                    pageErr={testSessionProgressErr}
                    name={`TestSession_${testsession.id}_MisuseChecker`}

                    tableData={({ orderByField, orderByOrder }) => _.orderBy(filteredQueries, [(i) => i.severity || 'HIGH' ? 0 : i.severity === 'MEDIUM' ? 1 : 2, 'result'], [orderByOrder || 'asc']).map(result => {
                      return [
                        () => <Text>{result.statement}</Text>,
                        () => <Tooltip title={`Reason: ${result.reason}`}><ShowIcon icon="question-circle" /></Tooltip>,
                        () => <Text bold >{result.distractionTopic}</Text>,
                        () => <Text
                          lowSeverityBg={result.severity === 'LOW'}
                          warningBg={result.severity === 'MEDIUM'}
                          dangerBg={result.severity === 'HIGH'}
                          icon={result.severity === 'HIGH' ? 'exclamation' : '' }
                        >
                          {result.severity}
                        </Text>,

                        () => <LinkButton onClick={() => this.setState({ showConversationDialog: true, resultId: result.resultId })}>
                          <Text noWrap>Conversation View</Text>
                        </LinkButton>,
                      ]
                    })}
                  />
                }}
              </TestSessionProgress>
              <ConfirmationDialog
                open={!!this.state.showConversationDialog}
                onOk={() => this.setState({ showConversationDialog: false, resultId: null })}
                title="Misuse Results"
              >
                <GridContainer>
                  {this.state.resultId && <Query
                    query={MISUSECHECKER_SESSION_RESULT_QUERY}
                    variables={{ id: this.state.resultId }}
                  >
                    {(queryResult) => {
                      if(queryResult.loading) return <LoadingIndicator />
                      if(queryResult.error) return <ErrorFormat err={queryResult.error.message} />
                      const rd = queryResult.data.misusecheckersessionresult || {}
                      for(const t of rd.transcript) {
                        if(t.sender === 'bot') {
                          const actual = _.isObject(t.actual) ? t.actual : JSON.parse(t.actual)
                          const violation = rd.violations.find(v => _.trim(v.statement).toLowerCase() === _.trim(actual.messageText).toLowerCase())
                          if(violation) {
                            t.misuseInfo = {
                              misuseCategory: violation.category,
                              misuseSeverity: violation.severity,
                              misuseReason: violation.reason
                            }
                          } else {
                            t.misuseInfo = {
                              misuseSeverity: 'NONE',
                            }
                          }
                        }
                      }
                      return <GridContainer>
                        <GridItem xs={12}>
                          <Transcript
                            steps={rd.transcript && _.orderBy(rd.transcript, 'step')}
                            key={this.state.resultId + '_steps'}
                            disableTooltip={true}
                          />
                        </GridItem>
                      </GridContainer>
                    }
                    }
                  </Query>}
                </GridContainer>
              </ConfirmationDialog>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>)
  }

  renderConversations(testsession, loading) {
    const { classes } = this.props
    const misuseCheckerTestProjectId = this.props.match.params.projectId
    const { filter } = this.state
    if (loading) return (<LoadingIndicator />)

    const orderBy = 'distractionTopic_ASC'
    const where = { AND: [] }
    const whereOr = { OR: [] }

    if (filter.showHighViolations) {
      whereOr.OR.push({
        violations_some: {
          severity: 'HIGH'
        }
      })
    }
    if (filter.showMediumViolations) {
      whereOr.OR.push({
        violations_some: {
          severity: 'MEDIUM'
        }
      })
    }
    if (filter.showLowViolations) {
      whereOr.OR.push({
        violations_some: {
          severity: 'LOW'
        }
      })
    }
    if (filter.showSuccess) {
      whereOr.OR.push({
        violations_none: {}
      })
    }
    if(whereOr.OR.length > 0) {
      where.AND.push(whereOr)
    }
    if (filter.filterText) {
      where.AND.push({
        distractionTopic_contains: filter.filterText
      })
    }

    const currentPage = filter.page || 0
    const skip = currentPage * filter.rowsPerPage
    const first = filter.rowsPerPage

    return (<TestSessionProgress
      query={MISUSECHECKER_SESSION_RESULTCOUNT_QUERY}
      querySelector={data => data.misusecheckersessionresultcount}
      subscription={MISUSECHECKER_SESSION_RESULTCOUNT_SUBSCRIPTION}
      subscriptionSelector={data => data.misuseCheckerSessionProgressResultCount}
      variables={{ where }}
      testSession={testsession}
      misuse={true}
    >{({testSessionProgress: misuseCheckerSessionResultCount}) => {
      return <TestSessionProgress
        query={MISUSECHECKER_SESSION_RESULTS_QUERY}
        querySelector={data => data.misusecheckersessionresults}
        subscription={MISUSECHECKER_SESSION_RESULTS_SUBSCRIPTION}
        subscriptionSelector={data => data.misuseCheckerSessionProgressResults}
        variables={{
          where, orderBy, skip, first
        }}
        testSession={testsession}
      >{({ testSessionProgress: misuseCheckerSessionResults, testSessionProgressLoading }) => {
          const resultCount = _.isNumber(misuseCheckerSessionResultCount) ? misuseCheckerSessionResultCount : 0
          const lastPage = Math.max(0, Math.ceil(resultCount / filter.rowsPerPage) - 1)
          const hasMore = resultCount > (currentPage + 1) * filter.rowsPerPage

          return (
            <GridContainer>
              {this.renderRepeatButtons(testsession)}
              <GridItem xs={12}>
                <Card noMargin>
                  <CardBody noPaddingTop noPaddingBottom>
                    <GridContainer>
                      <GridItem md={12} lg={10} borderRight className={classes.col1}>
                        <GridContainer>
                          <GridItem md={12}>
                            {testSessionProgressLoading && <LoadingIndicator large />}
                            {misuseCheckerSessionResults && misuseCheckerSessionResults.length > 0 &&
                              <List component="nav" key={`testcases_${currentPage}`}>
                                {misuseCheckerSessionResults.map(r => {
                                  r.highViolations = r.violations.filter(v => v.severity === 'HIGH').length
                                  r.mediumViolations = r.violations.filter(v => v.severity === 'MEDIUM').length
                                  r.lowViolations = r.violations.filter(v => v.severity === 'LOW').length
                                  return (
                                    <React.Fragment key={r.id}>
                                      <ExpansionPanel>
                                        <ExpansionPanelSummary>
                                          <ListItem small>
                                            <ShowIcon custom icon="convo" />
                                            <ListItemText
                                              primary={<>
                                                <Text inline>Distraction topics:</Text> <Text bold inline>{r.distractionTopic}</Text>
                                              </>}
                                              secondary={
                                                <Text inline>
                                                  <DateFormat seconds>{r.createdAt}</DateFormat>
                                                </Text>
                                              }>
                                            </ListItemText>
                                            <React.Fragment>
                                              <GridContainer>
                                                <GridItem md={12} lg={4} middle flexAuto >
                                                  <GridContainer fullWidth>
                                                    <GridItem xs={12} middle noPaddingLeft><Text regular >High</Text></GridItem>
                                                    <GridItem xs={12} middle className={classes.textLeftBorderRed}><Text lg bold>{r.highViolations}</Text></GridItem>
                                                  </GridContainer>
                                                </GridItem>
                                                <GridItem md={12} lg={4} middle flexAuto >
                                                  <GridContainer fullWidth>
                                                    <GridItem xs={12} middle noPaddingLeft><Text regular >Medium</Text></GridItem>
                                                    <GridItem xs={12} middle className={classes.textLeftBorderOrange}><Text lg bold>{r.mediumViolations}</Text></GridItem>
                                                  </GridContainer>
                                                </GridItem>
                                                <GridItem md={12} lg={4} middle flexAuto >
                                                  <GridContainer fullWidth>
                                                    <GridItem xs={12} middle noPaddingLeft><Text regular >Low</Text></GridItem>
                                                    <GridItem xs={12} middle className={classes.textLeftBorderYellow}><Text lg bold>{r.lowViolations}</Text></GridItem>
                                                  </GridContainer>
                                                </GridItem>
                                              </GridContainer>
                                            </React.Fragment>
                                          </ListItem>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                          <Query
                                            query={MISUSECHECKER_SESSION_RESULT_QUERY}
                                            variables={{ id: r.id }}
                                            fetchPolicy={'network-only'}
                                          >
                                            {({ loading, error, data }) => {
                                              if (error) {
                                                return <ErrorFormat err={error} />
                                              }
                                              if (loading) {
                                                return <LoadingIndicator large />
                                              }
                                              const rd = (data && data.misusecheckersessionresult) || {}
                                              for(const t of rd.transcript) {
                                                if(t.sender === 'bot') {
                                                  const actual = _.isObject(t.actual) ? t.actual : JSON.parse(t.actual)
                                                  const violation = rd.violations.find(v => _.trim(v.statement).toLowerCase() === _.trim(actual.messageText).toLowerCase())
                                                  if(violation) {
                                                    t.misuseInfo = {
                                                      misuseCategory: violation.category,
                                                      misuseSeverity: violation.severity,
                                                      misuseReason: violation.reason
                                                    }
                                                  } else {
                                                    t.misuseInfo = {
                                                      misuseSeverity: 'NONE',
                                                    }
                                                  }
                                                }
                                              }
                                              return (
                                                <GridContainer noMargin fullWidth>
                                                  <GridItem md={12}>
                                                    <GridContainer fullWidth noMargin>
                                                      {rd.job && (
                                                        <React.Fragment>
                                                          <GridItem md={5} lg={4} grid>
                                                            <NavLink
                                                              tabIndex={0}
                                                              className={classes.cardLinkConnections}
                                                              to={`/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testsession.id}/jobs`}
                                                              data-unique={`btnTestSessionJobs_${rd.job.id}`}
                                                              onClick={() => {
                                                                this.setState({ jobOpenId: rd.job.id })
                                                              }}
                                                            >
                                                              <Card hoverdark noMargin borderSecondary>
                                                                <CardBody noPadding>
                                                                  <GridContainer autoHeight>
                                                                    <GridItem xs={12} middle>
                                                                      <ListItem compactPadding small>
                                                                        <ListItemIcon>
                                                                          <Chip justIcon cursorPointer iconcolordarkblue variant="info" icon={<ShowIcon icon="fa-gears" />} />
                                                                        </ListItemIcon>
                                                                        <ListItemText>
                                                                          <div><Text inline subheader>Misuse Checker Session Job</Text></div>
                                                                          <Text inline wordBreak>{rd.job.createdAt}</Text>
                                                                        </ListItemText>
                                                                        <ListItemIcon>
                                                                          <Text primary>
                                                                            <ShowIcon moveleft icon="angle-right" />
                                                                          </Text>
                                                                        </ListItemIcon>
                                                                      </ListItem>
                                                                    </GridItem>
                                                                  </GridContainer>
                                                                </CardBody>
                                                              </Card>
                                                            </NavLink>
                                                          </GridItem>

                                                          &nbsp;

                                                        </React.Fragment>
                                                      )}
                                                    </GridContainer>
                                                    <Divider ></Divider>
                                                  </GridItem>
                                                  <GridItem md={12} >
                                                    <List component="div" disablePadding>
                                                      <Transcript
                                                        steps={rd.transcript && _.orderBy(rd.transcript, 'step')}
                                                        key={rd.id + '_steps'}
                                                        disableTooltip={true}
                                                      />
                                                    </List>
                                                  </GridItem>
                                                </GridContainer>
                                              )
                                            }}
                                          </Query>
                                        </ExpansionPanelDetails>
                                      </ExpansionPanel>
                                    </React.Fragment>
                                  )
                                })}
                              </List>
                            }
                          </GridItem>
                          <GridItem md={12} right>
                            <TablePagination
                              component="div"
                              count={resultCount}
                              labelDisplayedRows={
                                ({ from, to, count }) => `${from}-${to} of ${count}`
                              }
                              rowsPerPage={filter.rowsPerPage}
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              page={currentPage}
                              onChangePage={() => ({})}
                              onChangeRowsPerPage={(event) => this.setState({ filter: { ...filter, rowsPerPage: event.target.value } })}
                              ActionsComponent={() => (
                                <React.Fragment>
                                  <Button
                                    justIcon round
                                    onClick={() => this.setState({ filter: { ...filter, page: 0 } })}
                                    disabled={currentPage === 0}
                                    aria-label="First Page"
                                  >
                                    <FirstPageIcon />
                                  </Button>
                                  <Button
                                    justIcon round
                                    onClick={() => this.setState({ filter: { ...filter, page: currentPage - 1 } })}
                                    disabled={currentPage === 0}
                                    aria-label="Previous Page"
                                  >
                                    <KeyboardArrowLeft />
                                  </Button>
                                  <Button
                                    justIcon round
                                    onClick={() => this.setState({ filter: { ...filter, page: currentPage + 1 } })}
                                    disabled={!hasMore}
                                    aria-label="Next Page"
                                  >
                                    <KeyboardArrowRight />
                                  </Button>
                                  <Button
                                    justIcon round
                                    onClick={() => this.setState({ filter: { ...filter, page: lastPage } })}
                                    disabled={!hasMore}
                                    aria-label="Last Page"
                                  >
                                    <LastPageIcon />
                                  </Button>
                                </React.Fragment>
                              )}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem md={12} lg={2} className={classes.col2}>
                        <GridContainer>
                          <GridItem md={8} lg={12}>
                            <CustomCheckbox
                              input={{
                                onChange: e =>
                                  this.setState({
                                    filter: { ...filter, showHighViolations: e.target.checked },
                                  }),
                                checked: filter.showHighViolations,
                              }}
                              label={<><Text icon="square" danger contentCenter inline>&nbsp;</Text> <Text inline bold>Show High Violations</Text></>}
                              data-unique="chkMisuseSessionShowHigh"
                            />
                            <CustomCheckbox
                              dense
                              input={{
                                onChange: e =>
                                  this.setState({
                                    filter: { ...filter, showMediumViolations: e.target.checked },
                                  }),
                                checked: filter.showMediumViolations
                              }}
                              label={<><Text icon="square" warning contentCenter inline>&nbsp;</Text> <Text inline bold>Show Medium Violations</Text></>}
                              data-unique="chkMisuseSessionShowMedium"
                            />
                            <CustomCheckbox
                              dense
                              input={{
                                onChange: e =>
                                  this.setState({
                                    filter: { ...filter, showLowViolations: e.target.checked },
                                  }),
                                checked: filter.showLowViolations
                              }}
                              label={<><Text icon="square" lowSeverity contentCenter inline>&nbsp;</Text> <Text inline bold>Show Low Violations</Text></>}
                              data-unique="chkMisuseSessionShowLow"
                            />
                            <CustomCheckbox
                              dense
                              input={{
                                onChange: e =>
                                  this.setState({
                                    filter: { ...filter, showSuccess: e.target.checked },
                                  }),
                                checked: filter.showSuccess
                              }}
                              label={<><Text icon="square" success contentCenter inline>&nbsp;</Text> <Text inline bold>Show Success Conversations</Text></>}
                              data-unique="chkMisuseSessionShowSucceed"
                            />
                          </GridItem>
                          <GridItem md={4} lg={12}>
                            <CustomTextField
                              input={{
                                onChange: e =>
                                  this.setState({
                                    filter: { ...filter, filterText: e.target.value },
                                  }),
                                value: filter.filterText,
                              }}
                              label={'Filter by Distrction topic'}
                              data-unique="txtMisuseSessionConversationName"
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )
        }}
      </TestSessionProgress>
    }}
    </TestSessionProgress>)
  }

  renderParameters(testsession, loading) {
    if (loading) return <LoadingIndicator />
    const configuration = (testsession.misuseCheckerSession && testsession.misuseCheckerSession.configuration) || {}
    return (
      <GridContainer>
        {configuration.allowedDomains && configuration.allowedDomains.length > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? misuseAllowesDomainsDarkmode : misuseAllowesDomains} alt="Allowed Domains" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Allowed Domains
                    </Text>
                    <React.Fragment>
                      {
                        configuration.allowedDomains && configuration.allowedDomains.map(v => (
                          <Chip key={v} label={v} />
                        ))
                      }
                    </React.Fragment>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        {configuration.distractionTopics && configuration.distractionTopics.length > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? misuseDistractionTopicsDarkmode : misuseDistractionTopics} alt="Distraction Topics" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Distraction Topics
                    </Text>
                    <React.Fragment>
                      {
                        configuration.distractionTopics && configuration.distractionTopics.map(v => (
                          <Chip key={v} label={v} />
                        ))
                      }
                    </React.Fragment>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        <GridItem xs={4} grid>
          <Card>
            <CardBody>
              <GridContainer alignItems="center" justify="center" autoHeight>
                <GridItem xs={4} largePadding center>
                  <img src={isDarkmode() ? misuseNumberofCyclesDarkmode : misuseNumberofCycles} alt="Misuse Attempts" />
                </GridItem>
                <GridItem xs={8}>
                  <Text primary>
                    Misuse Attempts<br />
                    <strong>{MISUSE_ATTEMPS_MAPPING[configuration.numberOfCycles]}</strong>
                  </Text>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {configuration.approvedTopics && configuration.approvedTopics.length > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? misuseApprovedTopicsDarkmode : misuseApprovedTopics} alt="Approved Topics" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Approved Topics
                    </Text>
                    <React.Fragment>
                      {
                        configuration.approvedTopics && configuration.approvedTopics.map(v => (
                          <Chip key={v} label={v} />
                        ))
                      }
                    </React.Fragment>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        {configuration.forbiddenTopics && configuration.forbiddenTopics.length > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? ConversationStartMessagesDarkmode : ConversationStartMessages} alt="Forbidden Topics" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Forbidden Topics
                    </Text>
                    <React.Fragment>
                      {
                        configuration.forbiddenTopics && configuration.forbiddenTopics.map(v => (
                          <Chip key={v} label={v} />
                        ))
                      }
                    </React.Fragment>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        {configuration.confusedSentences && configuration.confusedSentences.length > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? misuseConfusedSentencesDarkmode : misuseConfusedSentences} alt="Confused Sentences" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Confused Sentences
                    </Text>
                    <React.Fragment>
                      {
                        configuration.confusedSentences && configuration.confusedSentences.map(v => (
                          <Chip key={v} label={v} />
                        ))
                      }
                    </React.Fragment>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        {configuration.ignoredSentences && configuration.ignoredSentences.length > 0 &&
          <GridItem xs={4} grid>
            <Card>
              <CardBody>
                <GridContainer alignItems="center" justify="center" autoHeight>
                  <GridItem xs={4} largePadding center>
                    <img src={isDarkmode() ? misuseIgnoredSentencesDarkmode : misuseIgnoredSentences} alt="Ignored Sentences" />
                  </GridItem>
                  <GridItem xs={8}>
                    <Text primary>
                      Ignored Sentences
                    </Text>
                    <React.Fragment>
                      {
                        configuration.ignoredSentences && configuration.ignoredSentences.map(v => (
                          <Chip key={v} label={v} />
                        ))
                      }
                    </React.Fragment>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
      </GridContainer>)
  }

  renderJobs(testsession) {
    const { classes, user } = this.props

    return (
      <TestSessionProgress
        key={this.state.testSessionProgressKey}
        query={TESTSESSION_PROGRESS_MISUSECHECKER_QUERY}
        subscription={TESTSESSIONPROGRESS_MISUSECHECKER_SUBSCRIPTION}
        testSession={testsession}>
        {({ testSessionProgress }) => {

          if (!testSessionProgress.misuseCheckerSession) {
            return null
          }
          return (
            <GridContainer>
              <GridItem xs={10} center middle largePadding>
                <Text header primary bold>{testSessionProgress.misuseCheckerSession.jobs && testSessionProgress.misuseCheckerSession.jobs.length} JOBS</Text>
                <Text primary padding>|</Text>
                <Text header info bold>{testSessionProgress.misuseCheckerSession.jobs && testSessionProgress.misuseCheckerSession.jobs.filter(j => j.status !== 'READY' && j.status !== 'FAILED').length} RUNNING</Text>
                <Text primary padding>|</Text>
                <Text icon="square" danger inline contentCenter>&nbsp;</Text> <Text header inline bold>{testSessionProgress.misuseCheckerSession.jobs && testSessionProgress.misuseCheckerSession.jobs.filter(j => j.status === 'FAILED').length} FAILED</Text>
                <Text primary padding>|</Text>
                <Text icon="square" success inline contentCenter>&nbsp;</Text>  <Text header inline bold>{testSessionProgress.misuseCheckerSession.jobs && testSessionProgress.misuseCheckerSession.jobs.filter(j => j.status === 'READY').length} READY</Text>
              </GridItem>
              <GridItem xs={2} right noPadding>
                <Tooltip title={'Download logs for each jobs merged into one log file.'}>
                  <Button link secondary data-unique={`btnTestSessionDownloadAllJobLogs`} onClick={() => window.open(`${config.api.base}/misusechecker/alljoblogdata/${testSessionProgress.misuseCheckerSession.id}`)}>
                    <ShowIcon icon="download" /> Download Logs
                  </Button>
                </Tooltip>
              </GridItem>
              <GridItem xs={12}>
                <List component="nav" key="jobs">
                  {testSessionProgress.misuseCheckerSession.jobs &&
                    testSessionProgress.misuseCheckerSession.jobs.map((job, jobIndex) => {
                      const onClick=() => {
                        if (hasPermission(user, 'TESTSESSIONS_REPORTS')) {
                          if (this.state.jobOpenId === job.id) {
                            this.setState({ jobOpenId: null })
                          } else {
                            this.setState({ jobOpenId: job.id })
                          }
                        }
                      }
                      return (
                        <React.Fragment key={job.id}>
                          <ExpansionPanel expanded={this.state.jobOpenId === job.id} >
                            <ExpansionPanelSummary
                              onClick={() => {
                                onClick()
                              }}
                              onKeyDown={(e) => {
                                if (e.keyCode === 32){
                                  onClick()
                                }
                              }}
                            >
                              <ListItem
                                data-unique={`liTestSessionJob${jobIndex}`}


                                className={classes.testcaselistitem}
                                key={job.id}
                              >
                                {job.status === 'READY' && <ListItemIcon className={classes.successText}><ShowIcon custom icon="success" /></ListItemIcon>}
                                {job.status === 'FAILED' && <ListItemIcon className={classes.dangerText}><ShowIcon custom icon="error" /></ListItemIcon>}
                                {job.status !== 'READY' && job.status !== 'FAILED' && <Text info><LoadingIndicator /></Text>}
                                {job.started && <ListItemText style={{ display: 'block', flex: 'unset' }}
                                                              primary={<>Started: <DateFormat seconds>{job.started}</DateFormat></>}
                                />}
                                {job.finished && <ListItemText style={{ display: 'block', flex: 'unset' }}
                                                               primary={<>Finished: <DateFormat seconds>{job.finished}</DateFormat></>}
                                                               secondary={<>
                                                                 Status: <Text icon="square" danger={job.status === 'FAILED'} success={job.status === 'READY'} info={job.status !== 'READY' && job.status !== 'FAILED'} inline contentCenter>&nbsp;</Text> <Text inline>
                                                                 {job.status || 'RUNNING'}
                                                               </Text>
                                                               </>}
                                                               secondaryTypographyProps={{ component: 'span' }}
                                />}
                                {job.jobName && <ListItemText
                                  primary={job.jobName} {...((job.finished || job.started) ? { secondary: <>&nbsp;</>, secondaryTypographyProps: { component: 'span' } } : {})}
                                />}
                              </ListItem>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <GridContainer nounset>
                                <GridItem xs={12}>
                                  <Query
                                    query={MISUSECHECKER_SESSIONJOB_LOGS_QUERY}
                                    variables={{ jobId: job.id, first: 26 }}
                                    fetchPolicy="network-only"
                                  >
                                    {({ loading, error, data, refetch }) => {
                                      if (error) {
                                        return <ErrorFormat err={error} />
                                      }
                                      if (loading) {
                                        return <LoadingIndicator large />
                                      }
                                      const rd = (data && data.misusecheckersessionjoblogs) || []
                                      return (
                                        <GridContainer>
                                          {job.err && (
                                            <GridItem xs={12}>
                                              <ErrorFormat err={job.err} suppress split />
                                            </GridItem>
                                          )}
                                          {rd && rd.length > 0 && <>
                                            <GridItem xs={12} right middle>
                                              <Button link secondary data-unique={`btnTestSessionRefreshJobLogs_${jobIndex}`} onClick={() => refetch()}>
                                                <ShowIcon icon="redo" /> Refresh
                                              </Button> <Text primary>|</Text> <Button link secondary data-unique={`btnTestSessionReadMoreJobLogs_${jobIndex}`} onClick={() => window.open(`${config.api.base}/misusechecker/joblog/${job.id}`, '_blank')}>
                                              <ShowIcon icon="eye" /> Full View
                                            </Button>
                                            </GridItem>
                                            <GridItem xs={12}>
                                              <AdvancedTable
                                                disableFooter
                                                disableHeader
                                                tableData={[...rd.slice(0, 25).map(l => {
                                                  const logLines = l.log ? l.log.split('\n') : []
                                                  return [
                                                    () =>
                                                      <Tooltip title={l.createdAt}>
                                                        <DateFormat seconds>
                                                          {l.createdAt}
                                                        </DateFormat>
                                                      </Tooltip>,
                                                    () => l.log &&
                                                      logLines.map((line, i) => (
                                                        <React.Fragment key={i}>
                                                          <Text pre linebreak>{line}</Text>
                                                          {i < logLines.length - 1 && (
                                                            <br />
                                                          )}
                                                        </React.Fragment>
                                                      ))
                                                  ]
                                                }), rd.length > 25 && [
                                                  () => '...',
                                                  () => null
                                                ]]} />
                                            </GridItem>
                                            {rd.length > 25 &&
                                              <GridItem xs={12} right middle>
                                                <Button link secondary data-unique={`btnTestSessionRefreshJobLogs2_${job.id}`} onClick={() => refetch()}>
                                                  <ShowIcon icon="redo" /> Refresh
                                                </Button> <Text primary>|</Text> <Button link secondary data-unique={`btnTestSessionReadMoreJobLogs2_${job.id}`} onClick={() => window.open(`${config.api.base}/misusechecker/joblog/${job.id}`, '_blank')}>
                                                <ShowIcon icon="eye" /> Full View
                                              </Button>
                                              </GridItem>
                                            }
                                          </>}
                                        </GridContainer>
                                      )
                                    }}
                                  </Query>
                                </GridItem>
                              </GridContainer>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </React.Fragment>
                      )
                    })}
                </List>
              </GridItem>
            </GridContainer>
          )
        }}
      </TestSessionProgress>
    )
  }

  renderDangerZone(testsession) {
    const { user, history, setAlertSuccessMessage, setAlertErrorMessage } = this.props
    return <GridContainer key="danger">
      {hasPermission(user, 'TESTSESSIONS_DELETE') &&
        <React.Fragment>
          <GridItem md={8} lg={4}>
            <ListItem>
              <Text lg danger padding><ShowIcon icon="trash" /></Text>

              <GridContainer nounset>
                <GridItem md={12}><Text bold>Delete Test Session</Text></GridItem>
                <GridItem md={12}><Text>This removes the Test Session and its results</Text></GridItem>
              </GridContainer>
              <Mutation
                mutation={DELETE_TESTSESSION}
                onCompleted={data => {
                  removeRecentListEntry({
                    url: `/misusecheck/projects/view/${testsession.misuseCheckerSession.project.id}/resuls/${testsession.id}`,
                  })
                  setAlertSuccessMessage('Test Session deleted')
                  if (testsession.misuseCheckerSession && testsession.misuseCheckerSession.project) {
                    history.push(`/misusecheck/projects/view/${testsession.misuseCheckerSession.project.id}`)
                  } else {
                    history.push(`/misusecheck/projects`)
                  }
                }}
                onError={error => {
                  setAlertErrorMessage('Test session deletion failed', error)
                }}
                update={DeleteTestSessionListsFromCache}
              >
                {(
                  deleteTestSession,
                  { loading, error },
                ) => (
                  <ConfirmationButton
                    confirmationText={`When deleting this Test Session, all it's test results are lost. You have to start another Test Session to get current results. If the session is running, it will be cancelled first. Are you sure you want to delete it ?`}
                    requireCheck={true}
                    danger
                    small
                    minWidth
                    onClick={() => {
                      deleteTestSession({
                        variables: { id: testsession.id },
                      })
                    }}
                    data-unique="btnTestSessionDelete"
                  >

                    Delete
                  </ConfirmationButton>
                )}
              </Mutation>
            </ListItem>
          </GridItem>
          <GridItem md={8}></GridItem>
        </React.Fragment>
      }
      {hasPermission(user, 'TESTSESSIONS_DELETE') && hasPermission(user, 'TESTSESSIONS_CREATE') &&
        <React.Fragment>
          <GridItem md={8} lg={4}><Divider dense /></GridItem>
          <GridItem md={12} lg={8}></GridItem>
        </React.Fragment>
      }
      {hasPermission(user, 'TESTSESSIONS_CREATE') &&
        <React.Fragment>
          <GridItem md={8} lg={4}>
            <ListItem>
              <Text lg danger padding><ShowIcon icon="power-off" /></Text>
              <GridContainer nounset>
                <GridItem md={12}><Text bold>Send Cancellation Request</Text></GridItem>
                <GridItem md={12}><Text>Send cancellation request to Test Session</Text></GridItem>
              </GridContainer>
              <Mutation
                mutation={CANCEL_TESTSESSION}
                onCompleted={data => {
                  setAlertSuccessMessage('Sent cancellation request to Test Session')
                  this.setState({ testSessionProgressKey: this.state.testSessionProgressKey + 1 })
                }}
                onError={error => {
                  setAlertErrorMessage('Sending cancellation request to Test Session failed', error)
                }}
              >
                {(
                  cancelTestSession,
                  { loading, error },
                ) => (
                  <TestSessionProgress testSession={testsession}>
                    {({ testSessionProgress }) => (
                      <ConfirmationButton
                        confirmationText={`When cancelling a Test Session, all background processing will be stopped and the test results are not complete. Are you sure you want to cancel it ?`}
                        requireCheck={true}
                        danger
                        small
                        minWidth
                        disabled={testSessionProgress.status === 'READY' || testSessionProgress.status === 'FAILED' || testSessionProgress.status === 'CANCELLED'}
                        onClick={() => {
                          cancelTestSession({
                            variables: { id: testsession.id },
                          })
                        }}
                        data-unique="btnTestSessionCancel"
                      >
                        Send
                      </ConfirmationButton>
                    )}
                  </TestSessionProgress>
                )}
              </Mutation>
            </ListItem>
          </GridItem>
          <GridItem xs={8}></GridItem>
        </React.Fragment>
      }
    </GridContainer>
  }

  render() {
    const { match } = this.props
    const testSessionId = match.params.id
    const misuseCheckerTestProjectId = match.params.projectId

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Query query={TESTSESSION_QUERY} variables={{ id: testSessionId }}>
            {(queryResult) => <QueryStatus {...queryResult} query="testsession" renderLoading={() => <RenderSkeletonProjectMenu />}>{({ testsession, loading }) => {
              if (!testsession) testsession = { id: testSessionId, results: [], jobs: [], testProject: { nlpAnalytics: false } }
              return (
                <CustomTabs
                  name={`tabMisuseCheckerSession_${testSessionId}`}
                  headerColor="info"
                  plainTabs
                  tabs={[
                    {
                      tabName: 'Overview',
                      tabIcon: <ShowIcon icon="tachometer-alt" />,
                      tabContent: this.renderDashboard(testsession, loading),
                      locationPrefix: `/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testSessionId}/dashboard`,
                      dataUnique: 'btnMisuseTestSessionNavigationDashboard'
                    },
                    {
                      tabName: 'Violations',
                      tabIcon: <ShowIcon icon="shield-alt" />,
                      tabContent: this.renderViolationsTab(testsession, loading),
                      locationPrefix: `/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testSessionId}/violations`,
                      dataUnique: 'btnMisuseTestSessionNavigationViolations'
                    },
                    {
                      tabName: 'Conversations',
                      tabIcon: <ShowIcon icon="list-ul" />,
                      tabContent: this.renderConversations(testsession, loading),
                      locationPrefix: `/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testSessionId}/conversations`,
                      dataUnique: 'btnMisuseTestSessionNavigationConversations'
                    },
                    {
                      tabName: 'Parameters',
                      tabIcon: <ShowIcon icon="list-ul" />,
                      tabContent: this.renderParameters(testsession, loading),
                      locationPrefix: `/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testSessionId}/parameters`,
                      dataUnique: 'btnMisuseTestSessionNavigationParameters'
                    },
                    {
                      tabName: 'Session Jobs',
                      tabIcon: <ShowIcon icon="cogs" />,
                      tabContent: this.renderJobs(testsession),
                      locationPrefix: `/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testSessionId}/jobs`,
                      dataUnique: 'btnMisuseTestSessionNavigationSessionJobs'
                    },
                    {
                      tabName: 'Danger Zone',
                      tabRight: true,
                      tabIcon: <ShowIcon icon="exclamation-triangle" />,
                      tabContent: this.renderDangerZone(testsession),
                      locationPrefix: `/misusecheck/projects/view/${misuseCheckerTestProjectId}/results/${testSessionId}/danger`,
                      dataUnique: 'btnMisuseTestSessionNavigationSessionDanger'
                    },
                  ]}
                />
              )
            }}</QueryStatus>}
          </Query>
        </GridItem>
      </GridContainer>
    )
  }
}

const MisuseCheckerSessionWithGraphql = compose(
  graphql(START_MISUSECHECKERPROJECT, {
    props: ({ mutate }) => ({
      mutateStartMisuseCheckerProject: args => mutate(args),
    }),
    options: (props) => ({
      onCompleted: (data) => {
        const testSessionId = data.startMisuseCheckerProject.id
        const projectId = data.startMisuseCheckerProject.misuseCheckerSession.project.id
        props.setAlertSuccessMessage('Misuse Checker session started ...')
        props.history.push('/misusecheck/projects/view/' + projectId + '/results/' + testSessionId)
      },
      onError: (error) => {
        props.setAlertErrorMessage('Test session failed', error)
      },
      refetchQueries: ({ data }) => [
        ...RefetchTestProjectQueriesOnNewTestSession(data.startMisuseCheckerProject.misuseCheckerSession.project.id),
        ...RefetchTestSessionQueries(data.startMisuseCheckerProject.id)
      ],
      update: DeleteTestSessionListsFromCache
    })
  }),
)(MisuseCheckerSession)

export default connect(
  state => ({ token: state.token.token, user: state.token.user, license: state.settings.license, features: state.settings.features }),
  { setAlertSuccessMessage, setAlertErrorMessage, removeRecentListEntry },
)(withStyles(testsessionsStyle, { withTheme: true })(MisuseCheckerSessionWithGraphql))
